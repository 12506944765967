import { formatFontQuery } from '~/utils/helpers';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useSiteContext } from '~/context/SiteContext';
import GlobalStructuredData from '~/components/meta/structured-data/GlobalStructuredData';

export const HelmetContent = () => {
  const { theme, globals } = useSiteContext();

  return (
    <>
      <Helmet>
        {theme && (
          <link
            href={formatFontQuery([theme.fontHeading, theme.fontBody])}
            rel="stylesheet"
          />
        )}
      </Helmet>

      <GlobalStructuredData
        name={globals.shopInfo.name}
        url={window.location.href}
      />
    </>
  );
};
