import React, { useEffect } from 'react';
import NewsletterSubscribe from '~/components/ui/NewsletterSubscribe';
import { useCustomer } from '~/context/CustomerContext';
import useShouldShowMailingListPopup, {
  MAILING_LIST_MODAL_LAST_SHOWN_LOCAL_STORAGE_KEY,
} from '~/hooks/useShouldShowMailingListPopup';
import { useSiteContext } from '~/context/SiteContext';

const MODAL_DELAY = 1000;

type MailingListModalProps = {
  openModal: (details: any) => void;
};
const MailingListModal: React.FC<MailingListModalProps> = ({ openModal }) => {
  const mailingListModalSettings = useSiteContext()?.mailingListPopup;
  const { customer } = useCustomer();
  const shouldShowMailingListPopup = useShouldShowMailingListPopup();

  useEffect(() => {
    if (
      !customer ||
      customer.emailSubscribed ||
      !shouldShowMailingListPopup ||
      !mailingListModalSettings
    ) {
      return;
    }

    const { title, text, cta } = mailingListModalSettings;

    const timeout = setTimeout(() => {
      openModal({
        id: 'newsletter',
        content: (
          <div className="text-center px-5 py-7 lg:px-7">
            <h4 className="mb-5 whitespace-pre-wrap break-words">
              {title ? title : 'Join our mailing list'}
            </h4>
            <p className="text-body lg:text-body-lg mb-8 whitespace-pre-wrap break-words">
              {text ? text : 'Hear it first from us'}
            </p>
            <NewsletterSubscribe
              inputID="email-modal"
              className="text-center"
              ctaText={cta ? cta : 'Sign Up'}
            />
          </div>
        ),
        attrs: {
          className: 'relative top-1/2 bg-main-0 rounded',
          overlayClassName: 'fixed inset-0 px-4 z-50',
          style: {
            content: {
              inset: 'none',
              transform: 'translateY(-50%)',
              maxWidth: '527px',
            },
          },
        },
        aria: {
          label: 'Newsletter',
        },
      });

      localStorage.setItem(
        MAILING_LIST_MODAL_LAST_SHOWN_LOCAL_STORAGE_KEY,
        new Date().getTime().toString(),
      );
    }, MODAL_DELAY);

    return () => clearTimeout(timeout);
  }, [
    openModal,
    customer,
    mailingListModalSettings,
    shouldShowMailingListPopup,
  ]);

  return null;
};

export default MailingListModal;
