import React, { FC, useState, useEffect } from 'react';
import { Modules } from '~/components/modules';

interface Image {
  id: null | string;
  src: null | string;
  title: null | string;
  caption: null | string;
  mediaType: null | string;
}

interface Video {
  id: null | string;
  src: null | string;
  title: null | string;
  caption: null | string;
  mediaType: null | string;
}

interface Config {
  color: string;
  image: Image;
  video: Video;
  imageSize: null | string;
  marginTop: string;
  moreSpace: boolean;
  videoType: string;
  marginLeft: string;
  paddingTop: string;
  columnSpace: string;
  marginRight: string;
  paddingLeft: string;
  imageOverlay: number;
  marginBottom: string;
  paddingRight: string;
  videoOpacity: number;
  paddingBottom: string;
  backgroundType: string;
  imageAlignment: null | string;
  videoAlignment: null | string;
  videoLoopPlayback: boolean;
}

interface Settings {
  id: string;
  name: string;
  settings: {
    anchorTag: string;
    id: string;
    isApp: boolean;
    isWeb: boolean;
    type: string;
    settings: any;
  };
  type: string;
  width: string;
}

export interface GridBlock {
  anchorTag: string;
  id: string;
  isApp: boolean;
  isWeb: boolean;
  type: string;
  settings: {
    config: Config;
    noOfColumn: number;
    settings: Settings[];
  };
}

interface containerCSS {
  gap?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  border?: string;
  backgroundRepeat?: string;
  opacity?: number;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const GridSection: FC<{ data: GridBlock }> = ({ data }) => {
  const columns = data.settings.settings;
  const config = data.settings.config;
  const {
    columnSpace,
    color,
    backgroundType,
    imageSize,
    imageAlignment,
    imageOverlay,
    image,
  } = config;
  const { marginTop, marginBottom, marginLeft, marginRight } = config;
  const { anchorTag } = data;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getGridColSpan = (width: string) => {
    let className = 'col-span-12';
    const gridColSpan: any = {
      '25': 'col-span-3',
      '33': 'col-span-4',
      '50': 'col-span-6',
      '66': 'col-span-8',
      '100': 'col-span-12',
    };
    if (gridColSpan[width]) {
      className = gridColSpan[width];
    } else {
      className = 'col-span-12';
    }
    return className;
  };
  const renderContainer = () => {
    const containerCSS: containerCSS = {
      backgroundRepeat: 'no-repeat',
      gap: windowWidth <= 768 ? '0' : columnSpace,
      marginTop: marginTop,
      marginLeft: windowWidth <= 768 ? '0' : marginLeft,
      marginRight: windowWidth <= 768 ? '0' : marginRight,
      marginBottom: marginBottom,
    };

    if (backgroundType === 'color') {
      containerCSS.backgroundColor = color;
    }

    if (backgroundType === 'image') {
      containerCSS.backgroundImage = `url(${image.src})`;
    }

    if (imageSize) {
      containerCSS.backgroundSize = imageSize;
    }

    if (imageAlignment) {
      containerCSS.backgroundPosition = imageAlignment;
    }

    if (imageOverlay) {
      containerCSS.opacity = imageOverlay / 10;
    }

    const newColumn = columns.map((item: any, index: number) => {
      const gridColSpan = getGridColSpan(item.width);

      return (
        <div key={index} className={gridColSpan}>
          {item.settings ? (
            <Modules
              data={item.settings}
              type={item.settings.type}
              key={item.id}
              config={config}
              parentWidget="GridBlock"
            />
          ) : (
            <div key={index}></div>
          )}
        </div>
      );
    });
    return (
      <div id={anchorTag}>
        <div
          style={containerCSS}
          className={`grid md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 grid-cols-1`}
        >
          {newColumn}
        </div>
      </div>
    );
  };

  return renderContainer();
};

export default GridSection;
