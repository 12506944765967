import { MutableRefObject, useEffect, useState } from 'react';

const useOnScreen = (ref: MutableRefObject<HTMLElement | null>) => {
  const [counter, setCounter] = useState(1);
  const [isOnScreen, setIsOnScreen] = useState(false);

  // Since refs are immutable, we have a checkAgain function which
  // will trigger a re-check of the intersection
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting),
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, counter]);

  const checkAgain = () => setCounter(prev => prev + 1);

  return { isOnScreen, checkAgain };
};

export default useOnScreen;
