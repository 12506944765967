// This is different from the other analytics in this project.
// It is using the webstoreTracker from the monolith and is
// not for shop use. See webstore-tracker.blade.php in the monolith.
declare global {
  interface Window {
    webstoreTracker: {
      // Must keep this up to date with webstore-tracker.blade.php in monolith
      isReady(): boolean;
      track(x: { eventName: string; eventDetails: Record<string, any> }): void;
      trackThroughServer(x: {
        eventName: string;
        eventDetails: Record<string, any>;
      }): Promise<unknown>;
      tagGroup(groupName: string): void;
    };
  }
}

// I had to use this temp variable for correct type hints for some reason
const webstoreTracker = window.webstoreTracker;

export default webstoreTracker;
