import { compact, isEmpty, isMatch, uniq } from 'lodash';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import enums from '~/utils/enums';

dayjs.extend(duration);

export function toPlainText(blocks) {
  if (!blocks) {
    return '';
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map(child => child.text).join('');
    })
    .join('\n\n');
}

/**
 * Converts an HTML string (like a collection description) into a plain text string
 * @param {String} html - A string of HTML
 */
export function htmlToText(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');

  return doc.body.textContent || '';
}

/**
 * Returns lowest priced variant from set of variants
 * @param {Array} variants
 * @returns {Variant} a variant object
 */
export function getLowestPricedVariant(variants = []) {
  const variantKeys = Object.keys(variants);
  const prices = [];
  const priceIndexes = [];

  variantKeys.forEach((variantKey, variantIndex) => {
    const variant = variants[variantKey];

    if (variant.priceCents) {
      prices.push(variant.priceCents);
      priceIndexes.push(variantIndex);
    }

    if (variant.salePriceCents) {
      prices.push(variant.salePriceCents);
      priceIndexes.push(variantIndex);
    }
  });

  if (prices.length) {
    const lowestPrice = Math.min.apply(null, prices);
    const lowestPriceIndex = prices.indexOf(lowestPrice);
    const lowestPricedVariantIndex = priceIndexes[lowestPriceIndex];
    return variants[lowestPricedVariantIndex];
  }

  return {};
}

/**
 * Determines if any variant is on sale
 * @param {array} variants
 */
export function getIsSale(variants) {
  if (!variants.length) {
    return [];
  }
  return !!variants.filter(v => v.salePriceCents).length;
}

/**
 * Determines whether the price of variants varies
 * or if they are all the same price
 * @param {array} variants
 */
export function getPriceVaries(variants) {
  if (!variants.length) {
    return [];
  }
  const prices = variants.map(v => v.priceCents);
  return uniq(prices).length > 1;
}

/**
 * Sets CSS variables on the document element
 * Must be called within useEffect to ensure DOM is mounted
 *
 * @param {string} name CSS variable name
 * @param {string} value CSS variable value
 */
export function setCssVar(name, value) {
  return document.documentElement.style.setProperty(`${name}`, `${value}`);
}

/**
 * Converts a hex code to an RGB string
 * @param {string} hex
 */
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Processes a color and applys as CSS var
 * @param {string} color containing comma-dilineated rgb values
 * @param index
 */
export function setColorVars(color, index) {
  const { r, g, b } = hexToRgb(color);

  setCssVar(`--r${index}`, r);
  setCssVar(`--g${index}`, g);
  setCssVar(`--b${index}`, b);
}

/**
 * Takes an array of typeface names and returns a formatted query for
 * pulling them from Google Fonts
 * @param {array} fonts Typeface names
 */
export function formatFontQuery(fonts) {
  const fontsFormatted = fonts
    .filter(x => !!x)
    .map(font => font.split(' ').join('+'));

  return `https://fonts.googleapis.com/css?family=${fontsFormatted.join(
    '|',
  )}:400,700&display=swap`;
}

/**
 * Takes an array of variants and returns true or false if all
 * variants have a quantity of 0
 * @param {array} variants
 */
export function getIsWaitlist(variants) {
  return !variants.filter(v => v.quantity > 0).length;
}

/**
 * Gets the highest and lowest price of a variant
 * @return {Object} Returns an object with 'min' and 'max' keys
 * @param variants
 */
export function getPriceMinMax(variants) {
  if (!variants.length) {
    return 0;
  }

  const prices = variants.map(v => v.priceCents);

  return {
    min: Math.min.apply(null, prices),
    max: Math.max.apply(null, prices),
  };
}

/**
 * Convert cents to dollars
 * @param {number} price value in cents
 * @returns {number} value in dollars
 */
export function getPriceDollars(price) {
  return (price / 100).toFixed(2);
}

/**
 * Preps HTML content for dangerouslySetInnerHTML
 * @param {string} content - HTML content
 */
export function createMarkup(content) {
  return { __html: content };
}

/**
 * Returns true or false on whether or not the string is an email address
 * @param {String} string - An email address
 */
export function isEmail(string) {
  // noinspection RegExpRedundantEscape,RegExpUnnecessaryNonCapturingGroup
  const emailRegex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  return emailRegex.test(string);
}

/**
 * Returns a hh:mm:ss difference until the specified date
 * @param {String} end - A string which represents a date
 */
export function timeUntil(end) {
  const start = dayjs();
  end = dayjs(end);

  // Get the total number of seconds
  let seconds = end.diff(dayjs(), 'seconds');

  // Convert seconds to hours
  const hours = Math.floor(seconds / (60 * 60));

  // Take hours (converted to seconds) away from seconds
  seconds = seconds - hours * 60 * 60;

  // Convert remaining seconds to minutes
  const minutes = Math.floor(seconds / 60);

  // Take remaining minutes (converted to seconds) away from seconds
  seconds = seconds - minutes * 60;

  return start < end
    ? [
        ensureMin2Digits(hours),
        ensureMin2Digits(minutes),
        ensureMin2Digits(seconds),
      ].join(':')
    : '00:00:00';
}

/**
 * Returns seconds between now and the specified date
 * @param {String} end - A string which represents a date
 */
export function secondsUntil(end) {
  end = dayjs(end);

  return end.diff(dayjs(), 'seconds');
}

/**
 * Ensures a minimum of 2 digits on a number by adding a 0 to the
 * beginning, or returning the whole number if it's greater than
 * 1 character
 * @param {String|Number} number - A number
 */
export function ensureMin2Digits(number) {
  return `${number}`.length === 1 ? '0' + number : number;
}

/**
 * Returns an array of option values based on variants and selected option
 * @param {Object} variants - An object of variants
 * @param {String} option - The option you want to find in the variants
 */
export function getOptionValues(variants, option) {
  const values = compact(
    uniq(variants.map(v => v[option]).filter(v => v !== null)),
  );

  if (!values.length) return false;
  return values;
}

export function buildOptions(variants, selectedOptions) {
  const options = [];
  const selectedColor = selectedOptions['Color']; // May be undefined
  const selectedSize = selectedOptions['Size']; // May be undefined

  const colorValues = getOptionQuantities(
    variants,
    'color',
    'size',
    selectedSize,
  );

  if (colorValues) {
    options.push({ name: 'Color', values: colorValues });
  }

  const sizeValues = getOptionQuantities(
    variants,
    'size',
    'color',
    selectedColor,
  );

  if (sizeValues) {
    options.push({ name: 'Size', values: sizeValues });
  }

  return options;
}

function getOptionQuantities(
  variants,
  optionName,
  otherOptionName,
  selectedOtherOption,
) {
  const valueNames = getOptionValues(variants, optionName) || [];

  const result = valueNames.map(valueName => {
    const variantsForThisValue = variants.filter(variant => {
      return variant[optionName] === valueName;
    });

    const quantity = getQuantityByOtherSelectedOption(
      variantsForThisValue,
      optionName,
      otherOptionName,
      selectedOtherOption,
    );

    return { name: valueName, quantity };
  });

  return result.length ? result : undefined;
}

function getQuantityByOtherSelectedOption(
  variantsForThisValue,
  optionName,
  otherOptionName,
  selectedOtherOption,
) {
  if (!selectedOtherOption) {
    return variantsForThisValue.reduce((acc, curr) => {
      acc += curr.quantity;

      return acc;
    }, 0);
  } else {
    const variantInThisColorAndSize = variantsForThisValue.find(v => {
      return v[otherOptionName] === selectedOtherOption;
    });

    return variantInThisColorAndSize?.quantity;
  }
}

/**
 * Takes a product and returns an array of images
 * this array is the first variant found to have images
 * or if no variant images exist, the product images
 *
 * @param {object} product CS product object
 * @param {string} [linkedVariantId] specify only getting images for a particular variant
 * @returns {*|*[]}
 */
export function getImageSet(product, linkedVariantId) {
  if (!product) return [];
  let { variants } = product;

  if (linkedVariantId) {
    variants = variants.filter(v => v.id === linkedVariantId);
  }

  const variantsWithImages = variants.filter(v => v.images?.length);

  const variantImages = variantsWithImages.flatMap(variant => {
    return variant.images.map(image => {
      return { url: image?.url };
    });
  });

  // if variants have images associated return those
  if (variantImages.length) {
    return variantImages;
  }

  // otherwise return the images associated at the product level
  return product.images || [];
}

/**
 * Takes a product and returns an array of variants
 * filtered down by a split attribute
 *
 * @param {Object} product CS product object
 * @param {String} splitAttr String by which to filter the variant set
 */
export function getVariantSet(product, splitAttr = false) {
  if (!splitAttr) return product.variants;

  return product.variants.filter(v => {
    return v.color === splitAttr || v.size === splitAttr;
  });
}

/**
 * Takes a product and returns a formatted link to that product's
 * PDP. This takes into account the linkedVariantId and splitAttr
 *
 * @param {String} slug product slug
 * @param {?String} linkedVariantId variant to link to
 * @param {String} splitAttr attribute one which to filter variants
 */
export function getProductLink(slug, linkedVariantId, splitAttr) {
  const productLinkUrlParams = [];

  if (splitAttr) productLinkUrlParams.push(`split=${splitAttr}`);
  if (linkedVariantId) productLinkUrlParams.push(`variant=${linkedVariantId}`);

  return `/products/${slug}${
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    productLinkUrlParams.length ? `?${productLinkUrlParams.join('&')}` : ''
  }`;
}

/**
 * Check if a variant is in stock and not a gift card
 *
 * @param {Object} variant
 * @param {Object} product
 * @returns {Boolean}
 */
export function getAvailability(variant, product) {
  const giftCardType = enums.productTypes.giftCard;
  return variant?.quantity > 0 || product?.type === giftCardType;
}

/**
 * Takes a split product and returns
 * the first variant to match the splitAttr
 *
 * @param {Object} product with variants
 * @param {String} splitAttr option type
 * @returns {Object} variant
 */
export function getVariantFromSplit(product, splitAttr) {
  if (!splitAttr) {
    return;
  }

  return product.variants.find(v => {
    return v.color === splitAttr || v.size === splitAttr;
  });
}

/**
 * Given a split return all images that match the split
 *
 * @param {Object} product CS product object
 * @param {String} splitAttr attribute on which to filter variants
 */
export function getImagesFromSplit(product, splitAttr) {
  if (!product) {
    return [];
  }

  if (!splitAttr) {
    return product.images;
  }

  const variantsMatchingSplit = product.variants.filter(v => {
    return v.color === splitAttr || v.size === splitAttr;
  });

  const variantsWithImages = variantsMatchingSplit.filter(
    v => v.images?.length,
  );

  // eslint-disable-next-line sonarjs/no-identical-functions
  return variantsWithImages.flatMap(variant => {
    return variant.images.map(image => {
      return { url: image?.url };
    });
  });
}

/**
 * Get variant by options
 * @param {Object} product
 * @param {Object} options
 * @returns boolean
 */
export function doesVariantExistForTheseOptions(product, options) {
  if (!options || isEmpty(options)) {
    return false;
  }

  return product.variants.some(variant => {
    const { size, color } = variant;
    const variantOptions = {};
    if (size) {
      variantOptions.Size = size;
    }
    if (color) {
      variantOptions.Color = color;
    }

    return isMatch(variantOptions, options);
  });
}

/**
 * uriIsExternal - Check is a URI is external or internal
 * @param {String} uri - The URI of the resources
 * @returns {Boolean}
 */
export function uriIsExternal(uri) {
  const host = window.location.hostname;

  const linkHost = (function (uri) {
    if (/^https?:\/\//.test(uri)) {
      // Absolute URL.
      // The easy way to parse an URL, is to create <a> element.
      // @see: https://gist.github.com/jlong/2428561
      const parser = document.createElement('a');
      parser.href = uri;

      return parser.hostname;
    } else {
      // Relative URL.
      return window.location.hostname;
    }
  })(uri);

  return host !== linkHost;
}

/**
 * sanitizeUri - Remove the host from the URL
 * @param {String} [uri] - The URI of the resources
 * @returns {String|undefined}
 */
export function sanitizeUri(uri) {
  let newUri = uri;

  try {
    // Undefined urls should pass through to keep bad nav
    // configs from taking down the site
    if (typeof newUri !== 'string') return newUri;

    const urlObj = new URL(uri);

    if (urlObj.host === window.location.host) {
      const hostPrefix = window.location.host + '/';
      newUri = `/${uri.split(hostPrefix)[1]}`;
    }
  } catch {
    try {
      // If the URL constructor fails, the URI is relative; make sure those begin with a /.
      if (newUri.length > 0 && newUri[0] !== '/') {
        newUri = `/${newUri}`;
      }
    } catch {
      // If all else fails, don't crash the site
    }
  }

  return newUri;
}

/**
 * isMoreDarkThanLight - Determines if the color is light or dark
 * source: https://stackoverflow.com/a/3943023
 * @param {String} [colorCode] - The hexadecimal color code
 * @returns {Boolean}
 */
export function isMoreDarkThanLight(colorCode) {
  if (!colorCode) {
    return false;
  }

  var color =
    colorCode.charAt(0) === '#' ? colorCode.substring(1, 7) : colorCode;
  var red = parseInt(color.substring(0, 2), 16);
  var green = parseInt(color.substring(2, 4), 16);
  var blue = parseInt(color.substring(4, 6), 16);

  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
}
