import React, { useEffect, useState } from 'react';
import { Redirect, useParams as useUrl } from 'react-router-dom';

import ProductGridWithFilters, {
  HeaderProps,
  NavLink,
} from '~/components/productlist/ProductGridWithFilters';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';
import { Container } from '~/components/layout/PageStructure';
import useRestClient from '~/hooks/useRestClient';
import {
  CategoryApiResponse,
  SubcategoryApiResponse,
} from '~/types/ApiResponseTypes';

interface RouteParams {
  id: string;
  slug: string;
}

const transformCategoryApiResponseToProps = (
  response: CategoryApiResponse,
): HeaderProps => {
  return {
    seoDescription: response.data.seo_description,
    seoTitle: response.data.seo_title,
    contentHTML: '',
    pageTitle: response.data.category_name,
    pageKey: response.data.category_slug,
    canonicalUrl: response.data.canonical_url,
    breadCrumbs: response.data.category_breadcrumbs,
    subNavLinks:
      response.data.subcategories?.map((subcategory: SubcategoryApiResponse) =>
        transformSubcategoryApiResponseToSubcategory(subcategory),
      ) ?? [],
  };
};

const transformSubcategoryApiResponseToSubcategory = (
  subcategory: SubcategoryApiResponse,
): NavLink => {
  return {
    id: subcategory.category_id,
    title: subcategory.category_name,
    url: `/categories/${subcategory.category_id}/${subcategory.category_slug}`,
  };
};

const CategoryPage: React.FC = () => {
  const { id, slug } = useUrl<RouteParams>();
  const [headerProps, setHeaderProps] = useState<HeaderProps>();
  const [categorySlug, setCategorySlug] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const restClient = useRestClient();

  useEffect(() => {
    const tagGroup = ContentGroups.CategoryPage;
    webstoreTracker.tagGroup(tagGroup);
  }, [id]);

  useEffect(() => {
    let shouldUpdate = true;
    setCategorySlug(''); // clear this out
    setShouldRedirect(false);
    setIsLoading(true);
    restClient
      .getCategory(id)
      .then(response => {
        if (!shouldUpdate) {
          // to prevent state updating if unmounted
          return;
        }
        if (!response?.data) {
          setIsNotFound(true);
          return;
        }
        setHeaderProps(transformCategoryApiResponseToProps(response));
        setCategorySlug(response.data.category_slug);
        if (slug !== response.data.category_slug) {
          setShouldRedirect(true);
        }
      })
      .finally(() => {
        if (!shouldUpdate) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      shouldUpdate = false;
    };
  }, [id, restClient, slug]);

  if (!isLoading && categorySlug && shouldRedirect) {
    // if url slug doesn't match the category slug
    // but id does 301 redirect to correct page
    return (
      <Redirect
        to={{
          pathname: `/categories/${id}/${categorySlug}`,
          state: { status: 301 },
        }}
      ></Redirect>
    );
  }

  if (isNotFound) {
    return (
      <Redirect
        to={{
          pathname: `/404`,
          state: { status: 404 },
        }}
      ></Redirect>
    );
  }

  return (
    <Container className="mb-3">
      <ProductGridWithFilters
        productSearch={{ categoryId: id }}
        headerProps={headerProps || ({} as HeaderProps)}
        isTitleLoading={isLoading}
        key={id}
      />
    </Container>
  );
};

export default CategoryPage;
