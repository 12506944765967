import React from 'react';
import { doesVariantExistForTheseOptions } from '~/utils/helpers';
import cn from 'classnames';
import enums from '~/utils/enums';

const buildQuantityText = (
  variantExists,
  optionValue,
  product,
  hideAvailableStockCount,
) => {
  if (!variantExists) return '';
  if (product?.type === enums.productTypes.giftCard) return '';
  if (hideAvailableStockCount && !isWaitlisted(optionValue.quantity)) {
    return 'In Stock';
  }
  if (isWaitlisted(optionValue.quantity)) return 'Waitlist';
  return `${optionValue.quantity} left`;
};

const isWaitlisted = quantity => {
  return quantity === 0;
};

const OptionValue = ({
  optionName,
  optionValue,
  product,
  selectedOptions,
  handleOptionChange,
  handleOptionUnselect,
  hideAvailableStockCount,
}) => {
  const optionIsSelected = selectedOptions[optionName] === optionValue.name;

  // Setup current variant
  let currentOptions = { ...selectedOptions };
  if (optionName === 'Color') {
    currentOptions.Color = optionValue.name;
  }
  if (optionName === 'Size') {
    currentOptions.Size = optionValue.name;
  }
  const variantExists = doesVariantExistForTheseOptions(
    product,
    currentOptions,
  );

  const availableStockText = buildQuantityText(
    variantExists,
    optionValue,
    product,
    hideAvailableStockCount,
  );

  return (
    <div
      className={cn(
        'relative md:mr-3 md:mb-3 py-2 px-4 rounded flex-shrink-0 w-variant sm:w-auto focusable',
        {
          'shadow-border-2': optionIsSelected,
          'shadow-border': !optionIsSelected,
          'bg-opacity-10 bg-grey text-white; opacity-50': !variantExists,
        },
      )}
    >
      <label
        className={cn('absolute inset-0 z-20 opacity-0 cursor-pointer', {
          'cursor-not-allowed': !variantExists,
        })}
        htmlFor={`${product.id}-${optionName}-${optionValue.name}`}
      >
        {optionValue.name}
      </label>
      <input
        type="radio"
        id={`${product.id}-${optionName}-${optionValue.name}`}
        name={optionName}
        value={optionValue.name}
        className="absolute top-0 left-0 opacity-0"
        checked={optionIsSelected}
        onChange={() =>
          variantExists
            ? handleOptionChange(optionName, optionValue.name)
            : false
        }
        onClick={() =>
          optionIsSelected && variantExists
            ? handleOptionUnselect(optionName)
            : false
        }
      />
      <div className="z-10 pointer-events-none">
        <p className="text-small whitespace-normal max-lines-2">
          {optionValue.name}
        </p>
        <p className="text-tiny">{availableStockText}</p>
      </div>
    </div>
  );
};

export default OptionValue;
export { buildQuantityText };
