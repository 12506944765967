import Icons from '~/components/ui/Icons';
import Link from '~/components/ui/Link';
import React from 'react';

const socialLinks: Record<string, any> = {
  facebook: ({ url }: { url: string }) =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
  twitter: ({ url, title }: { url: string; title: string }) =>
    `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url,
    )}&text=${encodeURIComponent(title)}`,
};

type ShareableProps = {
  title: string;
  platforms?: string[];
};
const Shareable: React.FC<ShareableProps> = ({
  title,
  platforms = ['facebook', 'twitter'],
}) => {
  const shareAttrs = { url: window.location, title };

  return (
    <div>
      <p className="text-grey text-tiny lg:text-tiny-lg mb-1">Share</p>

      <div className="flex flex-wrap text-black">
        {platforms.map(platform => {
          if (!socialLinks[platform]) return null;

          const socialLink = socialLinks[platform](shareAttrs);

          return (
            <Link
              popup
              to={socialLink}
              className="mr-4"
              key={`shareable-${platform}`}
              aria-label={`Share ${platform}`}
            >
              <Icons type={platform} className="w-5 h-5" />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Shareable;
