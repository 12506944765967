const LineItemMedia = [{ width: 120, height: 200, useAsFallback: true }];

const ImageBlockMedia = [
  {
    width: 700,
    media: '(min-width: 1100px)',
    useAsFallback: true,
  },
  {
    width: 500,
    media: '(min-width: 900px)',
  },
  {
    width: 350,
    media: '(min-width: 678px)',
  },
  {
    width: 720,
    media: '(min-width: 501px)',
  },
  {
    width: 500,
    media: '(max-width:500px)',
  },
];

const PromoGridMedia = [
  {
    width: 411,
    height: 616,
    media: '(min-width: 1000px)',
    useAsFallback: true,
  },
  {
    width: 302,
    height: 452,
    media: '(min-width: 850px)',
  },
  {
    width: 260,
    height: 380,
    media: '(min-width: 678px)',
  },
  {
    width: 360,
    height: 540,
    media: '(min-width: 601px)',
  },
  {
    width: 320,
    height: 415,
    media: '(max-width: 600px)',
  },
];

const PromoGridFancyMedia = [
  {
    width: 900,
    media: '(min-width: 1200px)',
    useAsFallback: true,
  },
  {
    width: 600,
    media: '(max-width: 900px)',
  },
  {
    width: 800,
    media: '(min-width:901px)',
  },
];

const PromoGridSliderMedia = [
  {
    width: 302,
    height: 460,
    media: '(min-width: 769px)',
    useAsFallback: true,
  },
  {
    width: 235,
    height: 350,
    media: '(min-width: 577px)',
  },
  {
    width: 265,
    height: 395,
    media: '(max-width: 576px)',
  },
];

const SlideShowMedia = [
  {
    width: 2560, //large desktops
    media: '(min-width: 1600px)',
    useAsFallback: true,
  },
  {
    width: 1400, //laptop
    media: '(min-width: 1100px)',
  },
  {
    width: 1100, //ipad
    media: '(min-width: 800px)',
  },
  {
    width: 768, //mobile
    media: '(min-width: 501px)',
  },
  {
    width: 500, //small mobile
    media: '(max-width: 500px)',
  },
];

const ProductCardMedia = [
  {
    width: 300,
    height: 500,
    media: '(min-width: 678px)',
  },
  {
    width: 360,
    height: 536,
    media: '(min-width: 601px)',
    useAsFallback: true,
  },
  {
    width: 275,
    height: 412,
  },
];

const ProductGalleryPreviewMedia = [
  {
    width: 2500,
    useAsFallback: true,
  },
];

const ProductGalleryMedia = [
  {
    width: 1200,
    useAsFallback: true,
  },
];

const ProductGalleryThumbMedia = [
  {
    width: 150,
    height: 223,
    useAsFallback: true,
  },
];

export {
  LineItemMedia,
  ImageBlockMedia,
  PromoGridFancyMedia,
  PromoGridSliderMedia,
  PromoGridMedia,
  ProductCardMedia,
  ProductGalleryMedia,
  ProductGalleryPreviewMedia,
  ProductGalleryThumbMedia,
  SlideShowMedia,
};
