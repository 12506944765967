import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Influencer } from '~/features/Api/apiSlice.type';

const BASE_URL = '/api';

export const webstoreApi = createApi({
  reducerPath: 'webstoreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Cart', 'User', 'PaymentMethods'],
  endpoints: builder => ({
    getInfluencerBySlug: builder.query<Influencer, string>({
      query: (slug: string) => `influencers/${slug}`,
    }),
    signupSms: builder.mutation<
      { success: number; sent_confirmation?: boolean },
      { phone: string; influencerSlug: string; optInText: string }
    >({
      query: ({ phone, influencerSlug, optInText }) => ({
        url: `influencers/${influencerSlug}/signup`,
        method: 'POST',
        body: { phone, optInText },
      }),
    }),
  }),
});

export const { useGetInfluencerBySlugQuery, useSignupSmsMutation } =
  webstoreApi;
