import React, { useEffect } from 'react';
import { Helmet, HelmetProps } from 'react-helmet-async';

export const HelmetServerSafe = (props: HelmetProps) => {
  useEffect(() => {
    if (!(props.meta instanceof Array)) return;

    // Support server rendered tags
    for (const meta of props.meta) {
      document
        .querySelectorAll(
          `meta[name="${meta.name}"], meta[property="${meta.property}"]`,
        )
        .forEach(tag => {
          tag.setAttribute('data-rh', tag.getAttribute('data-rh') || '');
        });
    }
  }, [props.meta]);

  return <Helmet {...props} />;
};
