import { useEffect, useState } from 'react';
import { FeatureFlags } from '~/types/FeatureFlags';
import { ShopInfo } from '~/types/ShopInfo';

declare global {
  interface Window {
    cs: {
      featureFlags: FeatureFlags;
      shopInfo: ShopInfo;
    };
  }
}

const initialShopInfo: { shopInfo: ShopInfo } = {
  shopInfo: {
    allowCartRemoval: false,
    allowCartUpdate: false,
    appStoreUrls: { android: '', apple: '' },
    appleAppArgument: null,
    appleId: '',
    androidPackage: '',
    mobileAppSchemaPrefix: '',
    mobileAppDisplayName: '',
    contactEmail: '',
    description: '',
    facebookPixelId: '',
    featureFlags: {
      lotwReplaysEnabled: true,
    },
    dropship_info: {
      cmsAccessToken: '',
      shopId: '',
      url: '',
      userId: 0,
    },
    googleAnalyticsUid: '',
    csGoogleAnalyticsUid: '',
    hideAvailableStockCount: false,
    homepageDescription: '',
    homepageTitle: '',
    id: '',
    klarnaDisplayOnProductPage: false,
    name: '',
    payPalDetails: null,
    paymentMethods: [],
    pinterestPixelId: '',
    sezzlePublicKey: '',
    shopLogo: '',
    soldKeyword: '',
    tikTokPixelId: '',
    useResponsiveImages: true,
    mobileAppUserAgents: {
      android: '',
      ios: '',
    },
  },
};

export default function useSiteSettings() {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();
  const [shopInfo, setShopInfo] = useState<ShopInfo>(initialShopInfo.shopInfo);

  useEffect(() => {
    if (!window.cs) {
      throw new Error('No Webstore settings found');
    }

    setFeatureFlags(window.cs.featureFlags);
    setShopInfo(window.cs.shopInfo);
  }, []);

  return {
    featureFlags,
    shopInfo,
  };
}
