import { MobileMenu } from '~/types/MobileMenu';

const getMobileMenus = (obj: MobileMenu) => {
  const result: MobileMenu[] = [];

  function recursiveMobileMenus(links: MobileMenu[]) {
    for (const link of links) {
      result.push(link);
      if (link.links && link.links.length > 0 && !link.is_shop_all) {
        recursiveMobileMenus(link.links);
      }
    }
  }

  recursiveMobileMenus(obj.links);
  return result;
};
export default getMobileMenus;
