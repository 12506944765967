import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import ProductFilter from '~/components/modules/productGrid/ProductFilter';
import CollectionSorting from '~/components/modules/productGrid/CollectionSorting';
import ProductListPageStructuredData from '~/components/meta/structured-data/ProductListPageStructuredData';
import FilterContext from '~/context/FilterContext';
import Paginator from '~/components/ui/Paginator';
import ProductGrid from '~/components/modules/productGrid/ProductGrid';
import UIContext from '~/context/UIContext';
import useParams from '~/hooks/useParams';
import enums from '~/utils/enums';
import { ProductLoader } from '~/components/modules/productGrid/ProductLoader';
import useRestClient from '~/hooks/useRestClient';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { GA4Event } from '~/types/google-analytics-4-event.enum';
import { AnalyticGroups } from '~/types/analytic-groups.enum';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useWindowScrollPosition from '~/hooks/useWindowScrollPosition';
import { ScrollContext } from '~/context/ScrollContext';
import { ProductListItem } from '~/types/Product';
import { Collection } from '~/types/Collection';
import { useSiteContext } from '~/context/SiteContext';
import { SliderFeaturedCollectionLoader } from '~/components/ui/molecules/skeleton-loaders/SliderFeaturedCollectionLoader';
import ProductSlider from '~/components/product/ProductSlider';

type FilterOptionsType = {
  externalTypes?: FilterOptionItemType;
  sizes?: FilterOptionItemType;
  inStockOnly?: FilterOptionItemType;
};

type FilterOptionItemType = {
  label: string;
  values: string[];
};

type ActiveFiltersType =
  | {
      inStockOnly?: boolean;
      externalTypes?: [];
      sizes?: [];
    }
  | undefined;

const additionalOptions = {
  inStockOnly: {
    label: 'Stock',
    values: ['In stock'],
    type: 'Boolean',
  },
};

const addAdditionalOptions = (
  filterOptions: FilterOptionsType,
): FilterOptionsType => {
  return {
    ...filterOptions,
    ...additionalOptions,
  };
};

const defaultSortMethod = enums.sortMethods.featured;

const ProductGridContainer = ({
  gridData,
  config,
  parentWidget,
}: {
  gridData?: any; // eslint-disable-next-line sonarjs/cognitive-complexity
  config: any; // eslint-disable-next-line sonarjs/cognitive-complexity
  parentWidget: string; // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { getParam, deleteParam, setParam } = useParams();
  const { breakpoint } = useContext(UIContext);
  const restClient = useRestClient();
  const searchQuery = '';

  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [products, setProducts] = useState<ProductListItem[]>([]);
  const [paginationData, setPaginationData] = useState<{
    count?: number;
    total?: number;
    currentPage?: number;
    lastPage?: number;
  }>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>({});
  const [collection, setCollection] = useState<Collection>();
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const { featureFlags } = useSiteContext();

  // Default values are not set to wait for URL context
  const [activeFilters, setActiveFilters] = useState<ActiveFiltersType>();
  const [page, setPage] = useState(1);
  const [gridId, setGridId] = useState(null);
  const [cardWidth, setCardWidth] = useState('33.333%');
  const [cardCount, setCardCount] = useState(3);
  const [showFilters, setShowFilters] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortMethod, setSortMethod] = useState('');
  const [collectionSlug, setCollectionSlug] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [pageData, setPageData] = useState({ anchorTag: '' });
  const { dispatch } = useContext<any>(ScrollContext);

  const paddingTop = config?.paddingTop ?? 0;
  const paddingBottom = config?.paddingBottom ?? 0;
  const paddingLeft = config?.paddingLeft ?? 0;
  const paddingRight = config?.paddingRight ?? 0;
  const { settings } = gridData;
  const { layout = 'grid' } = settings;

  useLayoutEffect(() => {
    setShowFilters(gridData?.settings?.showFilters);
    setShowSortOptions(gridData?.settings?.showSortingOptions);
    setItemsPerPage(parseInt(gridData.settings.productsPerPage));
    setCollectionSlug(gridData.settings.collection);
    let tempCardWidth = 0;
    let cardCounts = gridData.settings.columnsOnDesktop;
    if (breakpoint.isTabletOrSmaller) {
      cardCounts = gridData.settings.columnsOnMobile;
    }
    tempCardWidth = 100 / parseInt(cardCounts);
    setCardCount(parseInt(cardCounts));
    setCardWidth(tempCardWidth + '%');
    setPageData({ ...gridData });
    setGridId(gridData?.id);
  }, [gridData, gridData.settings, breakpoint.isTabletOrSmaller]);

  useEffect(() => {
    if (!activeFilters) {
      return;
    }

    const fetchData = async () => {
      setIsFilterLoading(true);
      const res = await restClient
        .getFilterOptions({
          ...activeFilters,
          collectionKey: collectionSlug,
        })
        .finally(() => {
          setIsFilterLoading(false);
        });
      const filterOptions: FilterOptionsType = {};
      if (featureFlags?.productTypes?.filter.enabled) {
        Object.assign(filterOptions, {
          externalTypes: res.data.filterOptions.externalTypes,
        });
      }
      Object.assign(filterOptions, { sizes: res.data.filterOptions.sizes });
      setFilterOptions(filterOptions);
      setCollection(res.data.collection);
    };

    void fetchData();
  }, [
    restClient,
    setFilterOptions,
    setCollection,
    collectionSlug,
    activeFilters,
    featureFlags,
  ]);

  useEffect(() => {
    // wait until activeFilters are set via FilterContext
    // since they can come from the URL
    if (!activeFilters) {
      return;
    }
    dispatch({ type: 'increment' });
    dispatch({ type: 'isLoaded', payload: true });
    setIsLoading(true);
    restClient
      .getProducts({
        externalTypes: activeFilters?.externalTypes,
        sizes: activeFilters?.sizes,
        orderBy: sortMethod || defaultSortMethod,
        pageNumber: page,
        itemsPerPage,
        collectionKey: collectionSlug,
        searchQuery,
        inStockOnly: activeFilters?.inStockOnly ? 1 : 0,
      })
      .then(response => {
        setProducts(response.products.data);
        setPaginationData(response.products.paginatorInfo || {});
      })
      .finally(() => {
        setIsLoading(false);
        dispatch({ type: 'decrement' });
      });
  }, [
    sortMethod,
    page,
    activeFilters,
    collectionSlug,
    searchQuery,
    restClient,
    itemsPerPage,
    setIsNotFound,
    dispatch,
  ]);

  useEffect(() => {
    if (!products || products.length === 0 || !collection) {
      //don't send with 0 products or if we are on a search page
      return;
    }

    const data = {
      eventName: GA4Event.ViewItemList,
      eventDetails: {
        item_list_id: collectionSlug,
        item_list_name: collection?.title,
        items: [
          ...products.map((product: ProductListItem) => {
            return {
              item_id: product.sku,
              item_name: product.name,
            };
          }),
        ],
        send_to: AnalyticGroups.All,
      },
    };
    webstoreTracker.track(data);
  }, [products, collection, collectionSlug]);

  useWindowScrollPosition(window.location.pathname, products.length > 0);

  if (isNotFound) {
    return <Redirect to={'/404'} />;
  }
  return (
    <>
      <div
        style={
          parentWidget === 'GridBlock'
            ? { paddingTop, paddingBottom, paddingLeft, paddingRight }
            : { paddingTop: '20px', paddingBottom: '20px' }
        }
        id={pageData.anchorTag}
      >
        <FilterContext
          getParam={getParam}
          deleteParam={deleteParam}
          setParam={setParam}
          setFilters={setActiveFilters}
          setSortMethod={setSortMethod}
        >
          <div className={parentWidget !== 'GridBlock' ? 'container mb-3' : ''}>
            {collection?.canonicalUrl ? (
              <Helmet>
                <link rel="canonical" href={collection.canonicalUrl} />
              </Helmet>
            ) : (
              ''
            )}
            <div className={parentWidget !== 'GridBlock' ? 'grid gap-6' : ''}>
              <div className="col-span-full">
                <div className="grid grid-cols-2 gap-4 mb-4 lg:grid-cols-2 mx-3 mt-2">
                  <div className="float-left">
                    {showFilters && (
                      <ProductFilter
                        sidebarClassName="hidden"
                        toolbarClassName="mb-2 sm:mb-0 block col-span-1 col-start-1 w-full"
                        onFilterUpdate={(data: ActiveFiltersType) =>
                          setActiveFilters({ ...data })
                        }
                        filters={addAdditionalOptions(filterOptions)}
                      />
                    )}
                  </div>

                  <div className="float-right">
                    {showSortOptions && !isFilterLoading && (
                      <CollectionSorting
                        sortMethod={sortMethod}
                        sortMethodCallback={null}
                      />
                    )}
                  </div>
                </div>
                {layout === 'grid' && (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${cardCount}, ${cardWidth})`,
                    }}
                  >
                    <ProductLoader
                      totalThumbs={itemsPerPage}
                      ready={!isLoading}
                    >
                      <ProductGrid
                        products={products}
                        productClassName={'mb-3 p-2'}
                        cardSettings={gridData.settings}
                      />
                    </ProductLoader>
                  </div>
                )}

                {layout === 'grid' && !isLoading && (
                  <>
                    <Paginator
                      pageContext={{ lastPage: paginationData?.lastPage || 0 }}
                      onPageUpdate={setPage}
                      activePage={page}
                      gridId={gridId}
                    />
                  </>
                )}
              </div>
            </div>

            {layout === 'grid' && paginationData?.currentPage && (
              <>
                <ProductListPageStructuredData
                  title={collectionSlug || 'Featured'}
                  location={window.location}
                  page={paginationData?.currentPage}
                />
              </>
            )}
          </div>
          {layout === 'slider' && (
            <SliderFeaturedCollectionLoader ready={!isLoading}>
              <ProductSlider slug={collectionSlug} products={products} />
            </SliderFeaturedCollectionLoader>
          )}
        </FilterContext>
      </div>
    </>
  );
};

export default React.memo(ProductGridContainer);
