import React, { useCallback, useEffect, useRef, useState } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Button from '~/components/ui/Button';
import ExpiryTimer from './ExpiryTimer';
import Icons from '~/components/ui/Icons';
import LineItem from '~/components/cart/LineItem';
import Link from '~/components/ui/Link';
import cn from 'classnames';
import { useCart } from '~/context/CartContext';
import usePrice from '~/hooks/usePrice';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { GA4Event } from '~/types/google-analytics-4-event.enum';
import { AnalyticGroups } from '~/types/analytic-groups.enum';
import { getVariantPriceDollars } from '~/models/helpers/inventory';
import { ShoppingCartItem } from '~/types/ShoppingCartItem';
import freshpaintSessionTracker from '~/helpers/trackers/freshpaintSessionTrackerWrapper';
import GiftItem from './GiftItem';
import { GiftItemProps } from '~/types/GiftItemTypes';

const MiniCart: React.FC<{
  data: { openTrigger: any };
  pathname: string;
  openLinksInNewTab: boolean;
}> = ({ data, pathname, openLinksInNewTab = false }) => {
  const { openTrigger } = data;
  const { notice, cart, miniCartOpen = false, toggleMiniCart } = useCart();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState();

  const scrollableRef = useCallback(node => {
    if (node !== null) {
      setScrollable(node);
    }
  }, []);

  const handleClick = useCallback(
    event => {
      if (
        scrollable &&
        !openTrigger.current.contains(event.target) &&
        (triggerRef.current?.contains(event.target) ||
          !containerRef.current?.contains(event.target))
      ) {
        toggleMiniCart(false);
      }
    },
    [openTrigger, scrollable, toggleMiniCart],
  );
  useEffect(() => {
    //effect locks focus to the modal when it is open
    const currentContainer = containerRef.current;
    if (miniCartOpen) {
      currentContainer?.focus();
      //add any focusable HTML element you want to include to this string
      const focusableElements = currentContainer?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      if (!focusableElements) {
        return;
      }

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleTabKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            (lastElement as HTMLElement)?.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            (firstElement as HTMLElement)?.focus();
          }
        }
      };

      const handleEscapeKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          toggleMiniCart(false);
        }
      };

      currentContainer?.addEventListener('keydown', handleTabKeyPress);
      currentContainer?.addEventListener('keydown', handleEscapeKeyPress);

      return () => {
        currentContainer?.removeEventListener('keydown', handleTabKeyPress);
        currentContainer?.removeEventListener('keydown', handleEscapeKeyPress);
      };
    }
  }, [miniCartOpen, containerRef, toggleMiniCart]);

  // Lock page scroll on minicart open
  useEffect(() => {
    // If "scrollable" is undefined for some reason
    // the minicart will still open, but just won't have
    // scroll lock enabled.
    if (miniCartOpen && scrollable) {
      // Using setTimeout to delay pushing callback to call stack
      // so that this works when adding a product to the cart.
      setTimeout(() => {
        disablePageScroll(scrollable);
      }, 10);
    } else {
      enablePageScroll(scrollable);
    }
  }, [miniCartOpen, scrollable]);

  useEffect(() => {
    if (!miniCartOpen || !cart) {
      return;
    }

    webstoreTracker.track({
      eventName: GA4Event.ViewCart,
      eventDetails: {
        currency: 'USD',
        value: cart?.subtotalCents / 100,
        items: [
          ...cart.items.map((item: ShoppingCartItem) => {
            return {
              item_id: item?.variant?.product?.sku,
              item_name: item?.variant?.product?.name,
              currency: 'USD',
              price: item?.variant
                ? getVariantPriceDollars(item?.variant)
                : null,
              quantity: item?.quantity,
            };
          }),
        ],
        send_to: AnalyticGroups.All,
      },
    });
    freshpaintSessionTracker?.track({
      eventName: 'view cart',
      eventDetails: { location: 'website' },
    });
  }, [miniCartOpen, cart]);

  useEffect(() => {
    toggleMiniCart(false);
    /**
     * eslint disable is rqeuired because adding toggleMiniCart as a dependency causes
     * this to trigger on every re-render
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (miniCartOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [miniCartOpen, handleClick]);

  const subtotalPrice = usePrice(cart?.subtotalCents) || '$0.00';

  return (
    <div
      className={cn('fixed w-screen h-screen transform z-50', {
        'translate-x-0': miniCartOpen,
        'translate-x-full delay-300': !miniCartOpen,
        none: pathname === '/cart',
      })}
      aria-hidden={!miniCartOpen}
    >
      <span
        className={cn(
          'fixed w-screen h-screen bg-ally-0 transition-opacity duration-300 ease-in-out',
          {
            'opacity-60': miniCartOpen,
            'opacity-0': !miniCartOpen,
          },
        )}
      />

      <div
        ref={containerRef}
        className={cn(
          'mini-cart fixed top-0 right-0 z-50 flex flex-col bg-main-0 text-ally-0 h-screen w-full shadow-panel transform transition-transform duration-300 ease-in-out',
          {
            'translate-x-0': miniCartOpen,
            'translate-x-full': !miniCartOpen,
          },
        )}
        role="dialog"
        aria-labelledby="View Cart"
        tabIndex={-1}
      >
        <div className="flex-1 overflow-auto" ref={scrollableRef}>
          <div className="flex justify-end px-5 pt-5">
            <button
              onClick={() => toggleMiniCart()}
              ref={triggerRef}
              aria-label="Close mini-cart"
              disabled={!miniCartOpen}
            >
              <Icons type="close" className="w-6" ariaLabel="Close mini-cart" />
            </button>
          </div>

          {cart?.items?.length && notice ? (
            <div className="px-6 pt-4">
              <div
                className="p-2 text-center border rounded border-grey text-tiny lg:text-tiny-lg"
                role="status"
              >
                {notice}
                <button
                  className="underline ml-2"
                  onClick={() => toggleMiniCart(false)}
                  disabled={!miniCartOpen}
                >
                  Continue shopping.
                </button>
              </div>
            </div>
          ) : null}

          <ExpiryTimer
            className="mx-2 mt-5 mb-3 text-center"
            expiresAt={cart?.freeShippingUntil}
            countdownPrefix="Free shipping if you pay within:"
            expiredText={'Free shipping as expired'}
          />

          <div className="px-5">
            {cart?.items && cart?.items.length ? (
              cart?.items.map((lineItem: ShoppingCartItem, index: number) => (
                <LineItem
                  key={lineItem.variant.id}
                  lineItem={lineItem}
                  context="minicart"
                  isHidden={!miniCartOpen}
                  className={cn('px-3 pt-4 pb-5 border-grey', {
                    'border-b': index + 1 < cart.items.length,
                  })}
                />
              ))
            ) : (
              <p className="px-3 py-4">Your cart is empty</p>
            )}
          </div>
          <div>
            {cart?.includedGifts?.map((item: GiftItemProps) => {
              return (
                <GiftItem
                  key={`gift-item-${item.productId}`}
                  productId={item.productId}
                  productName={item.productName}
                  productDescription={item.productDescription}
                  productImage={item.productImage}
                  priceCents={item.priceCents}
                />
              );
            })}
          </div>
        </div>
        <div className="sticky bottom-0 w-full px-6 py-4 border-t border-grey bg-main-0">
          <div className="flex justify-between mb-4 text-subtitle lg:text-subtitle-lg">
            <span>Subtotal</span>
            <p>{subtotalPrice}</p>
          </div>

          <Button
            className="w-full text-center"
            attrs={{
              to: '/checkout',
              blank: openLinksInNewTab,
              tabIndex: miniCartOpen ? 0 : -1,
            }}
            button={{
              text: 'Checkout',
            }}
          />
          <div className="mt-4 text-center">
            <Link
              className="underline text-small lg:text-small-lg"
              to={'/cart'}
              blank={openLinksInNewTab}
              tabIndex={miniCartOpen ? 0 : -1}
            >
              View cart
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
