const enums = {
  productTypes: {
    giftCard: 'giftcard',
  },
  sortMethods: {
    featured: 'FEATURED',
    bestSelling: 'BEST_SELLING',
    alphaAsc: 'ALPHA_ASC',
    alphaDesc: 'ALPHA_DESC',
    priceAsc: 'PRICE_ASC',
    priceDesc: 'PRICE_DESC',
  },
};

export default enums;
