import React, { FC } from 'react';
import { LiveReplaysContainer } from '~/components/live/live-replays/LiveReplaysContainer';
type LiveReplaysDataProps = {
  anchorTag: string;
};
const LiveReplaysModule: FC<{
  config: any;
  parentWidget: string;
  data: LiveReplaysDataProps;
}> = ({ config, parentWidget, data }) => {
  const paddingTop = config?.paddingTop ?? 0;
  const paddingBottom = config?.paddingBottom ?? 0;
  const paddingLeft = config?.paddingLeft ?? 0;
  const paddingRight = config?.paddingRight ?? 0;
  const { anchorTag } = data;
  return (
    <div
      style={
        parentWidget === 'GridBlock'
          ? { paddingTop, paddingBottom, paddingLeft, paddingRight }
          : {}
      }
      className={parentWidget !== 'GridBlock' ? 'container' : ''}
    >
      <div className="py-5 pb-3" id={anchorTag}>
        <div className={'mt-6'}>
          <LiveReplaysContainer />
        </div>
      </div>
    </div>
  );
};

export { LiveReplaysModule };
