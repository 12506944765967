import React, { lazy, Suspense } from 'react';
import { LOTWConnectorProps } from './LOTWConnector';

const LOTWConnector = lazy(() =>
  import('~/components/live/LOTWConnector').then(({ LOTWConnector }) => ({
    default: LOTWConnector,
  })),
);

export const LoadableLOTWConnector = (props: LOTWConnectorProps) => {
  return (
    <Suspense fallback={<span />}>
      <LOTWConnector {...props} />
    </Suspense>
  );
};
