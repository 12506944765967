import React, { useContext, useLayoutEffect, useState } from 'react';
import ProductForm from '~/components/product/ProductForm';
import ProductGallery from '~/components/product/ProductGallery';
import { ScrollContext } from '~/context/ScrollContext';
import useRestClient from '~/hooks/useRestClient';

type ModalPropsType = {
  id: number;
  showModal: boolean;
  onrequestClose: any;
};
const Modal: React.FC<ModalPropsType> = ({ id, showModal, onrequestClose }) => {
  const { dispatch } = useContext<any>(ScrollContext);
  const [products, setProducts] = useState<any>({});
  const restClient = useRestClient();
  useLayoutEffect(() => {
    dispatch({ type: 'increment' });
    if (id > 0 && showModal) {
      restClient
        .getDropshipProductsDetail({ id })
        .then(response => {
          setProducts(response.data);
        })
        .finally(() => {
          dispatch({ type: 'decrement' });
        });
    }
  }, [id, showModal, dispatch, restClient]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto"
              style={{ width: '100vh' }}
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div>
                  <button
                    style={{
                      float: 'right',
                      fontSize: '22px',
                      padding: '10px',
                    }}
                    onClick={() => onrequestClose(false)}
                  >
                    X
                  </button>
                </div>
                <div
                  style={{ height: '75vh', overflow: 'auto' }}
                  className="relative p-6 flex-auto"
                >
                  <div className="py-6">
                    <div>
                      <div className="flex flex-col md:flex-row -mx-4">
                        <div className="md:flex-1 px-4">
                          <div>
                            {products?.id && (
                              <ProductGallery
                                key={products.id}
                                activeVariantId={products.id}
                                product={products}
                                images={products?.images}
                                videoSource={''}
                                videoUrl={''}
                                initialSlide={0}
                              />
                            )}
                          </div>
                        </div>
                        <div className="md:flex-1 px-4">
                          {products?.id && (
                            <ProductForm
                              key={products?.id}
                              product={products}
                              linkedVariantId={products.id}
                              defaultVariantId={products.id}
                              setActiveVariantId={(value: any) =>
                                console.log(value)
                              }
                              splitAttr={['color']}
                              disableUrlUpdates={false}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-9 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
