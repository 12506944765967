export enum ContentGroups {
  HomePage = 'home',
  CollectionPage = 'collection',
  CategoryPage = 'category',
  QuickView = 'product_quickview',
  PDP = 'product_detail',
  Page = 'custom_page',
  Search = 'search_results',
  Live = 'live',
  Replay = 'live_replay',
  Cart = 'cart_page',
}
