const usePrice = (price, showCurrency = true) => {
  if (price === null) {
    return '';
  }
  const money = price / 100;

  const config = Object.assign(
    {
      currency: 'USD',
      minimumFractionDigits: 2,
    },
    showCurrency
      ? {
          style: 'currency',
        }
      : {},
  );

  const formattedPrice = Intl.NumberFormat(undefined, config).format(
    parseFloat(money ? money : 0),
  );

  return formattedPrice;
};

export default usePrice;
