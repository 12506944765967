import { InventoryItem } from '~/types';
import { Variant } from '~/types/Variant';

export function buildSizesFromInventory(inventory: InventoryItem[]): string {
  const combinedBySize = inventory
    .filter(item => !!item.size)
    .reduce<Record<string, number>>((acc, curr) => {
      acc[curr.size] = (acc[curr.size] || 0) + curr.quantity;

      return acc;
    }, {});

  return combinedBySize ? buildString(combinedBySize, 'Sizes: ') : '';
}

export function buildColorsFromInventory(inventory: InventoryItem[]): string {
  const combinedByColor = inventory
    .filter(item => !!item.color)
    .reduce<Record<string, number>>((acc, curr) => {
      acc[curr.color] = (acc[curr.color] || 0) + curr.quantity;

      return acc;
    }, {});

  return combinedByColor ? buildString(combinedByColor, 'Colors: ') : '';
}

const buildString = (obj: any, prefix: string) =>
  Object.entries(obj)
    .filter(([item]) => item)
    .reduce<string>((theString, [item, quantity], currentIndex, orig) => {
      const lastIndex = orig.length - 1;
      const isLastIndex = currentIndex === lastIndex;

      if (!quantity) {
        // Markdown interpreter makes this a strikethrough
        item = `~~${item}~~`;
      }

      const withoutComma = theString + item;
      const withComma = withoutComma + ', ';
      const returnString = isLastIndex ? withoutComma : withComma;

      if (currentIndex === 0) {
        return prefix + returnString;
      } else {
        return returnString;
      }
    }, '');

export function getVariantPriceDollars(variant: Variant): number | null {
  if (variant.salePriceCents) {
    return variant.salePriceCents / 100;
  }

  if (variant.priceCents) {
    return variant.priceCents / 100;
  }

  return null;
}
