import React, { useState } from 'react';

import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import client from '~/utils/client';
import { isEmail } from '~/utils/helpers';

const successMessage = "You've subscribed!";
const failureMessage = 'Something happened, please try again later.';
const isNotEmailMessage = 'Not a valid email.';

// Commented code is for https://commentsold.atlassian.net/browse/WEB-1
// It's part of the feature, but we'll need to wire up with the backend too

type NewsletterSubscribeProps = {
  inputID: string;
  className: string;
  ctaText?: string;
};
const NewsletterSubscribe: React.FC<NewsletterSubscribeProps> = ({
  inputID,
  className,
  ctaText = 'Join',
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [success, setSuccess] = useState(false);

  // const [isEmailAgreed, setIsEmailAgreed] = useState(false);
  // const { name } = useShopInfo();
  // const handleCheckboxChange = e => {
  //   setIsEmailAgreed(e.target.checked);
  // };

  const updateEmail = (value: string) => {
    setEmail(value);

    if (value) {
      if (isEmail(value)) {
        setError('');
      } else {
        setError(isNotEmailMessage);
      }
    }
  };

  const subscribe = async () => {
    setLoading(true);
    setComplete(false);

    const addEmailToListSuccess = await client.addEmailToList(email);

    setLoading(false);
    setComplete(true);
    setSuccess(addEmailToListSuccess);
  };

  const getInputMessage = () => {
    if (complete && !success) {
      return failureMessage;
    } else {
      if (error) {
        return error;
      }
    }

    return '';
  };

  return (
    <div className={className}>
      {success ? (
        <span className="text-small lg:text-small-lg">{successMessage}</span>
      ) : (
        <>
          <Input
            fullWidthBreakpoint="2-xl" // Basically want this always full width
            onValue={updateEmail}
            onSubmit={subscribe}
            attrs={{
              placeholder: 'Email',
              type: 'email',
              id: inputID,
              className: 'input-no-mobile w-full',
              value: email,
            }}
            input={{
              error: getInputMessage(),
            }}
            attachedButton={
              <Button
                className="w-full sm:w-auto whitespace-nowrap"
                attrs={{
                  disabled: loading || !!error || !email, // || !isEmailAgreed,
                  onClick: subscribe,
                }}
                button={{ text: ctaText }}
              />
            }
          />

          {/*<div className="mt-4 text-left">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    id="emailAgree"*/}
          {/*    value={isEmailAgreed}*/}
          {/*    onChange={handleCheckboxChange}*/}
          {/*    className="mr-2"*/}
          {/*  />*/}
          {/*  <label htmlFor="emailAgree">*/}
          {/*    I agree to receive promotional emails and offers from {name}.*/}
          {/*    Unsubscribe at any time.*/}
          {/*  </label>*/}
          {/*</div>*/}
        </>
      )}
    </div>
  );
};

export default NewsletterSubscribe;
