import { useCallback, useEffect, useRef, useState } from 'react';
import useRestClient from '~/hooks/useRestClient';
import { LiveReplay as LiveReplayModel } from '~/types';

export const useReplays = ({
  influencerSlug,
}: { influencerSlug?: string } = {}) => {
  // When has_more is true, hold a reference to the ID where we cutoff.
  // Doing this as a ref so it doesn't trigger an update in any callbacks.
  const cutoffId = useRef<number>();
  const hasMore = cutoffId.current !== undefined;

  // To solve the problem of my setters happening async after this component
  // has unmounted.
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  const [replays, setReplays] = useState<LiveReplayModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const restClient = useRestClient();

  const loadMore = useCallback(
    async (startingAfter?: number) => {
      restClient
        .getLiveReplays({ startingAfter, influencerSlug })
        .then(response => {
          if (!isMounted.current) return;
          const replayBatch: LiveReplayModel[] = response.data?.data || [];
          const replayBatchWithoutLives = replayBatch.filter(
            replay => !replay.is_live,
          );

          setReplays(prevReplays => {
            return prevReplays.concat(replayBatchWithoutLives);
          });

          const hasMore = response.data.has_more;
          const lastReplay = response.data.data[response.data.data.length - 1];

          cutoffId.current = hasMore ? lastReplay.id : undefined;
        })
        .catch(() => {
          if (!isMounted.current) return;

          setIsError(true);
        })
        .finally(() => {
          if (!isMounted.current) return;

          setIsLoading(false);
        });
    },
    [influencerSlug, restClient],
  );

  useEffect(() => {
    void loadMore();
  }, [loadMore]);

  const checkForMore = useCallback(async () => {
    if (cutoffId.current) {
      await loadMore(cutoffId.current);
    }
  }, [loadMore]);

  return { replays, isLoading, isError, hasMore, checkForMore };
};
