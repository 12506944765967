import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store/store';

type InfluencerState = {
  slug: string | null;
  id: number | null;
};

const slice = createSlice({
  name: 'influencer',
  initialState: { slug: null, id: null } as InfluencerState,
  reducers: {
    setInfluencer: (
      state,
      { payload }: PayloadAction<{ id: number; slug: string } | null>,
    ) => {
      if (!payload) {
        state.id = null;
        state.slug = null;
        return;
      }

      state.id = payload.id;
      state.slug = payload.slug;
    },
  },
});

export const { setInfluencer } = slice.actions;

export default slice.reducer;

export const selectInfluencer = (state: RootState) => state.influencer;
