import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import FilterModal from '~/components/ui/FilterModal';
import cn from 'classnames';
import { withFilterContext } from '~/context/FilterContext';
import { FiFilter } from 'react-icons/fi';

interface ActiveFilters {
  [key: string]: boolean | string[] | undefined;
  inStockOnly?: boolean;
  externalTypes?: string[];
  sizes?: string[];
}

/**
 * FilterCategory is, for example, for filtering "Colors" to "Red" and "Blue" and
 * lies within a FilterSection
 */
const FilterCategory: React.FC<{
  categoryKey: string;
  filter: Filter;
  activeFilters: ActiveFilters;
  applyFilterChange: (params: ApplyFilterChangeParams) => void;
  className: string;
}> = ({
  categoryKey,
  filter,
  activeFilters = {},
  applyFilterChange,
  className = '',
}) => {
  const [expanded, setExpanded] = useState(true);
  const [showAllFilters, setShowAllFilters] = useState(false);

  const defaultVisibleFilters = 8;
  const { label, values, type } = filter;
  const removeEmptyOptions = (options: string[] | never[]) => {
    return options.filter(option => option);
  };

  return (
    <div className={className}>
      <Button
        className="w-full text-left text-small lg:text-small-lg mb-2 capitalize"
        attrs={{ onClick: () => setExpanded(!expanded) }}
        button={{
          text: label,
          style: 'text',
          rounded: false,
          icon: expanded ? 'minus' : 'plus',
        }}
      />

      <AnimateHeight
        height={expanded ? 'auto' : 0}
        className="border-b border-grey"
      >
        <div>
          {(showAllFilters
            ? removeEmptyOptions(values)
            : removeEmptyOptions(values.slice(0, defaultVisibleFilters))
          ).map((option, index) => {
            let checked = false;

            // Check if the type is a boolean before assuming it is an array
            if (
              activeFilters[categoryKey] &&
              (activeFilters[categoryKey] === true ||
                (activeFilters[categoryKey] as string[]).includes(option))
            ) {
              checked = true;
            }

            return (
              <Input
                key={`filter-${categoryKey}-${option}`}
                onValue={newCheckedValue =>
                  applyFilterChange({
                    filter: categoryKey,
                    option,
                    on: newCheckedValue,
                    type: type ?? '',
                  })
                }
                className={cn({
                  'mb-4': index < values.length - 1,
                })}
                attrs={{
                  type: 'checkbox',
                  id: `filter-${categoryKey}-${option}`,
                  checked,
                  labelClassName: 'text-tiny max-lines-1',
                }}
                input={{ label: option }}
              />
            );
          })}

          {!showAllFilters && values.length > defaultVisibleFilters && (
            <Button
              attrs={{
                onClick: () => setShowAllFilters(true),
              }}
              className="block text-grey text-tiny text-left leading-filters"
              button={{
                text: 'See more',
                style: 'text',
              }}
            />
          )}
        </div>
      </AnimateHeight>
    </div>
  );
};

interface Filters {
  [key: string]: Filter | undefined;
  inStockOnly?: Filter;
  externalTypes?: Filter;
  sizes?: Filter;
}

interface Filter {
  label: string;
  type?: string;
  values: string[];
}
/**
 * FilterSection is the entire filtering area. This is a component because it is
 * replicated within the sidebar and the modal.
 */
const FilterSection = ({
  resetFilters,
  filters,
  activeFilters,
  applyFilterChange,
}: {
  resetFilters: () => void;
  filters: Filters;
  activeFilters: ActiveFilters;
  applyFilterChange: (params: ApplyFilterChangeParams) => void;
}) => (
  <>
    <div className="flex justify-between mt-1/2 mb-2 pb-2 border-b border-grey">
      <span>Filters</span>

      <Button
        className="text-grey text-tiny lg:text-tiny-lg self-end"
        attrs={{
          onClick: resetFilters,
        }}
        button={{ text: 'Clear', style: 'text' }}
      />
    </div>

    {Object.keys(filters).map((categoryKey, index) => (
      <FilterCategory
        categoryKey={categoryKey}
        key={`filter-${categoryKey}-${index}`}
        filter={filters[categoryKey] as Filter}
        activeFilters={activeFilters}
        applyFilterChange={applyFilterChange}
        className="mt-2 mb-4"
      />
    ))}
  </>
);

interface ApplyFilterChangeParams {
  filter: string;
  option: string;
  on: string | boolean;
  type: string;
}
/**
 * CollectionFilters is the main component.
 *
 * The "tempActiveFilters" and "tempActiveFilterCount" are used within the
 * modal before the "Apply" button is selected. These values get reset to
 * their default values whenever the modal is closed and set to the values
 * of "activeFilters" and "activeFilterCount" when it is opened to
 * maintain consistency.
 */
const CollectionFilters = ({
  filters = {},
  onFilterUpdate,
  sidebarClassName,
  toolbarClassName,
  activeFilters,
  setActiveFilters,
  activeFilterCount,
}: {
  filters: Filters;
  onFilterUpdate: (filter: {
    [key: string]: boolean | string[] | string;
  }) => void;
  sidebarClassName: string;
  toolbarClassName: string;
  activeFilters: ActiveFilters;
  setActiveFilters: (filters: {
    [key: string]: boolean | string[] | string;
  }) => string;
  activeFilterCount: number;
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [tempActiveFilters, setTempActiveFilters] = useState({});
  const [tempActiveFilterCount, setTempActiveFilterCount] = useState(0);
  const [tempResetFilters, setTempResetFilters] = useState(false);

  const applyFilterChange = ({
    filter,
    option,
    on,
    type,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  ApplyFilterChangeParams) => {
    /**
     * showMobileFilters is used extensively in this function to ensure we're
     * updating either the temp values or the actual values prior to applying
     * the changes via the "Apply" button in the modal
     */

    const activeFilterObject = Object.assign(
      showMobileFilters ? tempActiveFilters : activeFilters,
    ) as { [key: string]: string | boolean | string[] };

    /**
     * Build out the new filter object based on the filter (Color), the
     * option (Red, Blue) and account for it being a potential boolean
     * from additioanlOptions
     */
    if (on) {
      if (!activeFilterObject[filter]) {
        activeFilterObject[filter] = [];
      }

      if (!(activeFilterObject[filter] as string[]).includes(option)) {
        if (type === 'Boolean') {
          activeFilterObject[filter] = on;
        } else {
          (activeFilterObject[filter] as string[]).push(option);
        }
      }
    } else {
      if (activeFilterObject[filter]) {
        if (activeFilterObject[filter] === true) {
          delete activeFilterObject[filter];
        } else if ((activeFilterObject[filter] as string).includes(option)) {
          if ((activeFilterObject[filter] as string).length === 1) {
            delete activeFilterObject[filter];
          } else {
            const optionIndexInFilters = (
              activeFilterObject[filter] as string[]
            ).indexOf(option);

            (activeFilterObject[filter] as string[]).splice(
              optionIndexInFilters,
              1,
            );
          }
        }
      }
    }

    if (showMobileFilters) {
      setTempResetFilters(false);
      setTempActiveFilterCount(tempActiveFilterCount + (on ? 1 : -1));
      setTempActiveFilters(activeFilterObject);
    } else {
      setActiveFilters(activeFilterObject);
      onFilterUpdate(activeFilterObject);
    }
  };

  const beforeApplyResetFilters = () => {
    if (showMobileFilters) {
      setTempActiveFilters({});
      setTempActiveFilterCount(0);
      setTempResetFilters(true);
    } else {
      applyResetFilters();
    }
  };

  const applyResetFilters = () => {
    setActiveFilters({});
    setTempActiveFilters({});
    setTempActiveFilterCount(0);
    onFilterUpdate({});
  };

  const openModal = () => {
    setShowMobileFilters(true);
    setTempActiveFilters(activeFilters);
    setTempActiveFilterCount(activeFilterCount);
    setTempResetFilters(false);
  };

  const closeModal = (applyFilters: boolean) => {
    if (applyFilters) {
      if (tempResetFilters) {
        applyResetFilters();
      } else {
        setActiveFilters(tempActiveFilters);
        onFilterUpdate(tempActiveFilters);
      }
    }

    setTempResetFilters(false);
    setTempActiveFilters({});
    setTempActiveFilterCount(0);
    setShowMobileFilters(false);
  };

  const style = 'primary',
    rounded = true,
    size = 'regular';
  return (
    <>
      <div className={toolbarClassName}>
        <button
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px 16px',
            gap: '8px',
            height: '48px',
            background: '#F4F3F6',
            borderRadius: '6px',
            color: '#455af3',
          }}
          onClick={openModal}
        >
          <FiFilter size={'1.5rem'} style={{ color: '#455af3' }} />{' '}
          <span className="font-semibold">{`Filters (${activeFilterCount})`}</span>
        </button>
      </div>

      <FilterModal
        title={''}
        open={showMobileFilters}
        onRequestClose={() => closeModal(false)}
      >
        <div className="flex flex-col h-full" style={{ gap: 15 }}>
          <div className="h-full overflow-auto">
            <FilterSection
              resetFilters={beforeApplyResetFilters}
              filters={filters}
              activeFilters={tempActiveFilters}
              applyFilterChange={applyFilterChange}
            />
          </div>
          <button
            className={cn('button', 'flex-shrink-0', 'font-semibold', {
              [`button--${style}`]: !!style,
              rounded: rounded,
              [`button--${size}`]: !!size,
            })}
            onClick={() => closeModal(true)}
            style={{
              color: '#fff',
              backgroundColor: '#455af3',
            }}
          >
            Apply
          </button>
        </div>
      </FilterModal>
    </>
  );
};

export default React.memo(withFilterContext(CollectionFilters));
