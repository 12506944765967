import React, { useContext, useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import { ContainerGrid } from '~/components/layout/PageStructure';
import Link from '~/components/ui/Link';
import PropTypes from 'prop-types';
import Slider from '~/components/ui/Slider';
import UIContext from '~/context/UIContext';
import cn from 'classnames';
import { placeholderImage } from '~/utils/placeholders';
import { isMoreDarkThanLight } from '~/utils/helpers';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import { SlideShowMedia } from '~/constants/responsiveImageConstants';
import useShopInfo from '~/hooks/live/useShopInfo';

const Slideshow = ({ data = {}, config, parentWidget }) => {
  const { settings = {}, blocks = [], anchorTag } = data;
  const { autoAdvance = false, delay = 5000 } = settings;
  const { breakpoint } = useContext(UIContext);
  const { useResponsiveImages } = useShopInfo();
  const enableSlideDots = blocks.length > 1;
  const [blocksInsideSlideShow, setBlocksInsideSlideShow] = useState([]);

  const paddingTop = config?.paddingTop ?? 0;
  const paddingBottom = config?.paddingBottom ?? 0;
  const paddingLeft = config?.paddingLeft ?? 0;
  const paddingRight = config?.paddingRight ?? 0;

  const marginTop = config?.marginTop ?? 0;
  const marginBottom = config?.marginBottom ?? 0;
  const marginLeft = config?.marginLeft ?? 0;
  const marginRight = config?.marginRight ?? 0;

  useEffect(() => {
    const insideBlocks = blocks.filter(elements => {
      return elements != null && elements !== undefined && elements !== '';
    });
    setBlocksInsideSlideShow(insideBlocks);
  }, [blocks]);

  return (
    <div
      style={
        parentWidget === 'GridBlock'
          ? {
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              marginTop,
              marginBottom,
              marginLeft,
              marginRight,
            }
          : {}
      }
      className={parentWidget !== 'GridBlock' ? 'slideshow' : ''}
      id={anchorTag}
    >
      <Slider
        options={{
          layout: 'fullwidth',
          slidesPerView: 1,
          autoplay: autoAdvance,
          autoplayDelay: delay,
          autoHeight: true,
          spaceBetween: 0,
          loop: true,
          freeMode: false,
          breakpoints: {
            768: {
              spaceBetween: 0,
            },
          },
          pagination: breakpoint.isTabletOrSmaller
            ? false
            : enableSlideDots && {
                clickable: true,
              },
        }}
      >
        {blocksInsideSlideShow &&
          blocksInsideSlideShow.map(block => {
            const {
              id,
              image = placeholderImage('landscapeHuge'),
              mobileImage,
              title,
              description,
              cta,
              uri,
              contentPosition = 'left',
              textColor = '#000000',
            } = block;
            const hasContent = title || description || (uri && cta);
            let imageContainerClass = 'w-full';

            if (hasContent) {
              imageContainerClass =
                'object-cover object-center absolute top-0 left-0 w-full h-full';
            }

            const imageUrl =
              breakpoint.isTabletOrSmaller && mobileImage?.url
                ? mobileImage.url
                : image.url;

            const desktopMedia = image.mediatype;

            return (
              <div
                className={cn('slideshow-slide flex h-full', {
                  'py-4 py-md-9 has-content': hasContent,
                })}
                key={`slideshow-slide-${id}`}
              >
                <div className={imageContainerClass}>
                  {desktopMedia === 'video/mp4' && (
                    <video width="100%" autoPlay playsInline loop>
                      <source src={imageUrl} type="video/mp4" />
                    </video>
                  )}{' '}
                  {desktopMedia !== 'video/mp4' && (
                    <ResponsiveImage
                      media={
                        useResponsiveImages
                          ? SlideShowMedia
                          : [{ width: 2560, useAsFallback: true }]
                      }
                      url={imageUrl}
                      altText={description ? description : 'Banner image'}
                      aspectRatio="natural"
                      className={cn({
                        'h-full': hasContent,
                      })}
                    />
                  )}
                  {uri && (
                    <Link
                      aria-label={description ? description : 'Banner image'}
                      className="absolute top-0 bottom-0 left-0 right-0"
                      ignoreRouter={true}
                      to={uri}
                    />
                  )}
                </div>
                {hasContent && (
                  <ContainerGrid className="self-center">
                    <div
                      className={cn(
                        'flex flex-col justify-center col-span-4 md:col-span-10 md:col-start-2',
                        {
                          'items-start': contentPosition === 'left',
                          'items-center text-center':
                            contentPosition === 'center',
                        },
                      )}
                    >
                      {title && (
                        <h1
                          className={cn(
                            'relative pointer-events-none mb-5 text-shadow',
                          )}
                          style={{
                            color: textColor,
                            textShadow: isMoreDarkThanLight(textColor)
                              ? '#000 1px 0 10px'
                              : '',
                          }}
                        >
                          {title}
                        </h1>
                      )}
                      {description && (
                        <p
                          className={cn(
                            'relative pointer-events-none mb-3 mb-md-7 text-shadow text-subtitle lg:text-subtitle-lg',
                          )}
                          style={{
                            color: textColor,
                            textShadow: isMoreDarkThanLight(textColor)
                              ? '#000 1px 0 10px'
                              : '',
                          }}
                        >
                          {description}
                        </p>
                      )}
                      {uri && cta && (
                        <Button attrs={{ to: uri }} button={{ text: cta }} />
                      )}
                    </div>
                  </ContainerGrid>
                )}
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

Slideshow.propTypes = {
  data: PropTypes.shape({
    autoAdvance: PropTypes.bool,
    delay: PropTypes.number,
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
        title: PropTypes.string,
        description: PropTypes.string,
        cta: PropTypes.string,
        uri: PropTypes.string,
        contentPosition: PropTypes.string,
        textColor: PropTypes.string,
      }),
    ).isRequired,
  }),
};

export default React.memo(Slideshow);
