declare global {
  interface Window {
    // this comes from the CommentSold repo
    // freshpaint-session-tracker.blade.php
    freshpaintSessionTracker?: {
      track({
        eventName,
        eventDetails,
      }: {
        eventName: string;
        eventDetails?: unknown;
      }): void;
      startSession(): void;
      getSession(): { uuid: string; createdAt: number };
      getSessionTimeout(): number;
      addBreadcrumb(): void;
    };
  }
}

const freshpaintSessionTracker = window.freshpaintSessionTracker;

export default freshpaintSessionTracker;
