import { useEffect, useState } from 'react';

const getParam = (paramName: string) => {
  const searchString = window.location.search.substring(1);
  let i, val;
  const params = searchString.split('&');

  for (i = 0; i < params.length; i++) {
    val = params[i].split('=');
    if (val[0] === paramName) {
      return val[1];
    }
  }
  return null;
};

export default function useUserAgent() {
  const [userAgent, setUserAgent] = useState<string>('');
  const useragent = getParam('useragent');

  useEffect(() => {
    setUserAgent(useragent || window.navigator.userAgent);
  }, [useragent]);

  return userAgent;
}
