import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store/store';
import { ProductListItem } from '~/types/Product';

type ProductsState = {
  products: ProductListItem[];
};

const slice = createSlice({
  name: 'products',
  initialState: { products: [] } as ProductsState,
  reducers: {
    setProducts: (state, { payload }: PayloadAction<ProductListItem[]>) => {
      state.products = payload;
    },
  },
});

export const { setProducts } = slice.actions;

export default slice.reducer;

export const selectProducts = (state: RootState) => state.products;
