import {
  addEmailToList,
  cartAdd,
  cartGet,
  cartRemove,
  cartUpdate,
  waitlistRemove,
} from '~/utils/queries';
import axios from 'axios';
import config from '../config.js';

const GRAPH_QL_ENDPOINT = window.endpoint || `${config.API_BASE_URL}/graphql`;
const skip500Redirect = true;

/**
 * send - Send a request (used to catch all errors)
 * @param {Object} attrs - The attributes added to the request
 * @returns a request promise
 */
const send = async attrs => {
  return await axios(attrs).catch(err => {
    console.error(err);

    if (!skip500Redirect) {
      window.location = '/500';
    }
  });
};

const getCacheHeader = async body => {
  const message = JSON.stringify(body);
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle?.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return {
    'X-Query-Key': hashHex,
  };
};

const getHeaders = async (data, options) => {
  return options.cache ? await getCacheHeader(data) : {};
};

async function request(data, options = {}, returnFullObject = false) {
  const res = await send({
    url: GRAPH_QL_ENDPOINT,
    method: 'post',
    data,
    headers: await getHeaders(data, options),
    withCredentials: true,
  });

  if (!res?.data?.data) {
    console.group('Failed Staging GraphQL Response');
    console.log({ request: data, response: res, errors: res.data.errors });
    console.groupEnd();
  }

  return returnFullObject ? res : res.data.data;
}

const client = {
  getCart: async () => {
    const response = await request({
      query: cartGet,
    });

    return response.shoppingCart;
  },

  addToCart: async ({
    id,
    quantity,
    sessionSource,
    sessionSourceId,
    experience,
    experienceId,
  }) => {
    return await request(
      {
        query: cartAdd,
        variables: {
          id,
          quantity,
          sessionSource,
          sessionSourceId,
          experience,
          experienceId,
        },
      },
      {},
      true,
    );
  },

  updateCart: async (id, quantity) => {
    const response = await request({
      query: cartUpdate,
      variables: {
        id,
        quantity,
      },
    });

    return response.updateCartQuantity;
  },

  removeFromCart: async (id, quantity) => {
    const response = await request({
      query: cartRemove,
      variables: {
        id,
        quantity,
      },
    });

    return response.removeFromCart;
  },

  removeFromWaitlist: async (id, quantity) => {
    const response = await request({
      query: waitlistRemove,
      variables: {
        id,
        quantity,
      },
    });

    return response.removeFromWaitlist;
  },

  addEmailToList: async email => {
    const response = await request({
      query: addEmailToList,
      variables: {
        email,
      },
    });

    return response.addEmailToList;
  },

  request: async (query, variables, options = {}) => {
    return await request(
      {
        query,
        variables,
      },
      options,
    );
  },
};

export default client;
