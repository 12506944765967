import Cart from '~/pages/Cart';
import ProductThumbs from '~/pages/ProductThumbs';
import ErrorPage from '~/pages/ErrorPage';
import Home from '~/pages/Home';
import Product from '~/templates/Product';
import { LivePage } from '~/pages/LivePage';
import DynamicPage from '~/pages/DynamicPage';
import CategoryPage from '~/pages/Category';
import MobileMenuPage from '~/pages/MobileMenuPage';
import ShopAll from '~/pages/ShopAll';

const routes = [
  {
    path: '/live/:id?',
    component: LivePage,
  },
  {
    path: '/cart',
    component: Cart,
  },
  {
    path: '/collections/id/:key',
    component: ProductThumbs,
  },
  {
    path: '/collections/:key',
    component: ProductThumbs,
  },
  {
    path: '/pages/:slug',
    component: DynamicPage,
  },
  {
    // For deep links. https://commentsold.atlassian.net/browse/WEB-73
    path: '/products/id/:id',
    component: Product,
  },
  {
    path: '/products/:slug',
    component: Product,
  },
  {
    path: '/search',
    component: ProductThumbs,
  },
  {
    path: '/categories/:id/:slug',
    component: CategoryPage,
  },
  {
    path: '/categories/shop_all',
    component: ShopAll,
  },
  {
    path: '/store',
    component: Home,
    isHomeRoute: true,
  },
  {
    path: '/mobilemenu',
    component: MobileMenuPage,
  },
  {
    path: '/',
    component: Home,
    exact: true,
    isHomeRoute: true,
  },
  {
    path: '/404',
    component: ErrorPage,
  },
  {
    path: '*',
    component: () => {
      // if we don't know the route, refresh the page
      // so laravel can handle the view
      if (
        document.referrer === window.location.href &&
        window.location.pathname !== '/account'
        // filter out account because if they initially come from /account
        // the router will always show /account, so when they try and go
        // back it will cause the 404
      ) {
        console.error('Unknown page');
        window.location.replace('/404');
        return null;
      }

      window.location.replace(`${window.location.href}`);
      return null;
    },
  },
];

export default routes;
