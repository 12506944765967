import React from 'react';

import Link from '~/components/ui/Link';
import cn from 'classnames';
import { placeholderImage } from '~/utils/placeholders';
import {
  ResponsiveImage,
  ResponsiveMedia,
} from '~/components/ui/molecules/ResponsiveImage';

type PromoGridItemProps = {
  item: {
    id: string;
    image?: string;
    title?: string;
    uri: string;
  };
  aspectRatio?: string;
  containerClassName?: string;
  imageClassName?: string;
  layout?: string;
  imageMedia: ResponsiveMedia[];
  imageCrop?: boolean;
};

const PromoGridItem: React.FC<PromoGridItemProps> = ({
  item,
  aspectRatio = 'natural',
  containerClassName = '',
  imageClassName = '',
  layout = '',
  imageMedia,
  imageCrop = true,
}) => {
  const { image = placeholderImage('portraitMedium'), title = '', uri } = item;

  return (
    <div
      className={containerClassName}
      data-testid="promo-grid-item"
      key={`promo-grid-item-${item.id}`}
    >
      <Link
        className={cn('flex flex-col', {
          'h-full': layout === 'fancy-grid',
        })}
        ignoreRouter={true}
        to={uri}
      >
        {image.url && (
          <ResponsiveImage
            aspectRatio={aspectRatio}
            url={image.url}
            altText={title ? `${title} collection image` : 'Collection image'}
            className={cn('flex-1 rounded overflow-hidden', imageClassName)}
            media={imageMedia}
            imageCrop={imageCrop}
          />
        )}
        {title && (
          <p className="mt-2 text-subtitle lg:text-subtitle-lg">{title}</p>
        )}
      </Link>
    </div>
  );
};

export default PromoGridItem;
