import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

const initialBreakpointState = {
  current: undefined,
  index: undefined,
  isDesktop: false,
  isTabletOrSmaller: false,
  isMobile: false,
  width: 0,
};
type UIContextType = {
  ui:
    | {
        header: {
          isVisible: boolean;
          contentUnderHeader: boolean;
        };
      }
    | undefined;
  breakpoint: BreakpointType;
  menuOpen: boolean;
  setUI?: Dispatch<
    SetStateAction<{
      header: { isVisible: boolean; contentUnderHeader: boolean };
    }>
  >;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
};
const UIContext = createContext<UIContextType>(undefined as any);

const breakpoints = [
  { name: 'xl', value: 1280, index: 4 },
  { name: 'lg', value: 1024, index: 3 },
  { name: 'md', value: 768, index: 2 },
  { name: 'sm', value: 576, index: 1 },
  { name: 'xs', value: 0, index: 0 },
];

type BreakpointType = {
  current: string | undefined;
  index: number | undefined;
  isDesktop: boolean;
  isTabletOrSmaller: boolean;
  isMobile: boolean;
  width: number;
};
const UIProvider: React.FC = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [breakpoint, setBreakpoint] = useState<BreakpointType>(
    initialBreakpointState,
  );
  const [ui, setUI] = useState({
    header: {
      isVisible: false,
      contentUnderHeader: false,
    },
  });

  const handleResize = useCallback(() => {
    const desktopBreakpointIndex = 3;
    const tabletBreakpointIndex = 2;
    const mobileBreakpointIndex = 1;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let current;
    let currentIndex = 0;
    let prev;

    for (const bp of breakpoints) {
      const queryMin = `(min-width: ${bp.value}px)`;
      const queryMax =
        (prev?.value && `(max-width: ${prev.value - 1}px)`) ||
        `(max-width: 100vw)`;

      if (window.matchMedia(`${queryMin} and ${queryMax}`).matches) {
        current = bp.name;
        currentIndex = bp.index;
      }

      prev = bp;
    }

    if (isSafari && window.innerWidth > 1024 && !current) {
      current = 'lg';
      currentIndex = 3;
    }

    setBreakpoint({
      current,
      index: currentIndex,
      isDesktop: currentIndex >= desktopBreakpointIndex,
      isTabletOrSmaller: currentIndex < tabletBreakpointIndex,
      isMobile: currentIndex < mobileBreakpointIndex,
      width: window.innerWidth,
    });
  }, []);

  useEffect(() => {
    if (!window) {
      return;
    }

    handleResize();
  }, [handleResize]);

  useEffect(() => {
    if (!window) {
      return;
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [setBreakpoint, handleResize]);

  return (
    <UIContext.Provider
      value={{
        ui,
        setUI,
        menuOpen,
        setMenuOpen,
        breakpoint,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export { UIContext as default, UIProvider };
