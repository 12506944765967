import React, { useEffect, useState } from 'react';
import { Modules } from '~/components/modules';
import SEO from '~/components/meta/SEO';
import { useSiteContext } from '~/context/SiteContext';
import { htmlToText } from '~/utils/helpers';
import { Container } from '~/components/layout/PageStructure';
import { LoadableLOTWConnector } from '~/components/live/LoadableLOTWConnector';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';
import { ScrollProvider } from '~/context/ScrollContext';
import { useStream } from '~/context/StreamContext';
import DynamicPage from './DynamicPage';

const HomePage = () => {
  const [pageData, setPageData] = useState<any>();
  const [isStreaming, setIsStreaming] = useState<boolean | undefined>();
  const {
    homePageDetail,
    homePage: modules,
    globals: { shopInfo },
    isMobileAppConsuming,
  } = useSiteContext();
  const { homepageTitle, homepageDescription } = shopInfo;
  const { pageId, url: pageSlug } = homePageDetail ?? { pageId: 0, url: '' };
  const { streamInfo } = useStream();
  useEffect(() => {
    let webAppSection = [];
    if (modules && modules.length > 0) {
      if (!isMobileAppConsuming) {
        webAppSection = modules.filter(
          (item: any) => item.isWeb || item.isWeb === undefined,
        );
      } else {
        webAppSection = modules.filter((item: any) => item.isApp);
      }
    } else {
      webAppSection = [];
    }
    setPageData(webAppSection);

    webstoreTracker.tagGroup(ContentGroups.HomePage);
  }, [isMobileAppConsuming, modules]);

  useEffect(() => {
    setIsStreaming(streamInfo?.is_streaming_now);
  }, [streamInfo]);

  return (
    <ScrollProvider>
      {pageId ? (
        <DynamicPage pageSlug={pageSlug} />
      ) : (
        <>
          <SEO
            title={homepageTitle}
            description={htmlToText(homepageDescription)}
          />

          {isStreaming && (
            <Container className={'my-6'}>
              {!isMobileAppConsuming && (
                <LoadableLOTWConnector autoConnectToReplay={false} />
              )}
            </Container>
          )}

          {pageData &&
            pageData.map((module: any) => (
              <Modules data={module} type={module.type} key={module.id} />
            ))}
        </>
      )}
    </ScrollProvider>
  );
};
export default HomePage;
