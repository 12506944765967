export enum GA4Event {
  AddToCart = 'add_to_cart',
  AddPaymentInfo = 'add_payment_info',
  AddShippingInfo = 'add_shipping_info',
  AddToWishlist = 'add_to_wishlist',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
  Refund = 'refund',
  RemoveFromCart = 'remove_from_cart',
  SelectItem = 'select_item', // for when an item is clicked from a list ie collections page to PDP
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
}
