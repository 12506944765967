import React, { useEffect, useState } from 'react';

type SortMethods = {
  [key: string]: string;
};

type CollectionSortingProps = {
  sortMethods: SortMethods;
  activeSortMethod: string;
  setActiveSortMethod: (value: string | null) => void;
};
const CollectionSorting: React.FC<CollectionSortingProps> = ({
  sortMethods,
  activeSortMethod,
  setActiveSortMethod,
}) => {
  const [btnLable, setBtnLable] = useState('');
  const [showSortModueleDiv, setShowSortModueleDiv] = useState(false);
  useEffect(() => {
    getButtonLable(activeSortMethod);
  });
  const handleSelect = (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    value: string,
    label: string,
  ) => {
    if (e !== undefined) {
      setActiveSortMethod(value);
      setBtnLable(label);
      setShowSortModueleDiv(false);
    }
  };
  function getButtonLable(activeValue: string) {
    let tempLable = '';
    if (activeValue === '') {
      tempLable = 'Sort By';
    } else {
      const keyVal = Object.keys(sortMethods).find(
        key => sortMethods[key] === activeValue,
      );
      tempLable = keyVal ? keyVal : '';
    }
    setBtnLable(tempLable);
  }
  const focusOutSearchField = () => {
    document.addEventListener('mousedown', e => {
      const concernedElement = document.querySelector('.sort-option');
      const concernedSearchElement = document.querySelector('.sort');
      if (
        e !== undefined &&
        e !== null &&
        e.target !== null &&
        concernedElement !== null &&
        !concernedElement.contains(e.target as Node) &&
        concernedSearchElement !== null &&
        !concernedSearchElement.contains(e.target as Node) &&
        showSortModueleDiv
      ) {
        setShowSortModueleDiv(false);
      }
    });
  };
  return (
    <div className="relative">
      <div className="float-right">
        <button
          type="button"
          onClick={() => setShowSortModueleDiv(!showSortModueleDiv)}
          onBlur={() => focusOutSearchField()}
          className="sort inline-flex justify-between gap-x-1 rounded-md bg-white lg:text-sm text-xs font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="select-button"
        >
          <span className="sort-label">{btnLable}</span>
          <svg
            className="-mr-1 h-5 w-5 text-gray-400 -mt-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {showSortModueleDiv && (
        <div
          className="absolute sort-option-container right-0 z-10 mt-6 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1 sort-option">
            {Object.keys(sortMethods).map(sortOption => (
              <div
                key={`collection-sort-${sortOption}`}
                onKeyDown={e =>
                  handleSelect(e, sortMethods[sortOption], sortOption)
                }
                onClick={e =>
                  handleSelect(e, sortMethods[sortOption], sortOption)
                }
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                {sortOption}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionSorting;
