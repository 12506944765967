import axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import config from '../config.js';
import {
  CategoryApiResponse,
  CollectionApiResponse,
  DropshipFilterOptionsParams,
  FilterOptionsApiResponse,
  GetDropshipProductsApiResponse,
  GetProductsApiResponse,
} from '~/types/ApiResponseTypes';

const API_BASE_URL = config.API_BASE_URL;
const net = axios.create({ baseURL: API_BASE_URL });

function request(config: AxiosRequestConfig) {
  return net(config).catch(err => {
    console.error(err);
    Sentry.addBreadcrumb({
      category: 'http',
      message: 'Error http response received for ' + err,
      level: 'warning' as SeverityLevel,
    });
    return err;
  });
}

type FilterOptionsParams = {
  collectionKey?: string;
  query?: string;
  inStockOnly?: boolean;
  externalTypes?: [];
  sizes?: [];
  excludeWaitlist?: boolean;
  collectionSlug?: string;
  categoryId?: string;
};

const createRestClient = () => {
  return {
    getStreamInfo() {
      return request({
        method: 'GET',
        url: `/live/stream-info`,
      });
    },

    getLiveReplays({
      startingAfter,
      influencerSlug,
    }: {
      startingAfter?: number;
      influencerSlug?: string;
    } = {}) {
      let url = influencerSlug
        ? `/api/influencers/${influencerSlug}/lives`
        : '/live/replays';
      if (startingAfter) {
        url = `${url}?starting_after=${startingAfter}`;
      }
      return request({
        url,
        method: 'GET',
      });
    },

    getLiveEvents(id: number, shownAfter?: number) {
      let url = `/live/${id}/events`;
      if (shownAfter) {
        url = `${url}?shown_after=${shownAfter}`;
      }
      return request({
        url,
        method: 'GET',
      });
    },

    getLive(id: number) {
      return request({
        method: 'GET',
        url: `/live/live-sale/${id}`,
      });
    },

    trackProductView(id: string) {
      return request({
        method: 'GET',
        url: `/tracking/viewed-product/${id}`,
      });
    },

    trackLiveSaleView(id: string) {
      return request({
        method: 'GET',
        url: `/tracking/viewed-live/${id}`,
      });
    },

    async me() {
      const response = await request({
        method: 'GET',
        url: `/api/me`,
      });

      return response.data.data.me;
    },

    async getShopInfo() {
      return await request({
        method: 'GET',
        url: `/api/shop-info`,
      });
    },

    async getFilterOptions({
      collectionKey,
      query,
      inStockOnly,
      externalTypes,
      sizes,
      excludeWaitlist,
      categoryId,
    }: FilterOptionsParams): Promise<FilterOptionsApiResponse> {
      const response = await request({
        method: 'GET',
        url: `/api/filter-options`,
        params: {
          collection_key: collectionKey,
          query,
          in_stock_only: inStockOnly,
          externalTypes,
          sizes,
          exclude_waitlist: excludeWaitlist,
          category_id: categoryId,
        },
      });

      return response.data;
    },

    async getProducts({
      searchQuery,
      collectionKey,
      inStockOnly,
      pageNumber,
      externalTypes,
      sizes,
      orderBy,
      itemsPerPage,
      collectionSlug,
      categoryId,
    }: {
      searchQuery?: string;
      collectionKey?: string;
      inStockOnly?: number;
      pageNumber?: number;
      externalTypes?: [];
      sizes?: [];
      orderBy?: string;
      itemsPerPage?: number;
      collectionSlug?: string;
      categoryId?: string;
    }): Promise<GetProductsApiResponse> {
      const response = await request({
        method: 'GET',
        url: `/api/products`,
        params: {
          search_query: searchQuery,
          collection_key: collectionKey,
          in_stock_only: inStockOnly,
          externalTypes,
          sizes,
          page_number: pageNumber,
          order_by: orderBy,
          items_per_page: itemsPerPage,
          category_id: categoryId,
        },
      });
      return response.data.data;
    },

    async getDropshipProducts({
      page,
      tag,
      categories,
      brands,
      supplier,
      sku,
      name,
      orName,
      includedProductSku,
      maxPrice,
      minPrice,
      lowestPotentialProfit,
      highestPotentialProfit,
      sortBy,
      perPage,
      shopId,
      userId,
      accessToken,
      url,
    }: DropshipFilterOptionsParams): Promise<GetDropshipProductsApiResponse> {
      const response = await request({
        method: 'POST',
        url: `${url}/dropship-webstore-products`, //`/api/dropship-products`,
        data: {
          page,
          tag,
          categories,
          brands,
          supplier,
          sku,
          name,
          orName,
          includedProductSku,
          maxPrice,
          minPrice,
          lowestPotentialProfit,
          highestPotentialProfit,
          sortBy,
          perPage,
          shopId,
          userId,
          accessToken,
        },
      });
      return response.data;
    },

    async getDropshipProductsDetail({ id }: { id?: number }) {
      const response = await request({
        method: 'GET',
        url: `/api/dropshipping-catalog/products/${id}`,
      });
      return response.data;
    },

    async getProductByIdOrSlug({
      id,
      slug,
      includeUnpublished = false,
    }: {
      id?: string;
      slug?: string;
      includeUnpublished?: boolean;
    }) {
      return await request({
        method: 'GET',
        url: id ? `/api/products/${id}` : `/api/products/slug/${slug}`,
        params: {
          include_unpublished: includeUnpublished,
        },
      });
    },
    async getPageInfo(pageId: string) {
      let apiUrl = `/api/pages-v2/${pageId}`;
      if (window.config?.version === 'staged') {
        apiUrl += `?version=staged`;
      }
      return await request({
        method: 'GET',
        url: apiUrl,
      });
    },
    async getCategory(categoryId: string): Promise<CategoryApiResponse> {
      const response = await request({
        method: 'GET',
        url: `/api/category/${categoryId}`,
      });

      return response.data;
    },
    async getCollection(collectionKey: string): Promise<CollectionApiResponse> {
      const response = await request({
        method: 'GET',
        url: `/api/collection/${collectionKey}`,
      });

      return response.data;
    },
  };
};

export default createRestClient;
