import React, { useState } from 'react';

import AnimateHeight from 'react-animate-height';
import Button from '~/components/ui/Button';
import Input from '~/components/ui/Input';
import Modal from '~/components/ui/Modal';
import { clone as cloneObject } from 'lodash';
import cn from 'classnames';
import { withFilterContext } from '~/context/FilterContext';

export interface ActiveFilters {
  [key: string]: boolean | string[] | undefined;
  inStockOnly?: boolean;
  externalTypes?: string[];
  sizes?: string[];
}

/**
 * FilterCategory is, for example, for filtering "Colors" to "Red" and "Blue" and
 * lies within a FilterSection
 */
const FilterCategory: React.FC<{
  categoryKey: string;
  filter: Filter;
  activeFilters: ActiveFilters;
  applyFilterChange: (params: ApplyFilterChangeParams) => void;
  className: string;
}> = ({
  categoryKey,
  filter,
  activeFilters = {},
  applyFilterChange,
  className = '',
}) => {
  const [expanded, setExpanded] = useState(true);
  const [showAllFilters, setShowAllFilters] = useState(false);

  const defaultVisibleFilters = 8;
  const { label, values, type } = filter;
  const removeEmptyOptions = (options: string[] | never[]) => {
    return options.filter(option => option);
  };

  return (
    <div className={className}>
      <Button
        className="w-full text-left text-small lg:text-small-lg mb-2 capitalize"
        attrs={{ onClick: () => setExpanded(!expanded) }}
        button={{
          text: label,
          style: 'text',
          rounded: false,
          icon: expanded ? 'minus' : 'plus',
        }}
      />

      <AnimateHeight
        height={expanded ? 'auto' : 0}
        className="border-b border-grey"
      >
        <div>
          {(showAllFilters
            ? removeEmptyOptions(values)
            : removeEmptyOptions(values.slice(0, defaultVisibleFilters))
          ).map((option, index) => {
            let checked = false;

            // Check if the type is a boolean before assuming it is an array
            if (
              activeFilters[categoryKey] &&
              (activeFilters[categoryKey] === true ||
                (activeFilters[categoryKey] as string[]).includes(option))
            ) {
              checked = true;
            }

            return (
              <Input
                key={`filter-${categoryKey}-${option}`}
                onValue={newCheckedValue =>
                  applyFilterChange({
                    filter: categoryKey,
                    option,
                    on: newCheckedValue,
                    type: type ?? '',
                  })
                }
                className={cn({
                  'mb-4': index < values.length - 1,
                })}
                attrs={{
                  type: 'checkbox',
                  id: `filter-${categoryKey}-${option}`,
                  checked,
                  labelClassName: 'text-tiny max-lines-1',
                }}
                input={{ label: option }}
              />
            );
          })}

          {!showAllFilters && values.length > defaultVisibleFilters && (
            <Button
              attrs={{
                onClick: () => setShowAllFilters(true),
              }}
              className="block text-grey text-tiny text-left leading-filters"
              button={{
                text: 'See more',
                style: 'text',
              }}
            />
          )}
        </div>
      </AnimateHeight>
    </div>
  );
};

const FilterCategoryChips = ({
  categoryKey,
  activeFilters,
  applyFilterChange,
}: {
  categoryKey: string;
  activeFilters: ActiveFilters;
  applyFilterChange: (params: ApplyFilterChangeParams) => void;
}) => {
  return (
    <>
      {Array.isArray(activeFilters[categoryKey]) ? (
        activeFilters[categoryKey] &&
        (activeFilters[categoryKey] as string[]).map((option, index) => {
          return (
            <div
              className="text-xs p-2.5 flex rounded-2xl bg-gray-100 w-auto"
              key={`filter-${index}`}
            >
              <span className="mr-2 leading-5 font-semibold">{option}</span>
              <button
                onClick={() =>
                  applyFilterChange({
                    filter: categoryKey,
                    option,
                    on: '',
                    type: '',
                  })
                }
                className="border-solid border-gray-300 border-2 rounded-full px-1 text-gray-300 font-semibold hover:text-black hover:border-black"
              >
                X
              </button>
            </div>
          );
        })
      ) : (
        <div
          className="text-xs p-2.5 flex rounded-2xl bg-gray-100 w-auto"
          key={`filter-${categoryKey}`}
        >
          <span className="mr-2 leading-5 font-semibold">In Stock</span>
          <button
            onClick={() =>
              applyFilterChange({
                filter: categoryKey,
                option: '',
                on: '',
                type: 'Boolean',
              })
            }
            className="border-solid border-gray-300 border-2 rounded-full px-1 text-gray-300 font-semibold hover:text-black hover:border-black"
          >
            X
          </button>
        </div>
      )}
    </>
  );
};

interface Filters {
  [key: string]: Filter | undefined;
  inStockOnly?: Filter;
  externalTypes?: Filter;
  sizes?: Filter;
}

interface Filter {
  label: string;
  type?: string;
  values: string[];
}
/**
 * FilterSection is the entire filtering area. This is a component because it is
 * replicated within the sidebar and the modal.
 */
const FilterSection = ({
  resetFilters,
  filters,
  activeFilters,
  applyFilterChange,
}: {
  resetFilters: () => void;
  filters: Filters;
  activeFilters: ActiveFilters;
  applyFilterChange: (params: ApplyFilterChangeParams) => void;
}) => (
  <>
    <div className="flex justify-between mt-1/2 mb-2 pb-2 border-b border-grey">
      <span>Filters</span>

      <Button
        className="text-grey text-tiny lg:text-tiny-lg self-end"
        attrs={{
          onClick: resetFilters,
        }}
        button={{ text: 'Clear', style: 'text' }}
      />
    </div>

    {Object.keys(filters).map((categoryKey, index) => (
      <FilterCategory
        categoryKey={categoryKey}
        key={`filter-${categoryKey}-${index}`}
        filter={filters[categoryKey] as Filter}
        activeFilters={activeFilters}
        applyFilterChange={applyFilterChange}
        className="mt-4"
      />
    ))}
  </>
);

const FilterChipSection = ({
  resetFilters,
  activeFilters,
  applyFilterChange,
}: {
  resetFilters: () => void;
  activeFilters: ActiveFilters;
  applyFilterChange: (params: ApplyFilterChangeParams) => void;
}) => (
  <>
    {Object.keys(activeFilters).map((categoryKey, index) => (
      <FilterCategoryChips
        categoryKey={categoryKey}
        key={`filter-${categoryKey}-${index}`}
        activeFilters={activeFilters}
        applyFilterChange={applyFilterChange}
      />
    ))}
    {Object.keys(activeFilters).length !== 0 && (
      <div className="text-xs flex rounded-2xl bg-gray-100 w-auto hover:bg-gray-300">
        <button onClick={resetFilters}>
          <span className="my-2 p-2.5 leading-5 font-semibold">Clear</span>
        </button>
      </div>
    )}
  </>
);

interface ApplyFilterChangeParams {
  filter: string;
  option: string;
  on: string | boolean;
  type: string;
}
/**
 * CollectionFilters is the main component.
 *
 * The "tempActiveFilters" and "tempActiveFilterCount" are used within the
 * modal before the "Apply" button is selected. These values get reset to
 * their default values whenever the modal is closed and set to the values
 * of "activeFilters" and "activeFilterCount" when it is opened to
 * maintain consistency.
 */
const ProductFilters = ({
  filters = {},
  onFilterUpdate,
  sidebarClassName,
  toolbarClassName,
  activeFilters,
  setActiveFilters,
  activeFilterCount,
}: {
  filters: Filters;
  onFilterUpdate: (filter: {
    [key: string]: boolean | string[] | string;
  }) => void;
  sidebarClassName: string;
  toolbarClassName: string;
  activeFilters: ActiveFilters;
  setActiveFilters: (filters: {
    [key: string]: boolean | string[] | string;
  }) => string;
  activeFilterCount: number;
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [tempActiveFilters, setTempActiveFilters] = useState({});
  const [tempActiveFilterCount, setTempActiveFilterCount] = useState(0);
  const [tempResetFilters, setTempResetFilters] = useState(false);

  const applyFilterChange = ({
    filter,
    option,
    on,
    type,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  ApplyFilterChangeParams) => {
    /**
     * showMobileFilters is used extensively in this function to ensure we're
     * updating either the temp values or the actual values prior to applying
     * the changes via the "Apply" button in the modal
     */
    const activeFilterObject = cloneObject(
      showMobileFilters ? tempActiveFilters : activeFilters,
    ) as { [key: string]: string | boolean | string[] };

    /**
     * Build out the new filter object based on the filter (Color), the
     * option (Red, Blue) and account for it being a potential boolean
     * from additioanlOptions
     */
    if (on) {
      if (!activeFilterObject[filter]) {
        activeFilterObject[filter] = [];
      }

      if (!(activeFilterObject[filter] as string[]).includes(option)) {
        if (type === 'Boolean') {
          activeFilterObject[filter] = on;
        } else {
          (activeFilterObject[filter] as string[]).push(option);
        }
      }
    } else {
      if (activeFilterObject[filter]) {
        if (activeFilterObject[filter] === true) {
          delete activeFilterObject[filter];
        } else if ((activeFilterObject[filter] as string).includes(option)) {
          if ((activeFilterObject[filter] as string).length === 1) {
            delete activeFilterObject[filter];
          } else {
            const optionIndexInFilters = (
              activeFilterObject[filter] as string[]
            ).indexOf(option);

            (activeFilterObject[filter] as string[]).splice(
              optionIndexInFilters,
              1,
            );
          }
        }
      }
    }

    if (showMobileFilters) {
      setTempResetFilters(false);
      setTempActiveFilterCount(tempActiveFilterCount + (on ? 1 : -1));
      setTempActiveFilters(activeFilterObject);
    } else {
      setActiveFilters(activeFilterObject);
      onFilterUpdate(activeFilterObject);
    }
  };

  const beforeApplyResetFilters = () => {
    if (showMobileFilters) {
      setTempActiveFilters({});
      setTempActiveFilterCount(0);
      setTempResetFilters(true);
    } else {
      applyResetFilters();
    }
  };

  const applyResetFilters = () => {
    setActiveFilters({});
    setTempActiveFilters({});
    setTempActiveFilterCount(0);
    onFilterUpdate({});
  };

  const openModal = () => {
    setShowMobileFilters(true);
    setTempActiveFilters(activeFilters);
    setTempActiveFilterCount(activeFilterCount);
    setTempResetFilters(false);
  };

  const closeModal = (applyFilters: boolean) => {
    if (applyFilters) {
      if (tempResetFilters) {
        applyResetFilters();
      } else {
        setActiveFilters(tempActiveFilters);
        onFilterUpdate(tempActiveFilters);
      }
    }

    setTempResetFilters(false);
    setTempActiveFilters({});
    setTempActiveFilterCount(0);
    setShowMobileFilters(false);
  };

  return (
    <>
      <div className={sidebarClassName}>
        <FilterSection
          resetFilters={beforeApplyResetFilters}
          filters={filters}
          activeFilters={activeFilters}
          applyFilterChange={applyFilterChange}
        />
      </div>

      <div className={toolbarClassName}>
        <Button
          className="text-left px-4 py-2 text-button w-full border border-grey rounded"
          attrs={{
            onClick: openModal,
          }}
          button={{
            text: `Filters (${activeFilterCount})`,
            style: 'secondary',
          }}
        />
      </div>

      <div className="flex flex-wrap gap-2 mb-4 md:hidden row-start-3 col-span-2">
        <FilterChipSection
          resetFilters={beforeApplyResetFilters}
          activeFilters={activeFilters}
          applyFilterChange={applyFilterChange}
        />
      </div>

      <Modal
        open={showMobileFilters}
        onRequestClose={() => closeModal(false)}
        disableCloseButton
        containerClassName="h-full"
        contentClassName="flex flex-col"
      >
        <FilterSection
          resetFilters={beforeApplyResetFilters}
          filters={filters}
          activeFilters={tempActiveFilters}
          applyFilterChange={applyFilterChange}
        />

        <div className="flex-grow flex items-end">
          <Button
            attrs={{ onClick: () => closeModal(true) }}
            className="w-full mt-4 mb-6"
            button={{ text: 'Apply' }}
          />
        </div>
      </Modal>
    </>
  );
};

export default React.memo(withFilterContext(ProductFilters));
