import ReactPinterestTag from 'react-pinterest-tag';

import { TrackerHelper } from '~/types/tracker-helper.interface';
import { TrackingEvent } from '~/types/tracking-event.enum';

declare global {
  interface Window {
    pintrk: any;
  }
}

window.pintrk = window.pintrk || undefined;

const validEventNames = [
  'pagevisit',
  'viewcategory',
  'search',
  'addtocart',
  'checkout',
  'watchvideo',
  'signup',
  'lead',
  'custom',
  'externalmeasurement',
];

let hasBeenInit = false;

const initializePinterest = (apiKey: string) => {
  if (!apiKey || pinterestHasBeenInitialized()) {
    return;
  }
  ReactPinterestTag.init(apiKey);
  hasBeenInit = true;
};

const pinterestHasBeenInitialized = (): boolean =>
  typeof window.pintrk === 'function' && hasBeenInit;

const pinterestTrackPageview = () => {
  if (pinterestHasBeenInitialized()) {
    ReactPinterestTag.pageView();
  }
};

const pinterestTrackEvent = (name: TrackingEvent, data: any) => {
  if (pinterestHasBeenInitialized()) {
    const eventName = convertEventNameToPinterestEvent(name);
    if (eventName) {
      ReactPinterestTag.track(eventName, convertPayload(eventName, data));
    }
  }
};

const convertEventNameToPinterestEvent = (eventName: TrackingEvent): string => {
  if (validEventNames.includes(eventName)) {
    return eventName;
  }

  switch (eventName) {
    case TrackingEvent.AddToCart:
      return 'addtocart';
    case TrackingEvent.ViewContent:
      return 'pagevisit';
    default:
      return '';
  }
};

const convertPayload = (eventName: string, payload: any): any => ({
  value: payload?.value,
  currency: payload?.currency,
  product_id:
    payload?.content_ids?.length > 0 ? payload.content_ids[0] : undefined,
});

const pinterestTracker: TrackerHelper = {
  init: initializePinterest,
  hasBeenInitialized: pinterestHasBeenInitialized,
  trackEvent: pinterestTrackEvent,
  trackPageView: pinterestTrackPageview,
};
export default pinterestTracker;
