import 'swiper/swiper.min.css';
import 'swiper/components/a11y/a11y.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination } from 'swiper';

import { Container } from '~/components/layout/PageStructure';
import PropTypes from 'prop-types';
import UIContext from '~/context/UIContext';

SwiperCore.use([A11y, Autoplay, Navigation, Pagination]);

const layoutOptions = ['contained', 'fullwidth', 'uncontained'];

/* eslint-disable no-magic-numbers */
const Slider = ({ children, className = '', options = {} }) => {
  const {
    layout = 'contained',
    autoplay = false,
    autoplayDelay = 6000,
    itemsPerView = false,
    loop = false,
    ...swiperOptions
  } = options;
  const { breakpoint } = useContext(UIContext);
  const [offset, setOffset] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [updatedLayout, setUpdatedLayout] = useState(layout);

  // If there are arrays of items within the children,
  // we need to flatten them.
  const reducedChildren = children.reduce((a, c) => {
    if (Array.isArray(c)) {
      return a.concat(...c);
    }
    return a.concat(c);
  }, []);

  // Disable navigation for slideshow with a single banner item
  const navigation = reducedChildren.length > 1;

  useEffect(() => {
    const { current, width } = breakpoint;

    // If the window width is greater than 1400 we have to account
    // for variable margin.
    if (width >= 1400) {
      setOffset((width - 1400) / 2 + 60);
      setSlidesPerView(4);
      return;
    }

    // The magic numbers below represent x-padding values at the
    // different breakpoints.
    switch (current) {
      case 'xs':
        setOffset(16);
        setSlidesPerView(2);
        break;
      case 'sm':
        setOffset(16);
        setSlidesPerView(3);
        break;
      case 'md':
        setOffset(24);
        setSlidesPerView(3);
        break;
      case 'lg':
        setOffset(48);
        setSlidesPerView(4);
        break;
      default:
        setOffset(60);
        setSlidesPerView(4);
        break;
    }
  }, [breakpoint]);

  let containmentOptions = {
    slidesPerView,
  };

  if (updatedLayout === 'uncontained' && breakpoint.isTabletOrSmaller) {
    setUpdatedLayout('contained');
  }

  if (updatedLayout === 'uncontained') {
    containmentOptions = {
      slidesPerView: 'auto',
      slidesOffsetBefore: offset,
      slidesOffsetAfter: offset,
    };
  }

  if (itemsPerView) {
    containmentOptions = {
      slidesPerView: itemsPerView,
    };
  }

  const sliderOptions = Object.assign(
    {
      grabCursor: true,
      spaceBetween: 16,
      freeMode: true,
      breakpoints: {
        768: {
          spaceBetween: 24,
        },
      },
    },
    containmentOptions,
    swiperOptions,
  );

  const Content = () => (
    <Swiper
      {...sliderOptions}
      className={className}
      navigation={navigation}
      autoplay={autoplay ? { delay: autoplayDelay } : false}
      loop={reducedChildren.length > 1 ? loop : false}
    >
      {reducedChildren.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <>
      {updatedLayout === 'contained' && (
        <Container>
          <Content />
        </Container>
      )}

      {updatedLayout === 'uncontained' && (
        <div className="swiper-uncontained">
          <Content />
        </div>
      )}

      {updatedLayout === 'fullwidth' && <Content />}
    </>
  );
};

export default Slider;

Slider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  options: PropTypes.shape({
    layout: (props, _propName, componentName) => {
      if (!layoutOptions.includes(props.layout)) {
        return new Error(
          `Prop 'layout' requires a value included within [${layoutOptions.join(
            ', ',
          )}] in ${componentName}—given ${props.layout}`,
        );
      }
    },
  }),
};
