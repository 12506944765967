import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { WebstoreSkeletonTheme } from '~/components/ui/organisms/WebstoreSkeletonTheme';

export const LiveReplayLoader: React.FC = () => {
  return (
    <div className={'relative overflow-hidden p-0 mb-5'}>
      <div className={'relative w-full h-full flex'}>
        <WebstoreSkeletonTheme>
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
          <Skeleton
            style={{ height: '256px', width: '208px', marginRight: '30px' }}
          />
        </WebstoreSkeletonTheme>
      </div>
    </div>
  );
};
