import React from 'react';
import Link from '~/components/ui/Link';
import { GiftItemProps } from '~/types/GiftItemTypes';

const GiftItem: React.FC<GiftItemProps> = ({
  productId,
  productName,
  productDescription,
  productImage,
  priceCents,
}) => {
  return (
    <div key={productId} className=" border border-gray-300 rounded m-4">
      <div
        className="flex  text-base p-1 pl-2"
        style={{ backgroundColor: '#EDF6FD' }}
      >
        <p className="gift-item-heading">You’ve Earned a Free Gift! 🎁</p>
      </div>
      <div className="md:flex p-2 gap-2">
        <div className="md:flex-shrink-0">
          <img
            className="h-44 w-full object-cover md:w-28 rounded-md"
            src={productImage}
            alt={`gift`}
          />
        </div>
        <div className=" p-1 flex-col gap-4">
          <div>
            <p className="pb-1 gift-item-product-name-text">{productName}</p>
          </div>
          <div className="text-xs pb-2"></div>
          <div className="flex align-center gap-4">
            <div className="font-bold free-gift-text pb-2">
              <p>Free Gift</p>
            </div>
            <div className="free-gift-price-tag line-through">
              <p>${(Number(priceCents) / 100)?.toFixed(2)}</p>
            </div>
          </div>
          <div className="flex align-center gap-4">
            <Link to={`/products/id/${productId}`}>
              <div className="claim-gift-button button--primary">
                <p>Claim Free Gift</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftItem;
