import React, { useContext } from 'react';
import { findIndex } from 'lodash';

import UIContext from '~/context/UIContext';
import Slider from '~/components/ui/Slider';
import OptionValue from '~/components/product/ProductFormOptionValue';
import enums from '~/utils/enums';

const OptionGroup = ({
  optionName,
  optionValues,
  product,
  selectedOptions,
  handleOptionChange,
  handleOptionUnselect,
  hideAvailableStockCount,
}) => {
  const {
    breakpoint: { isTabletOrSmaller },
  } = useContext(UIContext);

  const initialSlide =
    findIndex(optionValues, {
      name: selectedOptions[optionName],
    }) ?? 0;

  return (
    <div className="mt-4 md:-mb-3">
      <p className="w-full mb-1 text-tiny text-grey">
        {product?.type === enums.productTypes.giftCard
          ? 'Denomination'
          : optionName}
      </p>

      <div className="md:flex md:flex-start md:flex-wrap swiper-variants">
        {isTabletOrSmaller ? (
          <Slider
            options={{
              itemsPerView: 'auto',
              initialSlide,
              layout: 'uncontained',
              navigation: false,
              centeredSlides: false,
              centeredSlidesBounds: true,
            }}
          >
            {optionValues.map(optionValue => (
              <OptionValue
                key={`option-${product.id}-${optionValue.name}`}
                optionName={optionName}
                optionValue={optionValue}
                product={product}
                selectedOptions={selectedOptions}
                handleOptionChange={handleOptionChange}
                handleOptionUnselect={handleOptionUnselect}
                hideAvailableStockCount={hideAvailableStockCount}
              />
            ))}
          </Slider>
        ) : (
          <>
            {/* eslint-disable-next-line sonarjs/no-identical-functions */}
            {optionValues.map(optionValue => (
              <OptionValue
                key={`option-${product.id}-${optionValue.name}`}
                optionName={optionName}
                optionValue={optionValue}
                product={product}
                selectedOptions={selectedOptions}
                handleOptionChange={handleOptionChange}
                handleOptionUnselect={handleOptionUnselect}
                hideAvailableStockCount={hideAvailableStockCount}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default OptionGroup;
