import { useHistory } from 'react-router-dom';

const useParams = () => {
  const history = useHistory();

  /**
   * pushParams - Pushes the URLSearchParams object to the URL
   * @param {Object} urlParams - A URLSearchParams object
   */
  const pushParams = urlParams => {
    const newParamString = urlParams.toString();
    const newSearch =
      newParamString.length > 0 ? `?${newParamString}` : newParamString;
    if (history.location.search !== newSearch) {
      const newLocation = { ...history.location };
      newLocation.search =
        newParamString.length > 0 ? `?${newParamString}` : newParamString;
      history.push(newLocation);
    }
  };

  /**
   * getParams - Get all URL search parameters
   */
  const getParams = () => {
    return new URLSearchParams(history.location.search);
  };

  /**
   * getParam - Returns value of the key found in URL search parameters
   * @param {String} key - The key of the search parameter you're looking for
   */
  const getParam = key => {
    const currentUrlParams = getParams();

    return currentUrlParams.get(key);
  };

  /**
   * deleteParam - Delete a value from the URL search parameters
   * @param {String} key - The key of the search parameter you want to delete
   */
  const deleteParam = key => {
    const currentUrlParams = new URLSearchParams(history.location.search);

    currentUrlParams.delete(key);
    pushParams(currentUrlParams);
  };

  /**
   * setParam - Sets a key value pair in the URL search parameters
   * @param {String} key - The key you want to set
   * @param {String} value - The value you want to set
   */
  const setParam = (key, value) => {
    const currentUrlParams = getParams();

    currentUrlParams.set(key, value);
    pushParams(currentUrlParams);
  };

  return {
    getParams,
    getParam,
    deleteParam,
    setParam,
  };
};

export default useParams;
