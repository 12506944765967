import React, { createContext, FC, useContext } from 'react';

import useUserAgent from '~/hooks/useUserAgent';
import { ShopInfo } from '~/types/ShopInfo';
import useSiteSettings from '~/hooks/useSiteSettings';
import { FeatureFlags } from '~/types/FeatureFlags';
interface SiteContextType extends Record<string, any> {
  theme: {
    borderRadius: number;
    color1: string;
    color2: string;
    color3: string;
    faviconSrc: string | null;
    fontBody: string;
    fontHeading: string;
    iconSet: string;
    productCardAspectRatio: string;
    productImageCrop: boolean;
    productPageRelatedProducts: boolean;
  };
  isMobileAppConsuming: boolean;
  mobileAppVersion: string;
  mobileAppPlatform: MobileAppOS;
  header: {
    announcementBanner: {
      text: string | null;
      uri: string | null;
    };
    layout: string;
    logo: string;
    menu: {
      id: string;
      links: GenericLinkWithChildren[];
    };
  };
  footer: {
    menu?: {
      id: string;
      links?: GenericLinkWithChildren[];
    };
    policyMenu?: {
      id?: string;
      links?: GenericLink[];
    };
    contactText: string;
    socialUrls: {
      [key: string]: string;
    };
  };
  mailingListPopup: any;
  featureFlags?: FeatureFlags;
  shopInfo: ShopInfo;
  globals: {
    featureFlags: FeatureFlags | undefined;
    shopInfo: ShopInfo;
  };
}

export interface GenericLink {
  id: string;
  title: string;
  uri: string;
  is_shop_all?: boolean;
  isWeb?: boolean;
}

export interface GenericLinkWithChildren extends GenericLink {
  links: GenericLinkWithChildren[];
  image?: {
    url: string;
  };
  shouldBreak?: boolean;
}

const SiteContext = createContext<SiteContextType>({} as SiteContextType);

const SiteProvider: FC<{ config: any }> = ({ config, children }) => {
  const settings = useSiteSettings();

  const userAgent = useUserAgent();
  const consumingAppOS = getViewingAppType(
    userAgent,
    settings.shopInfo.mobileAppUserAgents.ios,
    settings.shopInfo.mobileAppUserAgents.android,
  );

  return (
    <SiteContext.Provider
      value={{
        ...settings,
        theme: config?.theme,
        mailingListPopup: config?.mailingListPopup,
        header: config?.header,
        footer: config?.footer,
        homePage: config?.homePage,
        homePageDetail: config?.homePageDetail,
        htmlScripts: config?.htmlScripts,
        globals: settings,
        isMobileAppConsuming: consumingAppOS !== MobileAppOS.None,
        mobileAppVersion: getAppVersion(userAgent),
        mobileAppPlatform: consumingAppOS,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

function getViewingAppType(
  userAgent: string,
  iosUserAgent: string,
  androidUserAgent: string,
): MobileAppOS {
  if (userAgent.startsWith(androidUserAgent)) {
    return MobileAppOS.Android;
  }

  if (userAgent.startsWith(iosUserAgent)) {
    return MobileAppOS.IOS;
  }

  return MobileAppOS.None;
}

function getAppVersion(userAgent: string): string {
  return userAgent.split('/')[1] ?? '';
}

export enum MobileAppOS {
  Android,
  IOS,
  None,
}

function useSiteContext() {
  return useContext(SiteContext);
}
export { SiteProvider, useSiteContext };
export type { ShopInfo, SiteContextType };
