import React, { FC, useEffect, useRef } from 'react';

type RawHtmlDataProps = {
  anchorTag: string;
  htmlCode: string;
};

const RawHtml: FC<{
  config: any;
  parentWidget: string;
  data: RawHtmlDataProps;
}> = ({ data }) => {
  const htmlString = data.htmlCode ? atob(data.htmlCode) : '';
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      const scripts = Array.from(
        new DOMParser()
          .parseFromString(htmlString, 'text/html')
          .querySelectorAll('script'),
      );
      scripts.forEach(script => {
        const newScript = document.createElement('script');
        newScript.innerHTML = script.innerHTML;
        containerRef.current.appendChild(newScript);
      });
    }
  }, [htmlString]);
  return (
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

export default RawHtml;
