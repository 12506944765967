import { Container } from '~/components/layout/PageStructure';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import CollectionSorting from '~/components/modules/DropshipProductGrid/CollectionSorting';
import Paginator from '~/components/ui/Paginator';
import ProductGrid from '~/components/modules/DropshipProductGrid/ProductGrid';
import UIContext from '~/context/UIContext';
import { ProductLoader } from '~/components/modules/DropshipProductGrid/ProductLoader';
import useRestClient from '~/hooks/useRestClient';
import { Redirect } from 'react-router-dom';
import useWindowScrollPosition from '~/hooks/useWindowScrollPosition';
import { ScrollContext } from '~/context/ScrollContext';
import { DropshipMeta, DropshipProduct } from '~/types/ApiResponseTypes';

type SortMethodsType = {
  'Price: Low to High': string;
  'Price: High to Low': string;
};
const sortMethods: SortMethodsType = {
  'Price: Low to High': 'highest_msrp:asc',
  'Price: High to Low': 'highest_msrp:desc',
};

const DropshipProductGridContainer = ({
  gridData,
}: {
  gridData?: any; // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { breakpoint } = useContext(UIContext);
  const restClient = useRestClient();
  const searchQuery = '';

  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [products, setProducts] = useState<DropshipProduct[]>([]);
  const [paginationData, setPaginationData] = useState<DropshipMeta>({
    current_page: 0,
    from: 0,
    last_page: 0,
    links: [],
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [cardWidth, setCardWidth] = useState('33.333%');
  const [gridId, setGridId] = useState(null);
  const [cardCount, setCardCount] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortMethod, setSortMethod] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [tag, setTag] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [names, setNames] = useState([]);
  const [keyWords, setKeyWords] = useState([]);
  const [includedProductSku, setIncludedProductSku] = useState([]);
  const [sku, setSku] = useState('');
  const [msrp, setMsrp] = useState<{ minMSRP: number; maxMSRP: number }>({
    minMSRP: 0,
    maxMSRP: 0,
  });
  const [profit, setProfit] = useState<{
    minProfit: number;
    maxProfit: number;
  }>({ minProfit: 0, maxProfit: 0 });
  const [pageData, setPageData] = useState({ anchorTag: '' });
  const { dispatch } = useContext<any>(ScrollContext);

  useEffect(() => {
    setSortBy(gridData?.settings?.sortBy[0]?.value);
    setSortingOrder(gridData?.settings?.sortingOrder);
    setMsrp(gridData?.settings?.msrp);
    setTag(gridData?.settings?.tag);
    setBrands(gridData?.settings?.brands);
    setSuppliers(gridData?.settings?.suppliers);
    setSku(gridData?.settings?.sku);
    setProfit(gridData?.settings?.profit);
    setNames(gridData?.settings?.productNames);
    setCategories(gridData?.settings?.categories);
    setKeyWords(gridData?.settings?.keyWords);
    setIncludedProductSku(gridData?.settings?.includedProductSku);
    setGridId(gridData?.id);
    setItemsPerPage(parseInt(gridData.settings.productsPerPage));
    let tempCardWidth = 0;
    let cardCounts = gridData.settings.columnsOnDesktop;
    if (breakpoint.isTabletOrSmaller) {
      cardCounts = gridData.settings.columnsOnMobile;
    }
    tempCardWidth = 100 / parseInt(cardCounts);
    setCardCount(parseInt(cardCounts));
    setCardWidth(tempCardWidth + '%');
    setPageData({ ...gridData });
  }, [gridData, gridData.settings, breakpoint.isTabletOrSmaller]);

  useLayoutEffect(() => {
    if (page !== 0 && gridId !== null) {
      dispatch({ type: 'increment' });
      dispatch({ type: 'isLoaded', payload: true });
      setIsLoading(true);
      restClient
        .getDropshipProducts({
          page: page,
          tag: tag,
          categories: categories,
          brands: brands,
          supplier: suppliers,
          name: keyWords,
          orName: names,
          includedProductSku: includedProductSku,
          maxPrice: msrp.maxMSRP,
          minPrice: msrp.minMSRP,
          lowestPotentialProfit: profit.minProfit,
          highestPotentialProfit: profit.maxProfit,
          sortBy: `${sortBy}:${sortingOrder}`,
          perPage: itemsPerPage,
          shopId: window.cs?.shopInfo?.dropship_info?.shopId,
          userId: window.cs?.shopInfo?.dropship_info?.userId,
          accessToken: window.cs?.shopInfo?.dropship_info?.cmsAccessToken,
          url: window.cs?.shopInfo?.dropship_info?.url,
        })
        .then(response => {
          setProducts(response.data);
          setPaginationData(response.meta || {});
        })
        .finally(() => {
          setIsLoading(false);
          dispatch({ type: 'decrement' });
        });
    }
  }, [
    gridId,
    page,
    searchQuery,
    restClient,
    itemsPerPage,
    setIsNotFound,
    dispatch,
    categories,
    brands,
    msrp,
    profit,
    sku,
    names,
    includedProductSku,
    keyWords,
    sortBy,
    sortingOrder,
    suppliers,
    tag,
  ]);
  const handleCollectionSorting = (data: string | null) => {
    setIsLoading(true);
    setPage(1);
    data = data ? data : '';
    setSortMethod(data ? data : '');
    if (data.trim()) {
      restClient
        .getDropshipProducts({
          page: page,
          tag: tag,
          brands: brands,
          supplier: suppliers,
          sku: sku,
          maxPrice: msrp.maxMSRP,
          minPrice: msrp.minMSRP,
          lowestPotentialProfit: profit.minProfit,
          highestPotentialProfit: profit.maxProfit,
          sortBy: data,
          perPage: itemsPerPage,
          shopId: window.cs?.shopInfo?.dropship_info?.shopId,
          userId: window.cs?.shopInfo?.dropship_info?.userId,
          accessToken: window.cs?.shopInfo?.dropship_info?.cmsAccessToken,
          url: window.cs?.shopInfo?.dropship_info?.url,
        })
        .then(response => {
          setProducts(response.data);
          setPaginationData(response.meta || {});
        })
        .finally(() => {
          setIsLoading(false);
          dispatch({ type: 'decrement' });
        });
    }
  };

  useWindowScrollPosition(window.location.pathname, products?.length > 0);

  if (isNotFound) {
    return <Redirect to={'/404'} />;
  }
  return (
    <>
      <div className="py-5 ds-container" id={pageData.anchorTag}>
        <Container className="mb-3">
          <div className="grid gap-6">
            <div className="col-span-full">
              <div className="grid grid-cols-2 gap-4 mb-4 lg:grid-cols-2 mx-3 mt-2">
                <div className="float-left"></div>

                <div className="float-right">
                  <CollectionSorting
                    sortMethods={sortMethods}
                    activeSortMethod={sortMethod}
                    setActiveSortMethod={(value: string | null) =>
                      handleCollectionSorting(value)
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${cardCount}, ${cardWidth})`,
                }}
              >
                <ProductLoader totalThumbs={itemsPerPage} ready={!isLoading}>
                  <ProductGrid
                    products={products}
                    productClassName={'mb-3 p-2'}
                    cardSettings={gridData.settings}
                  />
                </ProductLoader>
              </div>

              {!isLoading && gridId !== null && (
                <>
                  <Paginator
                    pageContext={{ lastPage: paginationData?.last_page || 0 }}
                    onPageUpdate={v => setPage(v)}
                    activePage={page}
                    gridId={gridId}
                  />
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default React.memo(DropshipProductGridContainer);
