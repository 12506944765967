import React, { FC } from 'react';
import { ContainerGrid } from '~/components/layout/PageStructure';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { useSiteContext } from '~/context/SiteContext';
import { rewriteAnchorTagsFromRawHtml } from '~/helpers/mobileApp/rewriteAnchorTagsFromRawHtml';
import { createMarkup } from '~/utils/helpers';

export interface ContentSectionProps {
  anchorTag: string;
  settings: {
    title: string;
    description: string;
  };
}
const ContentSection: FC<{
  data: ContentSectionProps;
  oldLayout: number;
  config: any;
  parentWidget: string;
}> = ({ data, oldLayout, config, parentWidget }) => {
  const { settings, anchorTag } = data;
  const { title } = settings;
  let { description } = settings;
  const { isMobileAppConsuming } = useSiteContext();

  const paddingTop = config?.paddingTop ?? 0;
  const paddingBottom = config?.paddingBottom ?? 0;
  const paddingLeft = config?.paddingLeft ?? 0;
  const paddingRight = config?.paddingRight ?? 0;

  if (isMobileAppConsuming) {
    description = rewriteAnchorTagsFromRawHtml(description);
  }

  return (
    <div
      style={
        parentWidget === 'GridBlock'
          ? { paddingTop, paddingBottom, paddingLeft, paddingRight }
          : {}
      }
      className={parentWidget !== 'GridBlock' ? 'py-5 mb-2' : ''}
      id={anchorTag}
    >
      <div
        className={
          parentWidget !== 'GridBlock'
            ? 'container grid justify-items-center'
            : 'grid justify-items-center'
        }
      >
        {title && (
          <div className="pb-4 prose">
            <h1>{title}</h1>
          </div>
        )}

        {!oldLayout && description && (
          <div className="min-w-full grid gap-4">
            <div className="col-span-full">
              <div className="ql-editor-container">
                <div
                  className="py-2 ql-editor"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {oldLayout === 1 && description && (
        <>
          <ContainerGrid>
            <div
              className="rte mb-9 col-start-1 col-span-4 md:col-start-3 md:col-span-8 content-page-markup__wrapper"
              dangerouslySetInnerHTML={createMarkup(description)}
              data-testid="legacy-content"
            />
          </ContainerGrid>
        </>
      )}
    </div>
  );
};

export default ContentSection;
