import * as Loads from 'react-loads';
import Button, { ButtonProps } from '~/components/ui/Button';
import Link from '~/components/ui/Link';
import React from 'react';
import { useModal } from '~/context/ModalProvider';
import createRestClient from '~/utils/createRestClient';

const fetchData = async () => {
  const restClient = createRestClient();

  return await restClient.me();
};

type handleModalProps = {
  openModal: (details: any) => void;
  closeModal: (details: any) => void;
  isGuest: boolean;
  modal?: {
    beforeOpen?: () => void;
    text?: string;
  };
};

const handleModal = ({
  openModal,
  closeModal,
  isGuest = true,
  modal = {},
}: handleModalProps) => {
  if (isGuest) {
    // If beforeOpen callback exists, fire it.
    modal?.beforeOpen?.();

    return openModal({
      id: 'guest',
      content: (
        <GuestModalContent
          modalText={modal.text ?? ''}
          closeModal={closeModal}
        />
      ),
      attrs: {
        className: 'relative top-1/2 bg-main-0 rounded',
        containerClassName: 'p-7',
        overlayClassName: 'fixed inset-0 px-4 z-50',
        style: {
          content: {
            inset: 'none',
            transform: 'translateY(-50%)',
            maxWidth: '578px',
          },
        },
      },
      aria: {
        label: 'Guest',
      },
    });
  }

  return (window.location.href = '/checkout');
};

type GuestModalContentProps = {
  closeModal: (details: any) => void;
  modalText: string;
};
export const GuestModalContent: React.FC<GuestModalContentProps> = ({
  closeModal,
  modalText = '',
}) => (
  <div>
    <p>{modalText}</p>
    <div className="flex justify-between mt-8">
      <Link
        to="/account"
        onClick={closeModal}
        className="self-center underline text-tiny lg:text-small-lg"
      >
        Create account
      </Link>
      <Button
        button={{
          text: 'Login',
        }}
        attrs={{
          onClick: closeModal,
          to: '/account',
        }}
      />
    </div>
  </div>
);
type GuestModalProps = {
  button: ButtonProps & { text: string; disabled: boolean };
  modal: any;
};
const GuestModal: React.FC<GuestModalProps> = ({ button = {}, modal = {} }) => {
  const { openModal, closeModal } = useModal();

  const onCloseModal = () => {
    closeModal({ id: 'guest' });
  };

  const { response, isResolved } = Loads.useLoads('guestModal', fetchData);

  return (
    <Button
      className={button.className}
      attrs={{
        'aria-label': button.text,
        disabled: !isResolved && button.disabled,
        onClick: () => {
          handleModal({
            openModal,
            closeModal: onCloseModal,
            isGuest: response?.isGuest,
            modal,
          });
        },
      }}
      button={{ text: button.text }}
    />
  );
};

export default GuestModal;
