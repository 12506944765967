import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Customer } from '~/types';
import createRestClient from '~/utils/createRestClient';

interface CustomerContextType {
  customer: Customer | null;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
  isLoading: boolean;
}

const CustomerContext = createContext<CustomerContextType>(
  {} as CustomerContextType,
);

export const CustomerProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    setIsLoading(true);
    const restClient = createRestClient();

    restClient
      .me()
      .then(customer => {
        setCustomer(customer);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setCustomer, setIsLoading]);

  return (
    <CustomerContext.Provider
      value={{
        customer,
        setCustomer,
        isLoading,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => useContext(CustomerContext);
