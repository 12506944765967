import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { default as influencerReducer } from '../features/Influencer/influencerSlice';
import productsReducer from '~/features/Products/productsSlice';
import { webstoreApi } from '~/features/Api/apiSlice';

export const store = configureStore({
  reducer: {
    [webstoreApi.reducerPath]: webstoreApi.reducer,
    influencer: influencerReducer,
    products: productsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(webstoreApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// noinspection JSUnusedGlobalSymbols
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
