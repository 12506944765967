import { isEqual } from 'lodash';
import { useEffect } from 'react';
import usePreviousState from '~/hooks/usePreviousState';

const ActionOnParamChange = ({ action, params }) => {
  const prevParams = usePreviousState(params);

  useEffect(() => {
    if (!isEqual(params, prevParams)) {
      action();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return null;
};

export default ActionOnParamChange;
