import React, { useMemo } from 'react';
import cn from 'classnames';
import { v4 } from 'uuid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import portraitMedium from '~/assets/portraitMedium.jpg';
type ImageProps = {
  url: string;
  media: ResponsiveMedia[];
  aspectRatio?: string;
  className?: string;
  imgClassName?: string;
  imageCrop?: boolean;
  altText: string;
  optimizeQuality?: string;
};

export type ResponsiveMedia = {
  width?: number;
  height?: number;
  fit?: string;
  media?: string;
  useAsFallback?: boolean;
};

export const MediaTypes = {
  Webp: {
    type: 'image/webp',
    name: 'webp',
  },
  Jpeg: {
    type: 'image/jpeg',
    name: 'jpg',
  },
};

const buildImageUrl = (
  url: string,
  media: ResponsiveMedia,
  optimizeQuality: string,
): string => {
  return (
    url +
    `?optimize=${optimizeQuality}` +
    (media.width ? `&width=${media.width}` : '') +
    (media.height ? `&height=${media.height}&fit=cover` : '')
  );
};

export const ResponsiveImage: React.FC<ImageProps> = ({
  url,
  media,
  aspectRatio = 'natural',
  className,
  imageCrop = false,
  imgClassName = '',
  altText,
  optimizeQuality = 'medium',
}) => {
  // Need an id for the key in the map below
  const mediaWithIds = useMemo(
    () => media.map(item => ({ ...item, id: v4() })),
    [media],
  );

  return (
    <span
      className={cn('image block relative', `pt-${aspectRatio}`, className)}
    >
      <span
        className={cn({
          'absolute inset-0': aspectRatio !== 'natural',
          'flex justify-center align-center': !imageCrop,
        })}
      >
        <picture className="w-full h-full flex">
          {mediaWithIds.map(item => {
            const srcSet = buildImageUrl(url, item, optimizeQuality);

            return (
              <React.Fragment key={item.id}>
                <source
                  srcSet={
                    url != null
                      ? `${srcSet}&format=${MediaTypes.Webp.name}`
                      : portraitMedium
                  }
                  media={item.media ?? ''}
                  type={MediaTypes.Webp.type}
                  data-testid={`webp-${srcSet}`}
                />
                <source // add fallback jpeg
                  srcSet={
                    url != null
                      ? `${srcSet}&format=${MediaTypes.Jpeg.name}`
                      : portraitMedium
                  }
                  media={item.media ?? ''}
                  type={MediaTypes.Jpeg.type}
                  data-testid={`jpeg-${srcSet}`}
                />
              </React.Fragment>
            );
          })}

          {media.map(item => {
            if (!item.useAsFallback) {
              return '';
            }

            return (
              <LazyLoadImage
                key={`fallback-jpg-${item.width}`}
                className={cn(imgClassName, 'w-full h-full', {
                  'object-cover': imageCrop,
                  'object-contain': !imageCrop,
                })}
                src={
                  url != null
                    ? `${buildImageUrl(url, item, optimizeQuality)}&format=${
                        MediaTypes.Jpeg.name
                      }`
                    : portraitMedium
                }
                alt={altText}
              />
            );
          })}
        </picture>
      </span>
    </span>
  );
};
