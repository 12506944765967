import { useEffect, useState } from 'react';

// Eventually type this with the interface from the Webstore Editor in the monolith
type WebstoreConfig = Record<string, any>;

declare global {
  interface Window {
    config: WebstoreConfig;
  }
}

export default function useStoreConfig() {
  const [storeConfig, setStoreConfig] = useState<WebstoreConfig | null>(null);

  useEffect(() => {
    if (!window.config) {
      throw new Error('No Webstore configuration found');
    }

    setStoreConfig(window.config);
  }, []);

  return storeConfig;
}
