import React, { useLayoutEffect, useState } from 'react';
import { useSiteContext } from '~/context/SiteContext';
import cn from 'classnames';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import { ProductCardMedia } from '~/constants/responsiveImageConstants';
import useShopInfo from '~/hooks/live/useShopInfo';
import Modal from './DropshipQuickview';

type ProductCardPropsType = {
  product: any;
  className: string;
  cardSettings: any;
};
const ProductCard: React.FC<ProductCardPropsType> = ({
  product,
  className = '',
  cardSettings,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [showQuickShop, setShowQuickShop] = useState(false);
  const [productCardAspectRatio, setProductCardAspectRatio] = useState('2:3');
  const [showModal, setShowModal] = React.useState(false);
  const { name, lowest_msrp, id, brand, lowest_potential_profit } = product;
  // const { id, name, brand, description, lowest_msrp, highest_msrp,lowest_potential_profit,highest_potential_profit,thumbnail_image,pdp_url } = product;
  const { useResponsiveImages } = useShopInfo();

  useLayoutEffect(() => {
    setProductCardAspectRatio(cardSettings.imageOptions);
  }, [cardSettings, cardSettings.imageOptions, productCardAspectRatio]);

  // Get global settings
  const { productImageCrop = true } = useSiteContext().theme;
  const navigateToProduct = () => {
    const productId = id;
    window.parent.postMessage({ event: 'navigateToProduct', productId }, '*');
  };
  const productQuickView = () => {
    const productId = id;
    window.parent.postMessage({ event: 'quickView', productId }, '*');
  };
  return (
    <>
      <div
        className={className}
        onMouseEnter={() => setShowQuickShop(true)}
        onMouseLeave={() => setShowQuickShop(false)}
      >
        <div
          className="ds-card"
          role="presentation"
          onClick={() => navigateToProduct()}
          onKeyDown={() => navigateToProduct()}
        >
          <div className="relative block mb-2 overflow-hidden ds-product-card">
            <div
              className={cn(
                'absolute bottom-0 px-4 pb-4 z-10 w-full mobile-device:hidden',
                !showQuickShop && 'hidden',
              )}
            >
              <button
                className="button relative button--primary w-full px-1 rounded button--regular"
                type="button"
                onClick={() => productQuickView()}
              >
                Quickview
              </button>
            </div>

            <div>
              <ResponsiveImage
                aspectRatio={productCardAspectRatio}
                imageCrop={productImageCrop}
                url={product.thumbnail_image}
                altText={`Product: ${name}`}
                className={cn('ds-product')}
                media={
                  useResponsiveImages
                    ? ProductCardMedia
                    : [{ width: 600, useAsFallback: true }]
                }
              />
            </div>
          </div>
          <div className="py-2">
            <div className="uppercase text-product-card lg:text-product-card-lg py-1">
              {brand}
            </div>
            <div>
              <span
                style={{ cursor: 'pointer' }}
                role="presentation"
                className="text-product-card lg:text-product-card-lg max-lines-2 py-1 text-lg font-semibold"
                onClick={() => navigateToProduct()}
                onKeyDown={() => navigateToProduct()}
              >
                {name}
              </span>
              <div className="text-product-card lg:text-product-card-lg py-1">
                Potential Profit: ${lowest_potential_profit / 100}
              </div>
              <div className="text-product-card lg:text-product-card-lg py-1">
                MSRP: ${lowest_msrp / 100}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          id={id}
          showModal={showModal}
          onrequestClose={(v: boolean) => setShowModal(v)}
        />
      )}
    </>
  );
};

export default ProductCard;
