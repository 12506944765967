import React from 'react';
import { enablePageScroll, getScrollState } from 'scroll-lock';
import ActionOnParamChange from '~/components/utils/ActionOnParamChange';
import MailingListModal from '~/components/ui/MailingListModal';
import { useLocation } from 'react-router';
import useParams from '~/hooks/useParams';
import { useModal } from '~/context/ModalProvider';

const LayoutHooks = () => {
  const { openModal, closeModals } = useModal();
  const { pathname, hash } = useLocation();
  const { getParam } = useParams();

  const onPathAndPageChange = () => {
    if (hash === '') window.scrollTo(0, 0);
    closeModals();
  };

  const onPathChange = () => {
    if (getScrollState()) {
      enablePageScroll();
    }
  };

  return (
    <>
      <ActionOnParamChange
        action={onPathAndPageChange}
        params={[pathname, getParam('page')]}
      />
      <ActionOnParamChange action={onPathChange} params={[pathname]} />

      <MailingListModal openModal={openModal} />
    </>
  );
};

export default LayoutHooks;
