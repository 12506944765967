import React, { RefObject, useState } from 'react';
import AnnouncementBanner from '~/components/layout/Header/AnnouncementBanner';
import FreeGiftBanner from '~/components/layout/Header/FreeGiftBanner';
import HeaderMain from '~/components/layout/Header/HeaderMain';
import MiniCart from '~/components/cart/MiniCart';
import { ShopInfo, useSiteContext } from '~/context/SiteContext';
import { useLocation } from 'react-router-dom';
import LiveBanner from '~/components/layout/Header/LiveBanner';
import { useAppSelector } from '~/store/hooks';
import { selectInfluencer } from '~/features/Influencer/influencerSlice';
import { useCart } from '~/context/CartContext';

type HeaderType = {
  globals: {
    shopInfo: ShopInfo;
  };
};

const Header: React.FC<HeaderType> = ({ globals }) => {
  const [openTrigger, setOpenTrigger] =
    useState<RefObject<HTMLButtonElement> | null>(null);
  const { isMobileAppConsuming } = useSiteContext();

  const { header } = useSiteContext();
  const { pathname } = useLocation();
  const { slug: influencerSlug } = useAppSelector(selectInfluencer);

  const { shopInfo } = globals;
  const { cart } = useCart();
  let freeGiftTopBannerMessage = null;
  let displayAnnouncementBanner = true;
  if (cart?.freeGiftTopBannerMessage) {
    freeGiftTopBannerMessage = cart.freeGiftTopBannerMessage;
    displayAnnouncementBanner = false;
  }

  if (isMobileAppConsuming) {
    return '';
  }

  return (
    <>
      <header className="flex-grow-0 flex flex-col w-full max-h-screen transition-transform duration-300 ease-in-out group text-ally-0 bg-main-0">
        {freeGiftTopBannerMessage && (
          <FreeGiftBanner freeGiftTopBannerMessage={freeGiftTopBannerMessage} />
        )}

        {displayAnnouncementBanner && (
          <AnnouncementBanner data={header.announcementBanner} />
        )}

        <LiveBanner />

        <HeaderMain
          data={{ header, shopInfo, setOpenTrigger }}
          pathname={pathname}
        />

        <MiniCart
          data={{ openTrigger }}
          pathname={pathname}
          openLinksInNewTab={!!influencerSlug}
        />
      </header>
    </>
  );
};

export default Header;
