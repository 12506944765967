import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getMobileMenus from '~/utils/mobileMenus';
import { MobileMenu } from '~/types/MobileMenu';

const MobileMenuPage = () => {
  const [mobileMenus, setMobileMenus] = useState<any[]>([]);
  const history = useHistory();
  const handleNavigate = (pathEndPoint: string) => {
    history.push(pathEndPoint);
  };
  useEffect(() => {
    const menu = window.config.header.menu;
    const newNobileMenus: MobileMenu[] = getMobileMenus(menu);
    setMobileMenus(newNobileMenus);
  }, []);
  return (
    <div>
      {mobileMenus?.map((item: any, ind: number) => {
        return (
          <div key={ind + 1}>
            <button
              onClick={() => {
                handleNavigate(item.uri);
              }}
              style={{ padding: '20px', width: '100%', textAlign: 'left' }}
            >
              {item.title}
            </button>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default MobileMenuPage;
