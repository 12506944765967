import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { StreamInfo } from '~/types';
import useRestClient from '~/hooks/useRestClient';

const STREAM_INFO_UPDATE_INTERVAL = 30000; // 30 seconds

interface StreamContextType {
  streamInfo?: StreamInfo;
  setStreamInfo: React.Dispatch<React.SetStateAction<StreamInfo | undefined>>;
  getStreamInfoFromApi: () => Promise<any>;
}

const StreamContext = createContext<StreamContextType>({} as StreamContextType);

export const StreamProvider: FC = ({ children }) => {
  const [streamInfo, setStreamInfo] = useState<StreamInfo | undefined>();
  const restClient = useRestClient();

  const getStreamInfoFromApi = useCallback(() => {
    return restClient.getStreamInfo();
  }, [restClient]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getStreamInfoFromApi();
      setStreamInfo(res.data);
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();

    const interval = setInterval(() => {
      // noinspection JSIgnoredPromiseFromCall
      fetchData();
    }, STREAM_INFO_UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [restClient, setStreamInfo, getStreamInfoFromApi]);

  return (
    <StreamContext.Provider
      value={{
        streamInfo,
        setStreamInfo,
        getStreamInfoFromApi,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export const useStream = () => useContext(StreamContext);
