import cn from 'classnames';
import React, { createElement } from 'react';

const gridGap = (collapseRows: any) =>
  collapseRows ? 'gap-x-4 md:gap-x-5' : 'gap-4 md:gap-5';

/**
 * getGridColumns - Allows overwriting of default grid classes if necessary
 * @param {String} className - A string of classnames to be used with the grid
 * @returns An array of class names
 */
const getGridColumns = (className: any) => {
  const classNames = className.split(' ');

  let defaultCols = 'grid-cols-4';
  let smCols = '';
  let mdCols = 'md:grid-cols-12';
  let lgCols = '';

  classNames.map((name: any) => {
    switch (name) {
      case name.startsWith('grid-cols-'):
        return (defaultCols = name);
      case name.startsWith('sm:grid-cols-'):
        return (smCols = name);
      case name.startsWith('md:grid-cols-'):
        return (mdCols = name);
      case name.startsWith('lg:grid-cols-'):
        return (lgCols = name);
      default:
        return name;
    }
  });

  return [defaultCols, smCols, mdCols, lgCols];
};

const layoutGenerator = (type: any, className: any, children: any) =>
  createElement(
    type,
    {
      className,
    },
    children,
  );

const Container: React.FC<{ type?: string; className?: string }> = ({
  type = 'div',
  className = '',
  children,
}) => layoutGenerator(type, cn('container', className), children);

/**
 * Grid component
 *
 * @param {Object} props { type = 'div', className = '', collapseRows, children }
 */
const Grid: React.FC<{
  type?: string;
  className?: string;
  collapseRows?: boolean;
}> = ({ type = 'div', className = '', collapseRows = false, children }) =>
  layoutGenerator(
    type,
    cn('grid', getGridColumns(className), className, gridGap(collapseRows)),
    children,
  );

const ContainerGrid: React.FC<{
  type?: string;
  className?: string;
  collapseRows?: boolean;
}> = ({ type = 'div', className = '', collapseRows = false, children }) =>
  layoutGenerator(
    type,
    cn(
      'container grid',
      getGridColumns(className),
      gridGap(collapseRows),
      className,
    ),
    children,
  );

export { Container, Grid, ContainerGrid };
