import { Container } from '~/components/layout/PageStructure';
import React from 'react';
import SEO from '~/components/meta/SEO';

const errors = {
  404: "We're sorry, but the page you're looking for could not be found.",
  500: 'Your request failed to return results, please try again.',
};

const ErrorPage = () => {
  // Get the error code from the URL
  const error = window.location.pathname.replace(/\/([0-9]+).*/g, '$1');
  const text = errors[error] || errors[404];

  return (
    <>
      <SEO title={error} description={text} />
      <Container className="mt-9 mb-massive">
        <p className="text-center text-subtitle lg:text-subtitle-lg">{text}</p>
      </Container>
    </>
  );
};

export default ErrorPage;
