import React, { useEffect, useState } from 'react';
import Button from '~/components/ui/Button';
import { Grid } from '~/components/layout/PageStructure';
import Link from '~/components/ui/Link';
import ProductDetails from '../product/ProductDetails';
import PropTypes from 'prop-types';
import { getProductLink } from '~/utils/helpers';
import { useModal } from '~/context/ModalProvider';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import useRestClient from '~/hooks/useRestClient';
import ClipLoader from 'react-spinners/ClipLoader';

const modalId = 'quickshop';

const fetchProductData = async (restClient, productSlug) => {
  const data = await restClient.getProductByIdOrSlug({
    slug: productSlug,
  });

  return data?.data?.data || {};
};

const QuickShop = ({ productSlug, linkedVariantId, splitAttr }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openModal, closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const restClient = useRestClient();

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    const onCloseModal = () => {
      setIsModalOpen(false);
      closeModal({ id: modalId });
      webstoreTracker.track({
        eventName: 'quickview_closed',
        eventDetails: { productSlug },
      });
    };

    const fetchDataAndOpenModal = async () => {
      setIsLoading(true);
      const product = await fetchProductData(restClient, productSlug);
      setIsLoading(false);
      const productLink = getProductLink(
        productSlug,
        linkedVariantId,
        splitAttr,
      );

      openModal({
        id: modalId,
        attrs: {
          onRequestClose: onCloseModal,
        },
        aria: {
          label: `Product Quickview: ${product.name}`,
        },
        content: (
          <Grid className="my-8 m-0-auto max-w-5/6">
            <ProductDetails
              product={product}
              disableUrlUpdates={true}
              linkedVariantId={`${linkedVariantId}`}
              splitAttr={splitAttr}
              isLive={false}
            />
            <Link
              className="block underline mt-7 md:col-start-7 col-span-full"
              to={productLink}
              onClick={onCloseModal}
            >
              View full details
            </Link>
          </Grid>
        ),
      });
    };

    void fetchDataAndOpenModal();
  }, [
    isModalOpen,
    restClient,
    splitAttr,
    linkedVariantId,
    productSlug,
    openModal,
    closeModal,
    setIsLoading,
  ]);

  /**
   * openModal needs to be a separate state from modalStatus because:
   * - We need openModal function to be inside of QuickShopRenderer so it can
   *   use the queried data
   * - If we only use the modals modalStatus prop, we're locked in to having
   *   the QuickShopRenderer always be mounted—which fetches the data
   *   immediately
   */

  return (
    <>
      <Button
        className="w-full px-1"
        attrs={{
          onClick: () => {
            setIsModalOpen(true);
            webstoreTracker.track({
              eventName: 'quickview_opened',
              eventDetails: { productSlug },
            });
          },
          disabled: isLoading,
        }}
        button={{
          text: isLoading ? (
            <ClipLoader
              size={17}
              css={{
                borderColor:
                  'var(--color-text-1) var(--color-text-1) transparent;',
              }}
            />
          ) : (
            'Quickview'
          ),
        }}
      />
    </>
  );
};

QuickShop.propTypes = {
  productSlug: PropTypes.string,
  linkedVariantId: PropTypes.number,
};

export default React.memo(QuickShop);
