import ReactPixel from 'react-facebook-pixel';

import { TrackerHelper } from '~/types/tracker-helper.interface';
import { TrackingEvent } from '~/types/tracking-event.enum';

declare global {
  interface Window {
    fbq: any;
  }
}

window.fbq = window.fbq || undefined;

let hasBeenInit = false;

const initializeFacebook = (pixelId: string): void => {
  if (!pixelId || facebookHasBeenInitialized()) {
    return;
  }
  ReactPixel.init(pixelId);
  hasBeenInit = true;
};

const facebookHasBeenInitialized = (): boolean =>
  typeof window.fbq === 'function' && hasBeenInit;

const facebookTrackPageview = () => {
  if (facebookHasBeenInitialized()) {
    ReactPixel.pageView();
  }
};

const facebookTrackEvent = (
  name: TrackingEvent,
  data: any,
  eventData: any = {},
) => {
  if (facebookHasBeenInitialized()) {
    ReactPixel.trackCustom(name, data, eventData);
  }
};

const facebookTracker: TrackerHelper = {
  init: initializeFacebook,
  hasBeenInitialized: facebookHasBeenInitialized,
  trackEvent: facebookTrackEvent,
  trackPageView: facebookTrackPageview,
};
export default facebookTracker;
