import {
  getIsSale,
  getLowestPricedVariant,
  getPriceMinMax,
  getPriceVaries,
  getVariantSet,
} from '~/utils/helpers';

import PropTypes from 'prop-types';
import React from 'react';
import usePrice from '~/hooks/usePrice';

const ProductPrice = ({ product, className = '' }) => {
  const { splitColor, splitSize } = product;
  // Determine if product is split on an attribute
  const splitAttr = splitColor || splitSize;
  const variants = getVariantSet(product, splitAttr);
  const variant = getLowestPricedVariant(variants);
  const { priceCents, salePriceCents } = variant;
  const priceVaries = getPriceVaries(variants);
  const { min: priceMin, max: priceMax } = getPriceMinMax(variants);
  const priceEl = usePrice(priceCents);
  const salePriceEl = usePrice(salePriceCents);
  const priceMinEl = usePrice(priceMin);
  const priceMaxEl = usePrice(priceMax);
  const variantIsSale = getIsSale([variant]);

  return (
    <div className={className}>
      {variantIsSale ? (
        <>
          <div className="visually-hidden">Sale Price:</div>
          <span>{salePriceEl}</span>
          <div className="visually-hidden">Original Price:</div>
          <span className="ml-4 line-through text-grey">{priceEl}</span>
        </>
      ) : priceVaries ? (
        <>
          <span className="mr-2">{priceMinEl}</span>-
          <span className="ml-2">{priceMaxEl}</span>
        </>
      ) : (
        priceEl
      )}
    </div>
  );
};

export default ProductPrice;

ProductPrice.propTypes = {
  product: PropTypes.shape({
    variants: PropTypes.array,
  }),
};
