import { parseInt } from 'lodash';
import useIsLiveHappeningWithPolling, {
  IsLiveHappening,
} from '~/hooks/useIsLiveHappeningWithPolling';
import { useAppSelector } from '~/store/hooks';
import { selectInfluencer } from '~/features/Influencer/influencerSlice';
import { useSiteContext } from '~/context/SiteContext';

export const MAILING_LIST_MODAL_LAST_SHOWN_LOCAL_STORAGE_KEY =
  'mailingListModalLastShown';
const SHOW_MODAL_INTERVAL = 1000 * 60 * 60 * 24 * 3; // three days in ms

const useShouldShowMailingListPopup = () => {
  const { isLiveHappening } = useIsLiveHappeningWithPolling();
  const { id: influencerId } = useAppSelector(selectInfluencer);
  const { isMobileAppConsuming } = useSiteContext();
  const hasLastShownTimeoutElapsed = getHasLastShownTimeoutElapsed();

  return (
    isLiveHappening === IsLiveHappening.No &&
    !influencerId && // An ID of 0 would be an error, so this is fine
    hasLastShownTimeoutElapsed &&
    !isMobileAppConsuming
  );
};

export default useShouldShowMailingListPopup;

// PRIVATE

function getHasLastShownTimeoutElapsed() {
  const nowTimestamp = new Date().getTime();
  const lastShownTimestamp = localStorage.getItem(
    MAILING_LIST_MODAL_LAST_SHOWN_LOCAL_STORAGE_KEY,
  );
  const lastShownTimestampNumber = lastShownTimestamp
    ? parseInt(lastShownTimestamp)
    : 0;

  return nowTimestamp - lastShownTimestampNumber > SHOW_MODAL_INTERVAL;
}
