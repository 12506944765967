import 'swiper/swiper.min.css';
import 'swiper/components/a11y/a11y.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Button from '~/components/ui/Button';
import Icons from '~/components/ui/Icons';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useSiteContext } from '~/context/SiteContext';
import cn from 'classnames';
import {
  videoMimeTypeConstants,
  videoSourceConstants,
} from '~/constants/videoSourceConstants';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import {
  ProductGalleryMedia,
  ProductGalleryPreviewMedia,
  ProductGalleryThumbMedia,
} from '~/constants/responsiveImageConstants';
import useShopInfo from '~/hooks/live/useShopInfo';
import { LoadableVideoPlayer } from '~/components/ui/LoadableVideoPlayer';

SwiperCore.use([A11y, Navigation]);

const videoMediaIndex = 1;

/**
 *  @param {array} images: A list of images
 *  @param {array} videos
 *  @param {boolean} isPreview whether we are in full screen preview mode or product detail page
 */
const ProductGallery = ({
  product = {},
  images = [],
  videoSource = videoSourceConstants.NOT_SET,
  videoUrl = null,
  isPreview = false,
  initialSlide = 0,
  activeVariantId,
}) => {
  const hasMedia = images.length > 0 || videoUrl !== null;
  const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlide);
  const gallerySwiperRef = useRef(null);
  const mounted = useRef(false);
  const thumbnailSwiperRef = useRef(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isOnVideoSlide, setIsOnVideoSlide] = useState(false);
  const [sliderHasVideo, setSliderHasVideo] = useState(false);

  const media = useMemo(() => {
    const output = buildMedia(images, videoUrl);
    setSliderHasVideo(output.some(x => x.isVideo));
    return output;
  }, [images, videoUrl]);

  const {
    theme: { productCardAspectRatio, productImageCrop = true },
  } = useSiteContext();
  const { useResponsiveImages } = useShopInfo();

  useEffect(() => {
    if (!isOnVideoSlide) return;

    webstoreTracker.track({
      eventName: 'webstore_video_viewed_in_pdp_gallery',
      eventDetails: {
        productId: product.id,
      },
    });
  }, [isOnVideoSlide, product]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      Modal.setAppElement('#root');
    }
  }, [mounted]);

  useEffect(() => {
    const gallerySwiper = gallerySwiperRef.current.swiper;
    const thumbnailSwiper = thumbnailSwiperRef.current.swiper;

    if (gallerySwiper.activeIndex !== activeSlideIndex) {
      gallerySwiper.slideTo(activeSlideIndex);
    }
    if (thumbnailSwiper.activeIndex !== activeSlideIndex) {
      thumbnailSwiper.slideTo(activeSlideIndex);
    }

    setIsOnVideoSlide(sliderHasVideo && activeSlideIndex === videoMediaIndex);
  }, [activeSlideIndex, sliderHasVideo]);

  /**
   * Handle onSlideChange Swiper event
   * @param {Swiper} swiper instance of swiper
   */
  const onSlideChange = swiper => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  /**
   * Handle onClick event on slides
   * @param {Swiper} swiper instance of swiper
   */
  const onSlideClick = swiper => {
    if (swiper.clickedIndex !== undefined) {
      setActiveSlideIndex(swiper.clickedIndex);
    }
  };

  const mainSwiperParams = {
    initialSlide,
    onSlideChange,
    navigation: true,
    slidesPerView: 1,
  };

  const thumbnailSwiperParams = {
    initialSlide,
    onSlideChange,
    slidesPerView: 4,
    spaceBetween: 10,
    onClick: onSlideClick,
    centerInsufficientSlides: isPreview,
    breakpoints: {
      480: {
        slidesPerView: isPreview ? 6 : 4,
      },
      640: {
        slidesPerView: isPreview ? 8 : 4,
      },
    },
    navigation: true,
  };

  const goToVideoSlide = () => {
    const gallerySwiper = gallerySwiperRef.current.swiper;
    const thumbnailSwiper = thumbnailSwiperRef.current.swiper;

    if (
      gallerySwiper.activeIndex !== videoMediaIndex &&
      thumbnailSwiper.activeIndex !== videoMediaIndex
    ) {
      setActiveSlideIndex(videoMediaIndex);
    }
  };

  const openModal = () => {
    setIsOpen(true);
    disablePageScroll();
  };

  const closeModal = () => {
    setIsOpen(false);
    enablePageScroll();
  };

  let videoIsLiveStream = videoSourceConstants.NOT_SET;
  let videoMimeType = videoMimeTypeConstants.NOT_SET;

  if (videoUrl && videoSource) {
    videoIsLiveStream = videoSource === videoSourceConstants.LIVE_SALE;
    videoMimeType = videoIsLiveStream
      ? videoMimeTypeConstants.STREAM
      : videoMimeTypeConstants.MP4;
  }

  const hasVideoToDisplay =
    videoIsLiveStream !== videoSourceConstants.NOT_SET &&
    videoMimeType !== videoMimeTypeConstants.NOT_SET;

  const videoJsOptions = {
    loop: true,
    sources: [
      {
        src: videoUrl,
        type: videoMimeType,
      },
    ],
  };

  const customModalStyles = {
    content: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      padding: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '0',
      borderRadius: '0',
      overflow: 'hidden',
    },
  };

  // noinspection RequiredAttributes
  return (
    <>
      <div
        className={cn(
          'product-swiper relative mb-4 border-opacity-50 mx-auto rounded',
          {
            'bg-main-3 h-full': !hasMedia,
            'max-w-4xl preview-gallery-full-height': isPreview,
            'border border-grey ': !isPreview,
          },
        )}
      >
        {/* Main Swiper */}
        <Swiper ref={gallerySwiperRef} {...mainSwiperParams}>
          {media.map((item, index) => (
            <SwiperSlide
              key={`${product.id}-${activeVariantId}-slides-${index}`}
            >
              {item.isVideo && hasVideoToDisplay ? (
                <div
                  className={cn(
                    'video-and-in-this-video-wrapper relative w-full h-full',
                    {
                      'video-crop': !isPreview && productImageCrop,
                      [`pt-${productCardAspectRatio}`]:
                        !isPreview && productCardAspectRatio !== 'natural',
                    },
                  )}
                >
                  <div
                    className={cn('h-full', {
                      'absolute inset-0': !isPreview,
                    })}
                  >
                    {/* Unmount this if not on the slide. Stops playback and sound. */}
                    {activeSlideIndex === index && (
                      <LoadableVideoPlayer
                        className="w-full h-full rounded object-fit"
                        tryToAutoplay={true}
                        videoJsOptions={videoJsOptions}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={cn('relative', {
                    'w-full': !isPreview,
                  })}
                >
                  <ResponsiveImage
                    className={cn('rounded', {
                      'flex justify-center': isPreview,
                      'w-full': !isPreview,
                    })}
                    imgClassName={cn({
                      'preview-gallery-full-height': isPreview,
                    })}
                    aspectRatio={isPreview ? 'natural' : productCardAspectRatio}
                    imageCrop={isPreview ? false : productImageCrop}
                    url={item.url}
                    altText={product?.name || ''}
                    media={
                      useResponsiveImages
                        ? isPreview
                          ? ProductGalleryPreviewMedia
                          : ProductGalleryMedia
                        : [{ width: 1000, useAsFallback: true }]
                    }
                  />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {!isPreview && hasMedia && (
          <span
            className={cn('absolute z-10 bottom-2 left-2', {
              'bottom-6': isOnVideoSlide,
            })}
          >
            <Button
              attrs={{
                onClick: openModal,
              }}
              button={{
                style: 'tertiary',
                size: 'small',
                text: 'See larger',
              }}
            />
          </span>
        )}

        {!isPreview && sliderHasVideo && !isOnVideoSlide && (
          <div className="absolute z-10 leading-none bottom-2 right-2">
            <button aria-label="play featured video" onClick={goToVideoSlide}>
              <Icons className="w-6" type="playcircle" />
            </button>
          </div>
        )}
      </div>
      {/* Thumbs Swiper */}
      <Swiper
        ref={thumbnailSwiperRef}
        className={cn(
          'product-gallery__thumbs hidden product-gallery__thumbs--modal md:block',
        )}
        {...thumbnailSwiperParams}
      >
        {media.map((item, index) => (
          <SwiperSlide key={`${product.id}-${activeVariantId}-thumbs-${index}`}>
            <div
              className={cn('h-full w-full rounded overflow-hidden border', {
                'swiper-slide-active-actual border-text-0':
                  index === activeSlideIndex,
                'border-grey border-opacity-50': index !== activeSlideIndex,
              })}
            >
              {item.isVideo ? (
                <div
                  className={cn(
                    'relative images-center w-full h-full cursor-pointer',
                    `pt-${productCardAspectRatio}`,
                    {
                      'bg-cover': productImageCrop && !isPreview,
                      'bg-contain': !productImageCrop || isPreview,
                    },
                  )}
                >
                  <button className="absolute inset-0 flex items-center justify-center w-full focus:outline-none focus:border-main-2">
                    <Icons type="play" className="inline-block w-5" />
                  </button>
                </div>
              ) : (
                <ResponsiveImage
                  className="cursor-pointer"
                  url={item.url}
                  altText={`Product thumbnail #${index}`}
                  aspectRatio={productCardAspectRatio}
                  media={
                    useResponsiveImages
                      ? ProductGalleryThumbMedia
                      : [{ useAsFallback: true }]
                  }
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {!isPreview && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="product-gallery__modal bg-main-0"
          style={customModalStyles}
        >
          <button className="absolute z-10 top-2 right-2" onClick={closeModal}>
            <Icons type="close" className="w-6 h-6" />
          </button>
          <div className="px-4 py-8 md:pt-4 w-gallery h-gallery">
            <ProductGallery
              images={images}
              videoSource={videoSource}
              videoUrl={videoUrl}
              isPreview={true}
              initialSlide={activeSlideIndex}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

ProductGallery.propTypes = {
  activeVariantId: PropTypes.string,
  isPreview: PropTypes.bool,
  images: PropTypes.array.isRequired,
  initialSlide: PropTypes.number,
  product: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
  }),
  videoSource: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default ProductGallery;

// Private functions

function buildMedia(images, videoUrl) {
  const imagesCopy = [...images]; // No mutation of images

  if (videoUrl) {
    imagesCopy.splice(videoMediaIndex, 0, { url: videoUrl, isVideo: true });
  }

  return imagesCopy;
}
