import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Spinner = ({ className, ...props }: SpinnerProps) => (
  <div className={cn('spinner', className)} {...props} />
);

export default Spinner;
