import React, { useState } from 'react';

import AnimateHeight from 'react-animate-height';
import ConditionalWrapper from '~/components/utils/ConditionalWrapper';
import Icons from '~/components/ui/Icons';
import cn from 'classnames';

/* Input
 * Possible styles: primary, secondary, text
 */
interface InputPropsBase {
  attrs: {
    id?: string;
    type?: string;
    checked?: boolean;
    value?: string;
    className?: string;
    labelClassName?: string;
  };
  className?: string;
  input?: {
    arrow?: string;
    label?: string;
    error?: string;
  };
  attachedButton?: unknown;
  onValue?: (value: string) => void;
  onSubmit?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  icon?: {
    type: string;
    onClick: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    className: string;
  };
  fullWidthBreakpoint?: string;
}

interface CheckboxInputProps extends InputPropsBase {
  attrs: {
    id?: string;
    type?: string;
    checked: boolean;
    value?: never;
    className?: string;
    labelClassName?: string;
    placeholder?: string;
  };
}

interface InputValueProps extends InputPropsBase {
  attrs: {
    id?: string;
    type?: string;
    checked?: never;
    value?: string;
    className?: string;
    labelClassName?: string;
    placeholder?: string;
  };
}

const Input: React.FC<CheckboxInputProps | InputValueProps> = ({
  attrs = {},
  className,
  input = {},
  attachedButton,
  onValue,
  onSubmit,
  icon = {},
  fullWidthBreakpoint = 'sm',
}) => {
  const {
    type: iconType,
    onClick: iconOnClick,
    className: iconClassName,
  } = icon;
  const { id, type = 'text', checked = false, value = '' } = attrs;
  const { error, label } = input;
  const {
    className: inputClassName,
    labelClassName = '',
    ...inputAttrs
  } = attrs;
  const isCheckable = ['checkbox', 'radio'].includes(type);

  const [currentValue, setCurrentValue] = useState(
    isCheckable ? checked : value,
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSubmit) {
      onSubmit(event);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = isCheckable ? event.target.checked : event.target.value;

    setCurrentValue(newValue);

    if (onValue) {
      onValue(newValue as string);
    }
  };

  return (
    <div
      className={cn(
        `input-group relative w-full`,
        className,
        !isCheckable && `${fullWidthBreakpoint}:w-auto`,
        {
          'input-group--checkbox': type === 'checkbox',
          'input-group--radio': type === 'radio',
        },
      )}
    >
      {label && (
        <label htmlFor={id} className={labelClassName} title={label}>
          {label}
        </label>
      )}

      <ConditionalWrapper
        condition={!!attachedButton}
        wrapper={(children: React.ReactNode) => (
          <div
            className={cn('input--attachment-container flex-col', {
              'w-full': !isCheckable,
            })}
          >
            {children}
          </div>
        )}
      >
        {isCheckable && checked && (
          <div className="icon-check-container">
            <Icons type="check" />
          </div>
        )}

        <div
          className={cn('relative leading-none', {
            'mb-3 mb-0 mr-0 sm:mr-5': attachedButton,
            'flex-grow': attachedButton && !isCheckable,
            'w-full': !isCheckable,
          })}
        >
          <input
            className={cn(
              'input',
              inputClassName,
              {
                'input--error': !!error,
                'pr-6': !!iconType,
              },
              'cursor-pointer',
            )}
            {...inputAttrs}
            value={currentValue as string}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />

          {iconType && (
            <Icons
              type={iconType}
              className={cn('absolute center-vertical right-1', iconClassName)}
              onClick={iconOnClick}
            />
          )}
        </div>
        {attachedButton}
      </ConditionalWrapper>

      {!isCheckable && (
        <AnimateHeight height={error ? 'auto' : 0} className="text-left">
          <span className="input__error">{error}</span>
        </AnimateHeight>
      )}
    </div>
  );
};

export default Input;
