import { Container, Grid } from '~/components/layout/PageStructure';
import React, { useContext, useEffect, useState } from 'react';
import ProductFilters from '~/components/productlist/ProductFilters';
import ProductSorting from '~/components/productlist/ProductSorting';
import ProductListPageStructuredData from '~/components/meta/structured-data/ProductListPageStructuredData';
import FilterContext from '~/context/FilterContext';
import Paginator from '~/components/ui/Paginator';
import ProductGrid from '~/components/product/ProductGrid';
import SearchStructuredData from '~/components/meta/structured-data/SearchStructuredData';
import UIContext from '~/context/UIContext';
import useParams from '~/hooks/useParams';
import enums from '~/utils/enums';
import cn from 'classnames';
import useStoreConfig from '~/hooks/useStoreConfig';
import { ProductListLoader } from '~/components/ui/molecules/skeleton-loaders/ProductListLoader';
import useRestClient from '~/hooks/useRestClient';
import { ProductFilterLoader } from '~/components/ui/molecules/skeleton-loaders/ProductFilterLoader';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { GA4Event } from '~/types/google-analytics-4-event.enum';
import { AnalyticGroups } from '~/types/analytic-groups.enum';
import { Redirect } from 'react-router-dom';
import useWindowScrollPosition from '~/hooks/useWindowScrollPosition';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { selectProducts, setProducts } from '~/features/Products/productsSlice';
import SEO from '~/components/meta/SEO';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import PageTitle from '~/components/modules/pageTitle';
import { SubNavigation } from '~/components/productlist/SubNavigation';
import { useSiteContext } from '~/context/SiteContext';

const itemsPerPage = 24;

type FilterOptionsType = {
  externalTypes?: FilterOptionItemType;
  sizes?: FilterOptionItemType;
  inStockOnly?: FilterOptionItemType;
};

type FilterOptionItemType = {
  label: string;
  values: string[];
};

const additionalOptions = {
  inStockOnly: {
    label: 'Stock',
    values: ['In stock'],
    type: 'Boolean',
  },
};

const addAdditionalOptions = (
  filterOptions: FilterOptionsType,
): FilterOptionsType => {
  return {
    ...filterOptions,
    ...additionalOptions,
  };
};

export interface HeaderProps {
  pageTitle: string;
  pageKey: string;
  seoTitle: string;
  seoDescription: string;
  contentHTML: string;
  canonicalUrl: string;
  subNavLinks?: NavLink[];
  breadCrumbs?: Breadcrumb[];
}

export interface Breadcrumb {
  title: string;
  uri: string;
}

export interface NavLink {
  id: number;
  title: string;
  url: string;
}

const defaultSortMethod = enums.sortMethods.featured;

export type ProductGridWithFiltersProps = {
  productSearch: {
    categoryId?: string;
    searchQuery?: string;
    collectionKey?: string;
  };
  headerProps: HeaderProps;
  isTitleLoading?: boolean;
};

const ProductGridWithFilters = ({
  productSearch: { searchQuery, collectionKey, categoryId },
  isTitleLoading = false,
  headerProps: {
    seoDescription = '',
    seoTitle = '',
    canonicalUrl = '',
    contentHTML = '',
    pageKey = '',
    pageTitle = '',
    subNavLinks = [],
    breadCrumbs = [],
  }, // eslint-disable-next-line sonarjs/cognitive-complexity
}: ProductGridWithFiltersProps) => {
  const isSearchPage = !!searchQuery;
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const dispatch = useAppDispatch();
  const { featureFlags } = useSiteContext();
  const { products } = useAppSelector(selectProducts);
  const [paginationData, setPaginationData] = useState<{
    count: number;
    currentPage: number;
    firstItem: number;
    lastItem: number;
    lastPage: number;
    perPage: number;
    total: number;
  }>();
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>({});
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  // Default values are not set to wait for URL context
  const [activeFilters, setActiveFilters] = useState<
    | {
        inStockOnly?: boolean;
        externalTypes?: [];
        sizes?: [];
      }
    | undefined
  >();
  const [page, setPage] = useState(1);
  const [sortMethod, setSortMethod] = useState('');
  const storeConfig = useStoreConfig();

  let showFilters = true;
  let showSortOptions = true;
  if (storeConfig?.collection) {
    showFilters = storeConfig.collection.showFilters;
    showSortOptions = storeConfig.collection.showSortOptions;
  }

  const { breakpoint } = useContext(UIContext);
  const { getParam, deleteParam, setParam } = useParams();

  const restClient = useRestClient();

  useEffect(() => {
    let shouldUpdate = true;
    if (!activeFilters) {
      return;
    }

    const fetchData = async () => {
      setIsFilterLoading(true);
      const res = await restClient
        .getFilterOptions({
          ...activeFilters,
          collectionKey,
          categoryId,
          query: searchQuery,
        })
        .finally(() => {
          if (!shouldUpdate) {
            return;
          }
          setIsFilterLoading(false);
        });
      if (shouldUpdate) {
        const filterOptions: FilterOptionsType = {};
        if (featureFlags?.productTypes?.filter.enabled) {
          Object.assign(filterOptions, {
            externalTypes: res.data.filterOptions.externalTypes,
          });
        }
        Object.assign(filterOptions, { sizes: res.data.filterOptions.sizes });
        setFilterOptions(filterOptions);
      }

      return () => {
        shouldUpdate = false;
      };
    };

    void fetchData();
  }, [
    restClient,
    setFilterOptions,
    activeFilters,
    collectionKey,
    categoryId,
    searchQuery,
    featureFlags,
  ]);

  useEffect(() => {
    let shouldUpdate = true;
    // clear out previous products
    dispatch(setProducts([]));
    // wait until activeFilters are set via FilterContext
    // since they can come from the URL
    if (!activeFilters) {
      return;
    }
    setIsLoading(true);
    restClient
      .getProducts({
        externalTypes: activeFilters?.externalTypes,
        sizes: activeFilters?.sizes,
        orderBy: sortMethod || defaultSortMethod,
        pageNumber: page,
        itemsPerPage,
        collectionKey,
        searchQuery,
        inStockOnly: activeFilters?.inStockOnly ? 1 : 0,
        categoryId,
      })
      .then(response => {
        if (!shouldUpdate) {
          return;
        }
        const products = response.products;

        dispatch(setProducts(products.data || []));
        setProducts(products.data || []);
        setPaginationData(products.paginatorInfo || {});
        if (!products) {
          setIsNotFound(true);
        }
      })
      .finally(() => {
        if (!shouldUpdate) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      shouldUpdate = false;
    };
  }, [
    sortMethod,
    page,
    activeFilters,
    collectionKey,
    searchQuery,
    categoryId,
    restClient,
    setIsNotFound,
    dispatch,
  ]);

  useEffect(() => {
    if (!products || products.length === 0 || searchQuery) {
      //don't send with 0 products or if we are on a search page
      return;
    }

    const data = {
      eventName: GA4Event.ViewItemList,
      eventDetails: {
        item_list_id: pageKey,
        item_list_name: pageTitle,
        items: [
          ...products.map(product => {
            return {
              item_id: product.sku,
              item_name: product.name,
            };
          }),
        ],
        send_to: AnalyticGroups.All,
      },
    };
    webstoreTracker.track(data);
  }, [products, pageKey, pageTitle, searchQuery]);

  useWindowScrollPosition(window.location.pathname, products.length > 0);

  const { firstItem = 1, lastItem = 1, total = 0 } = paginationData || {};

  if (isSearchPage) {
    pageTitle = isTitleLoading
      ? `Searching for "${searchQuery}"...`
      : `Your search for "${searchQuery}" found ${total} items`;
  }

  if (isNotFound) {
    return <Redirect to={'/404'} />;
  }

  return (
    <FilterContext
      getParam={getParam}
      deleteParam={deleteParam}
      setParam={setParam}
      setFilters={setActiveFilters}
      setSortMethod={setSortMethod}
    >
      <Container className="mb-3">
        <SEO
          title={seoTitle ? seoTitle : pageTitle}
          description={seoDescription}
        />
        {canonicalUrl ? (
          <Helmet>
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
        ) : (
          ''
        )}
        {isTitleLoading ? (
          <Skeleton
            style={{ width: '30%' }}
            className={'py-1 my-7 grid m-auto'}
          />
        ) : (
          <PageTitle title={pageTitle} />
        )}

        {breadCrumbs?.length ? (
          <div>
            {breadCrumbs.map((crumb, key) => {
              return (
                <React.Fragment key={crumb.uri}>
                  {key !== 0 && ' / '}
                  <a key={crumb.uri} href={crumb.uri}>
                    {crumb.title}
                  </a>
                </React.Fragment>
              );
            })}
            <span> / {pageTitle}</span>
          </div>
        ) : (
          ''
        )}

        <div className="grid grid-cols-5 gap-6">
          {showFilters && !breakpoint.isTabletOrSmaller && (
            <ProductFilterLoader
              isReady={!isFilterLoading}
              className={'col-span-1 hidden md:block mt-4'}
            >
              <ProductFilters
                sidebarClassName="col-span-1 hidden md:block mt-4"
                toolbarClassName="hidden"
                onFilterUpdate={setActiveFilters}
                filters={addAdditionalOptions(filterOptions)}
              />
            </ProductFilterLoader>
          )}

          <div
            className={cn('col-span-full mt-4', {
              'md:col-span-4': showFilters,
            })}
          >
            {subNavLinks && subNavLinks.length > 0 && !isTitleLoading && (
              <SubNavigation
                links={subNavLinks}
                currentPageTitle={pageTitle}
              ></SubNavigation>
            )}
            <div className="grid grid-cols-2 gap-4 mb-4 lg:grid-cols-3">
              {showFilters && breakpoint.isTabletOrSmaller ? (
                <ProductFilterLoader
                  isReady={!isFilterLoading}
                  className={
                    'mb-2 sm:mb-0 block md:hidden col-span-1 col-start-1 w-full'
                  }
                >
                  <span className="self-end text-grey text-tiny lg:text-tiny-lg">
                    Showing {firstItem}-{lastItem} of {total} items
                  </span>
                  <ProductFilters
                    sidebarClassName="hidden"
                    toolbarClassName="mb-2 sm:mb-0 block md:hidden col-span-1 col-start-1 w-full"
                    onFilterUpdate={setActiveFilters}
                    filters={addAdditionalOptions(filterOptions)}
                  />
                </ProductFilterLoader>
              ) : (
                <span className="self-end text-grey text-tiny lg:text-tiny-lg">
                  Showing {firstItem}-{lastItem} of {total} items
                </span>
              )}

              {/* This can be shown while loading on larger screens */}
              {showSortOptions &&
                (!isFilterLoading || !breakpoint.isTabletOrSmaller) && (
                  <ProductSorting
                    sortMethod={sortMethod}
                    sortMethodCallback={null}
                    selectClassName="col-span-1 col-start-2 lg:col-start-3 md:w-max md:ml-auto"
                  />
                )}
            </div>

            <Grid>
              <ProductListLoader totalThumbs={15} ready={!isLoading}>
                <ProductGrid
                  products={products}
                  productClassName="col-span-2 md:col-span-4 lg:col-span-3 mb-3"
                />
              </ProductListLoader>
            </Grid>

            {!isLoading && (
              <>
                <Paginator
                  pageContext={{ lastPage: paginationData?.lastPage }}
                  onPageUpdate={setPage}
                  activePage={page}
                />
                {contentHTML && (
                  <div className="product-grid--collection-description grid grid-cols-3 border-t-4 border-main-1 mt-4 pt-4 text-small">
                    <div
                      className="col-span-3 md:col-span-2"
                      dangerouslySetInnerHTML={{ __html: contentHTML }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {paginationData?.currentPage && (
          <>
            {!isSearchPage && (
              <ProductListPageStructuredData
                title={pageTitle}
                location={window.location}
                page={paginationData?.currentPage}
              />
            )}
            {isSearchPage && (
              <SearchStructuredData url={window.location.href} />
            )}
          </>
        )}
      </Container>
    </FilterContext>
  );
};

export default React.memo(ProductGridWithFilters);
