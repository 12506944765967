import Modal from '~/components/ui/Modal';
import React, { useContext } from 'react';
import { enablePageScroll } from 'scroll-lock';
import { omit } from 'lodash';

interface ModalContextValue {
  openModal: (details: any) => void;
  closeModal: (id: any) => void;
  closeModals: () => void;
}

const ModalContext = React.createContext<ModalContextValue>(
  undefined as unknown as ModalContextValue,
);

interface ModalState {
  modals: Record<
    string,
    { id: string; content: any; attrs: any; aria: any; open: boolean }
  >;
}

class ModalProvider extends React.Component<unknown, ModalState> {
  state: ModalState = {
    modals: {},
  };

  openModal = (details: any) => {
    const { content, id, attrs, aria } = details;
    const { modals } = this.state;

    this.setState({
      modals: {
        ...modals,
        [id]: {
          open: true,
          content,
          attrs,
          aria,
        },
      },
    });
  };

  closeModal = ({ id }: any) => {
    const { modals } = this.state;

    this.setState((prevState: any) => {
      return {
        modals: {
          ...modals,
          [id]: { ...prevState[id], open: false },
        },
      };
    });

    // Timeout is to keep the transition of the modal
    // This could be simplified by not removing it but this
    // will keep state cleaner if the modal isn't in use
    setTimeout(() => {
      this.setState({ modals: omit(modals, id) });
    }, 500);
  };

  closeModals = () => {
    this.setState({ modals: {} });
    enablePageScroll();
  };

  render = () => {
    const { modals } = this.state;

    return (
      <ModalContext.Provider
        value={{
          openModal: this.openModal,
          closeModal: this.closeModal,
          closeModals: this.closeModals,
        }}
      >
        {Object.keys(modals).map(modalId => {
          const modal = modals[modalId];

          const onRequestClose = () => {
            modal?.attrs?.onRequestClose?.();
            this.closeModal({ id: modalId });
          };

          return (
            <Modal
              key={`modal-${modalId}`}
              open={modal.open}
              {...modal.attrs}
              aria={modal.aria}
              onRequestClose={onRequestClose}
            >
              {modal.content}
            </Modal>
          );
        })}

        {this.props.children}
      </ModalContext.Provider>
    );
  };
}

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
