import { Container } from '~/components/layout/PageStructure';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

/**
 * PageTitle - The title component for a page
 * @param {String} title - The title of the page
 * @param {String('left'|'center'|'right')} textAlign - Align text to a certain position
 */
const PageTitle = ({
  title,
  textAlign = 'center',
}: {
  title: string;
  textAlign?: 'center' | 'left' | 'right';
}) => (
  <Container>
    <div
      className={cn('my-7', {
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left',
      })}
    >
      {title && <h1 className="relative text-h4 lg:text-h4-lg">{title}</h1>}
    </div>
  </Container>
);

PageTitle.propTypes = {
  title: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default PageTitle;
