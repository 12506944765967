import * as Loads from 'react-loads';

import React, { useContext } from 'react';

import ConditionalWrapper from '~/components/utils/ConditionalWrapper';
import { Container } from '~/components/layout/PageStructure';
import ProductSlider from '~/components/product/ProductSlider';
import UIContext from '~/context/UIContext';
import client from '~/utils/client';
import { productFragment } from '~/utils/queries';

const query = `
  query {
    suggestedProducts {
      ${productFragment}
    }
  }
`;

const fetchData = async () => {
  return await client.request(query, null, { cache: true });
};

export const SuggestedItems: React.FC = () => {
  const {
    breakpoint: { isMobile },
  } = useContext(UIContext);
  const { response, isResolved } = Loads.useLoads('SuggestedItems', fetchData);

  return (
    <ConditionalWrapper
      condition={!isMobile}
      wrapper={(children: JSX.Element | JSX.Element[]) => (
        <Container>{children}</Container>
      )}
    >
      <div className="py-6 mt-6 sm:px-5 lg:px-0 bg-grey text-white bg-opacity-5">
        <Container className="mb-2">
          <h3 className="text-tiny lg:text-tiny-lg font-body text-grey">
            You might also like:
          </h3>
        </Container>
        {isResolved && (
          <ConditionalWrapper
            condition={isMobile}
            wrapper={(children: JSX.Element | JSX.Element[]) => (
              <Container>{children}</Container>
            )}
          >
            <ProductSlider products={response?.suggestedProducts} />
          </ConditionalWrapper>
        )}
      </div>
    </ConditionalWrapper>
  );
};

export default SuggestedItems;
