import React, { useLayoutEffect, useState } from 'react';
import {
  getImageSet,
  getIsSale,
  getIsWaitlist,
  getLowestPricedVariant,
  getOptionValues,
  getProductLink,
  getVariantSet,
} from '~/utils/helpers';

import Icons from '~/components/ui/Icons';
import Link from '~/components/ui/Link';
import QuickShop from '~/components/ui/QuickShop';
import { useSiteContext } from '~/context/SiteContext';
import cn from 'classnames';
import enums from '~/utils/enums';
import ProductPrice from '~/components/product/ProductPrice';
import useStoreConfig from '~/hooks/useStoreConfig';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import { ProductCardMedia } from '~/constants/responsiveImageConstants';
import useShopInfo from '~/hooks/live/useShopInfo';

type ProductCardPropsType = {
  product: any;
  className: string;
  cardSettings: any;
};
const ProductCard: React.FC<ProductCardPropsType> = ({
  product,
  className = '',
  cardSettings,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [showSecondImg, setShowSecondImg] = useState(false);
  const [showQuickShop, setShowQuickShop] = useState(false);
  const [productCardAspectRatio, setProductCardAspectRatio] = useState('2:3');
  const { slug, name, splitColor, splitSize, videoUrl, type } = product;
  const storeConfig = useStoreConfig();
  const { useResponsiveImages } = useShopInfo();

  useLayoutEffect(() => {
    setProductCardAspectRatio(cardSettings.imageOptions);
  }, [cardSettings, cardSettings.imageOptions, productCardAspectRatio]);

  const showWaitListFullImageOpacity =
    !!storeConfig?.features?.showWaitListFullImageOpacity;

  // Get global settings
  const { productImageCrop = true } = useSiteContext().theme;

  // Determine if product is split on an attribute
  const splitAttr = splitColor || splitSize;

  // Get active variant set
  const variants = getVariantSet(product, splitAttr);

  // Get color set
  // Used to display number of color options a product has
  const colors = getOptionValues(variants, 'color');

  // The lowest priced variant is the displayed/linked variant
  const variant = getLowestPricedVariant(variants);
  const { priceCents, salePriceCents } = variant;
  const linkedVariantId = variant.id;

  // Get images for this Product Card listing
  // Second image is shown on mouse hover
  const images = getImageSet(product);
  const firstImage = images[0] ?? false;
  const secondImage = images[1] ?? false;

  // Create link to specific variant
  const productLink = getProductLink(slug, null, splitAttr);

  // Check if product is on sale
  const productIsSale = getIsSale(variants);

  // Check if product is completely waitlisted
  // Toggles the image flag
  const productIsWaitlist =
    type === enums.productTypes.giftCard ? false : getIsWaitlist(variants);

  const ImageFlag = () => {
    const flag = (productIsWaitlist && 'Waitlist') || (productIsSale && 'Sale');

    return (
      <span className="absolute px-3 rounded top-4 left-4 py-1/2 text-tiny lg:text-tiny-lg bg-main-0 text-ally-0">
        {flag}
      </span>
    );
  };

  // Set up image flip attributes
  let imageFlipAttrs = {};
  if (secondImage && cardSettings.secondImageOnHover) {
    imageFlipAttrs = {
      onMouseEnter: () => setShowSecondImg(true),
      onMouseLeave: () => setShowSecondImg(false),
    };
  }

  return (
    <div
      className={className}
      onMouseEnter={() => setShowQuickShop(true)}
      onMouseLeave={() => setShowQuickShop(false)}
    >
      <div
        className="relative block mb-2 overflow-hidden border border-opacity-50 rounded border-grey"
        {...imageFlipAttrs}
      >
        <div
          className={cn(
            'absolute bottom-0 px-4 pb-4 z-10 w-full mobile-device:hidden',
            !showQuickShop && 'hidden',
          )}
        >
          <QuickShop
            productSlug={slug}
            linkedVariantId={linkedVariantId}
            splitAttr={splitAttr}
          />
        </div>

        <Link to={productLink} className="product-interactive">
          <div className={cn(showSecondImg && 'desktop-device:hidden')}>
            <ResponsiveImage
              aspectRatio={productCardAspectRatio}
              imageCrop={productImageCrop}
              url={firstImage.url}
              altText={`Product: ${name}`}
              className={cn(
                productIsWaitlist &&
                  !showWaitListFullImageOpacity &&
                  'opacity-50',
              )}
              media={
                useResponsiveImages
                  ? ProductCardMedia
                  : [{ width: 600, useAsFallback: true }]
              }
            />
          </div>

          {secondImage && (
            <div
              className={cn('mobile-device:hidden', !showSecondImg && 'hidden')}
            >
              <ResponsiveImage
                aspectRatio={productCardAspectRatio}
                imageCrop={productImageCrop}
                url={secondImage.url}
                altText={name}
                className={cn(
                  productIsWaitlist &&
                    !showWaitListFullImageOpacity &&
                    'opacity-50',
                )}
                media={
                  useResponsiveImages
                    ? ProductCardMedia
                    : [{ width: 600, useAsFallback: true }]
                }
              />
            </div>
          )}

          {videoUrl && (
            <Icons
              type="playcircle"
              className="absolute w-5 right-3 bottom-3"
            />
          )}
        </Link>

        {(productIsWaitlist || productIsSale) && <ImageFlag />}
      </div>
      <div>
        <div>
          <Link
            className="text-product-card lg:text-product-card-lg max-lines-2 font-semibold"
            to={productLink}
          >
            {name}
          </Link>
          {(!!priceCents || !!salePriceCents) && (
            <ProductPrice
              product={product}
              className="mt-1 text-product-card lg:text-product-card-lg"
            />
          )}

          {colors && colors.length > 1 && (
            <div className="mt-1 text-tiny lg:text-tiny-lg text-grey">
              {colors.length} Colors
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
