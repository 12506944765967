import React, { useCallback, useEffect } from 'react';

import Button from '~/components/ui/Button';
import cn from 'classnames';
import useParams from '~/hooks/useParams';
import { useLocation } from 'react-router-dom';

/**
 * This function gathers necessary data for the paginator elements. It will return
 * information regarding showing previous & next buttons relative to current and
 * last pages, along with an array of page numbers to show based on the current
 * page, and the maximum page available for skipping forward.
 * @param {number} currentPage - The current page number
 * @param {number} lastPage - The last page available
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const getPageData = (currentPage: number, lastPage: number) => {
  const visiblePages: number[] = [];

  if (currentPage === 1) {
    visiblePages.push(1);

    if (lastPage >= 2) {
      visiblePages.push(2);
    }

    if (lastPage >= 3) {
      visiblePages.push(3);
    }
  } else if (currentPage === lastPage) {
    if (currentPage - 2 >= 1) {
      visiblePages.push(currentPage - 2, currentPage - 1, currentPage);
    } else {
      if (currentPage - 1 >= 1) {
        visiblePages.push(currentPage - 1, currentPage);
      }
    }
  } else {
    visiblePages.push(currentPage - 1, currentPage, currentPage + 1);
  }

  return {
    previousPage: currentPage > 1,
    nextPage: currentPage < lastPage,
    visiblePages,
    minPage: lastPage > 3 && currentPage > 2,
    maxPage:
      currentPage + 2 <= lastPage && !visiblePages.includes(lastPage)
        ? lastPage
        : false,
  };
};

type PageButtonProps = {
  text: string;
  onClick: () => void;
  className?: string;
};
const PageButton: React.FC<PageButtonProps> = ({
  text,
  onClick,
  className = '',
}) => (
  <Button
    button={{ text, style: 'text' }}
    className={className}
    attrs={{ onClick }}
  />
);

type PaginatorProps = {
  activePage: number;
  pageContext: {
    count?: number;
    lastPage?: number;
    total?: number;
  };
  onPageUpdate: (page: number) => void;
  gridId?: any;
};
const Paginator: React.FC<PaginatorProps> = ({
  activePage,
  pageContext: { lastPage },
  onPageUpdate: updatePage,
  gridId,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { getParam, deleteParam, setParam } = useParams();
  const { hash } = useLocation();
  const updateUrl = useCallback(
    newPage => {
      if (newPage === 1) {
        hash === '' && deleteParam(gridId + 'page');
      } else {
        setParam(gridId + 'page', newPage);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onPageChange = (newPage: number) => {
    updateUrl(newPage);
    updatePage(newPage);
  };

  useEffect(() => {
    if (activePage && lastPage) {
      if (activePage > lastPage) {
        // If the active page is above the maximum number of pages, reset it to 1
        updateUrl(1);
        updatePage(1);
      } else {
        // The collection component manages the pages so we have to update the URL in the paginator
        updateUrl(activePage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, lastPage, updateUrl]);

  useEffect(() => {
    const pageFromUrl = getParam(gridId + 'page') || '';

    updatePage(parseInt(pageFromUrl, 10) || 1);
  });

  if (!activePage || !lastPage) return null;

  const { previousPage, nextPage, visiblePages, minPage, maxPage } =
    getPageData(activePage, lastPage);

  return lastPage > 1 ? (
    <div className="text-center my-5 text-grey">
      {previousPage && (
        <PageButton
          text="Previous"
          onClick={() => onPageChange(activePage - 1)}
          className="mr-6"
        />
      )}

      {minPage && (
        <>
          <PageButton text="1" onClick={() => onPageChange(1)} />
          <span className="mx-5">...</span>
        </>
      )}

      {visiblePages.map((visiblePage, index) => {
        const isMiddleChild = index === 1;

        return (
          <PageButton
            key={`page-button-${visiblePage}`}
            text={`${visiblePage}`}
            onClick={() => onPageChange(visiblePage)}
            className={cn({
              'text-ally-0': activePage === visiblePage,
              'mx-4': isMiddleChild,
            })}
          />
        );
      })}

      {maxPage && (
        <>
          <span className="mx-5">...</span>
          <PageButton
            text={`${lastPage}`}
            onClick={() => onPageChange(lastPage)}
          />
        </>
      )}

      {nextPage && (
        <PageButton
          text="Next"
          onClick={() => onPageChange(activePage + 1)}
          className="ml-6"
        />
      )}
    </div>
  ) : null;
};

export default Paginator;
