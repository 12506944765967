import React, { useEffect, useRef, useState } from 'react';

import Input from '~/components/ui/Input';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

const Search = ({
  mobileView = false,
  headerLayout,
  setSearchOpen,
}: {
  mobileView: boolean;
  headerLayout: string;
  setSearchOpen: (value: boolean) => void;
}) => {
  const [query, setQuery] = useState('');
  const ref = useRef<HTMLFormElement>(null);
  const history = useHistory();

  const commitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (query.length) {
      history.push(`/search?q=${encodeURIComponent(query)}`);
    }
  };

  useEffect(() => {
    function closeSearch(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setSearchOpen(false);
      }
    }
    document.addEventListener('mousedown', closeSearch);
    return () => {
      document.removeEventListener('mousedown', closeSearch);
    };
  }, [setSearchOpen, ref]);

  return (
    <form
      className={cn('header-search relative flex', {
        [`header-search--${headerLayout}`]: !!headerLayout,
        'flex-1 ml-2': mobileView,
      })}
      ref={ref}
    >
      <Input
        fullWidthBreakpoint="lg"
        attrs={{
          placeholder: 'Search...',
          type: 'text',
          className: cn('pl-4', {
            'w-full': mobileView,
            'md:pr-7': headerLayout === 'center',
          }),
        }}
        onValue={setQuery}
        onSubmit={commitSearch}
        icon={{
          type: 'search',
          onClick: commitSearch,
          className: 'w-6 cursor-pointer',
        }}
      />
    </form>
  );
};

export default Search;

Search.propTypes = {
  isTabletOrSmaller: PropTypes.bool,
  headerLayout: PropTypes.string,
  setSearchOpen: PropTypes.func,
};
