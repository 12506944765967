interface VideoMimeTypeConstantsInterface {
  NOT_SET: string;
  STREAM: string;
  MP4: string;
  MOV: string;
  OGG: string;
  AVI: string;
  WEBM: string;
}

interface VideoSourceConstantsInterface {
  NOT_SET: string;
  LIVE_SALE: string;
  VIDEO: string;
}

const videoMimeTypeConstants: VideoMimeTypeConstantsInterface = {
  NOT_SET: '',
  STREAM: 'application/x-mpegURL',
  MP4: 'video/mp4',
  MOV: 'video/quicktime',
  OGG: 'video/ogg',
  AVI: 'video/x-msvideo',
  WEBM: 'video/webm',
};

const videoSourceConstants: VideoSourceConstantsInterface = {
  NOT_SET: '',
  LIVE_SALE: 'liveSaleProduct',
  VIDEO: 'video',
};

export { videoMimeTypeConstants, videoSourceConstants };
