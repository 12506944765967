// these are GA groups
// that let us send events
// ONLY to the specific group
// so if we wanted to send an
// event via CS backend, we could exclude
// the event from going to GA in the frontend
export enum AnalyticGroups {
  CommentSold = 'cs', // CS Pixel events
  Shop = 'shop', // Shop Pixel events
  All = 'all',
}
