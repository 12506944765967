import { TrackerHelper } from '~/types/tracker-helper.interface';
import { TrackingEvent } from '~/types/tracking-event.enum';
import createRestClient from '~/utils/createRestClient';

const restClient = createRestClient();

const commentSoldTrackEvent = (name: TrackingEvent, data: any) => {
  switch (name) {
    case TrackingEvent.ViewProduct:
      void restClient.trackProductView(data.product_id);
      break;
    case TrackingEvent.ViewLive:
      void restClient.trackLiveSaleView(data.live_sale_id);
      break;
  }
};

const commentSoldTracker: TrackerHelper = {
  init: () => {
    //
  },
  hasBeenInitialized: () => true,
  trackEvent: commentSoldTrackEvent,
  trackPageView: () => {
    //
  },
};
export default commentSoldTracker;
