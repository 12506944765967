import React, { useEffect } from 'react';
import SEO from '~/components/meta/SEO';
import { useParams } from 'react-router-dom';
import { Container } from '~/components/layout/PageStructure';
import { LiveReplaysContainer } from '~/components/live/live-replays/LiveReplaysContainer';
import { useCustomer } from '~/context/CustomerContext';
import { parseInt } from 'lodash';
import useIsLiveHappeningWithPolling, {
  IsLiveHappening,
} from '~/hooks/useIsLiveHappeningWithPolling';
import { LoadableLOTWConnector } from '~/components/live/LoadableLOTWConnector';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';

export const LivePage = () => {
  const { id } = useParams<{ id?: string }>();
  const liveId = id ? parseInt(id) : undefined;
  const { customer } = useCustomer();
  const { isLiveHappening } = useIsLiveHappeningWithPolling();
  const currentContentGroup =
    !liveId && isLiveHappening === IsLiveHappening.Yes
      ? ContentGroups.Live
      : ContentGroups.Replay;
  useEffect(() => {
    if (isLiveHappening === IsLiveHappening.Unknown) return;

    webstoreTracker.tagGroup(currentContentGroup);
  }, [currentContentGroup, liveId, isLiveHappening]);

  if (!customer) return null; // Shouldn't be possible here

  return (
    <>
      <SEO title="Live" description="CommentSold live stream" />

      <Container>
        <div className={'my-6'}>
          {/*
            StreamViewer will automatically connect to the first replay if a live isn't happening.
            Then, if a live starts happening, it won't connect to that because a replay is already
            going. So, if I want StreamViewer to automatically connect to the live that has started,
            I need to remount the component so it will cancel what it's doing (playing a replay)
            and look for a live to connect to. So, this funny bizness with the key prop is a bit
            of a hack but a simple one.
          */}
          <LoadableLOTWConnector streamId={liveId} key={isLiveHappening} />
        </div>

        <LiveReplaysContainer />
      </Container>
    </>
  );
};
