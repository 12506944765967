import { Helmet } from 'react-helmet-async';
import React from 'react';

const PageStructuredData = ({ url, title }: { url: string; title: string }) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': url,
              name: title,
            },
          },
        ],
      })}
    </script>
  </Helmet>
);

export default PageStructuredData;
