import React from 'react';
import { HelmetServerSafe } from '~/components/meta/HelmetServerSafe';
import { ShopInfo, useSiteContext } from '~/context/SiteContext';
const AppLinks = ({ url }: { url: string }) => {
  const { featureFlags, shopInfo } = useSiteContext();
  const properties = [
    ...getIOSProperties(
      featureFlags?.mobileApp?.appLinks?.ios?.enabled ?? false,
      shopInfo,
      url,
    ),
    ...getAndroidProperties(
      featureFlags?.mobileApp?.appLinks?.android?.enabled ?? false,
      shopInfo,
      url,
    ),
  ];
  return <HelmetServerSafe meta={properties} />;
};

const getIOSProperties = (
  isEnabled: boolean,
  shopInfo: ShopInfo,
  url: string,
) => {
  if (!isEnabled || !shopInfo.appleId) {
    return [];
  }

  return [
    {
      property: 'al:ios:url',
      content: shopInfo.mobileAppSchemaPrefix + url,
    },
    {
      property: 'al:ios:app_store_id',
      content: shopInfo.appleId,
    },
    {
      property: 'al:ios:app_name',
      content: shopInfo.mobileAppDisplayName,
    },
  ];
};

const getAndroidProperties = (
  isEnabled: boolean,
  shopInfo: ShopInfo,
  url: string,
) => {
  if (!isEnabled || !shopInfo.androidPackage) {
    return [];
  }

  return [
    {
      property: 'al:android:url',
      content: shopInfo.mobileAppSchemaPrefix + url,
    },
    {
      property: 'al:android:app_name',
      content: shopInfo.mobileAppDisplayName,
    },
    {
      property: 'al:android:package',
      content: shopInfo.androidPackage,
    },
  ];
};

export default AppLinks;
