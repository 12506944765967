import React, { useEffect, useRef } from 'react';
const FilterModal: React.FC<{
  title: string;
  open: boolean;
  onRequestClose: () => void;
  children: any;
}> = ({ title, open, onRequestClose, children }) => {
  useEffect(() => {
    if (open) {
      document.body.setAttribute('style', 'overflow: hidden;');
    } else {
      document.body.removeAttribute('style');
    }
  }, [open]);
  useEffect(() => {
    window.addEventListener('keydown', closeModalByEsc);

    return () => {
      window.removeEventListener('keydown', closeModalByEsc);
    };
  });
  const closeModalByEsc = (e: any) => {
    if (e.keyCode === 27) onRequestClose();
  };
  return (
    <CustomModalLayout
      open={open}
      title={title}
      onRequestClose={onRequestClose}
    >
      {children}
    </CustomModalLayout>
  );
};
export const CustomModalLayout: React.FC<{
  title: string;
  open: boolean;
  onRequestClose: () => void;
  children: any;
}> = ({ onRequestClose, title, children, open }) => {
  const ref: any = useRef();
  useOnClickOutside(ref, onRequestClose);

  return (
    <>
      {open && (
        <div className="modal-layout">
          <div className="modal" ref={ref}>
            {title && <div>{title}</div>}
            <div className="modal-content">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export const useOnClickOutside = (node: any, handler: () => void) => {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!node.current) return;
      if (node.current.contains(e.target)) return;
      if (handlerRef.current) {
        handlerRef.current();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [node]);
};

export default FilterModal;
