import cn from 'classnames';
import Link from '~/components/ui/Link';
import React, { useEffect, useRef, useState } from 'react';
import { GenericLinkWithChildren } from '~/context/SiteContext';
import useWindowSize from '~/hooks/useWindowSize';
import { deepCopy } from '~/utils/deepCopy';

const navSizes = ['max-h-60', 'max-h-72', 'max-h-96'];
export const ShopAllNav = ({
  link,
  tabOutHandler,
  expanded,
}: {
  link: GenericLinkWithChildren;
  tabOutHandler: (e: any) => void;
  expanded: boolean;
}) => {
  const [isNavBuilt, setIsNavBuilt] = useState(false);
  const navRefs = useRef<Array<HTMLLIElement | null>>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [renderedLinks, setRenderedLinks] = useState<GenericLinkWithChildren>(
    deepCopy(link),
  );
  const [navSize, setNavSize] = useState<string>(navSizes[0]);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const totalLinks =
    renderedLinks.links.reduce((count, link) => count + link.links.length, 0) +
    renderedLinks.links.length;
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (windowWidth > 0) {
      // rebuild the nav on window resize
      setRenderedLinks(deepCopy(link));
      setIsNavBuilt(false);
    }
  }, [windowWidth, link]);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    // in this effect we:
    // loop through all the links + child links to
    //     a. check and see if the 'top' is less than the last item offset
    //        (meaning we go to the next column)
    //     b. when that happens we set `shouldBreak` which will add the
    //        parent category above it.  Then we break from the use effect
    //        by updating the renderedLinks (triggering a render)
    //     c. We repeat this until all the columns have been checked, since
    //        everytime we add the parent category this pushes all the items back
    //     d. Once each column is handled, we set isNavBuilt to true, stopping the recursion
    //     e. If the menu is under 40 items we also need to check if its overflowing because, we are setting
    //        the columns property to auto (top to bottom, left to right).  Anything over 40 is automatically evened ou
    //        and the height of the nav is dynamic.
    //     f. One other thing we do is push Heading categories that are cut off to the next column at the first child
    //        (meaning they don't have any children under them, because they are all in the next colum), to the next column.
    if (
      !expanded ||
      navRefs.current.length < 1 ||
      isNavBuilt ||
      !containerRef.current
    ) {
      return;
    }
    let lastYPos = 0;

    const updatedLinks = { ...renderedLinks };
    let isNavReady = true;
    navRefs.current.forEach((ref, key) => {
      if (!ref || key === 0) {
        return;
      }

      const currentDimensions = ref.getClientRects()[0];

      if (lastYPos > currentDimensions.top) {
        for (let i = 0; i < updatedLinks.links.length; i++) {
          if (
            updatedLinks.links[i].id === ref.getAttribute('data-title') ||
            !isNavReady
          ) {
            break;
          }
          for (let j = 0; j < updatedLinks.links[i].links.length; j++) {
            if (
              updatedLinks.links[i].links[j].id ===
              ref.getAttribute('data-title')
            ) {
              if (j === 0 && !updatedLinks.links[i].shouldBreak) {
                // make sure the header breaks to next column
                // if we don't have any l3's under it
                updatedLinks.links[i].shouldBreak = true;
                isNavReady = false;
              } else if (!updatedLinks.links[i].links[j].shouldBreak) {
                updatedLinks.links[i].links[j].shouldBreak = true;
                isNavReady = false;
              }

              break;
            }
            if (!isNavReady) {
              break;
            }
          }
        }
      }
      lastYPos = currentDimensions.top;
    });

    setRenderedLinks(updatedLinks);
    if (isNavReady) {
      const isNavOverflowing =
        (navRefs.current[navRefs.current.length - 1]?.getClientRects()[0]
          .left ?? 0) > containerRef.current.getClientRects()[0].right;
      if (isNavOverflowing) {
        setIsOverflowing(true);
        setRenderedLinks(deepCopy(link));
        return;
      }

      setIsNavBuilt(true);
    }
  }, [
    expanded,
    isNavBuilt,
    setIsNavBuilt,
    navRefs,
    renderedLinks,
    navSize,
    setNavSize,
    totalLinks,
    link,
  ]);

  return (
    <div className="pb-10 container">
      <div
        className={cn('nav_item__shop-all-menu', {
          'column-auto max-h-48': totalLinks < 41 && !isOverflowing,
        })}
        ref={containerRef}
      >
        {renderedLinks.links.map((childlink, i) => (
          <ul
            className={cn('px-3 mb-6', {
              invisible: !isNavBuilt,
            })}
            key={`${link.id}-shop-all-child-${childlink.id}`}
          >
            <li
              className={cn('', {
                'should-break': !!childlink.shouldBreak,
              })}
              ref={(el: any) => navRefs.current.push(el)}
              data-title={`${childlink.id}`}
            >
              <Link
                to={childlink.uri}
                className="text-nav-lg uppercase w-100"
                onKeyDown={e => {
                  if (tabOutHandler) {
                    tabOutHandler(e);
                  }
                }}
              >
                {childlink.title}
              </Link>
            </li>
            {childlink.links.map((grandchildlink, j) => (
              <React.Fragment
                key={`shop-all-nav-grandchild-${grandchildlink.id}`}
              >
                {grandchildlink.shouldBreak && (
                  <li className="should-break" data-title={`${childlink.id}`}>
                    <Link
                      to={childlink.uri}
                      className="text-nav-lg uppercase w-100"
                      onKeyDown={e => {
                        if (tabOutHandler) {
                          tabOutHandler(e);
                        }
                      }}
                    >
                      {childlink.title}
                    </Link>
                  </li>
                )}
                <li
                  ref={(el: any) => navRefs.current.push(el)}
                  data-title={grandchildlink.id}
                >
                  <Link
                    to={grandchildlink.uri}
                    className={
                      grandchildlink.links.length
                        ? `text-nav-lg uppercase w-100 pl-2`
                        : `text-tiny`
                    }
                    onKeyDown={e => {
                      if (tabOutHandler) {
                        tabOutHandler(e);
                      }
                    }}
                  >
                    {grandchildlink.title}
                  </Link>
                </li>
              </React.Fragment>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};
