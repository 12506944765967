import React, { SyntheticEvent } from 'react';
import imagePlaceholder from '../../assets/image-placeholder.png';
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from 'react-lazy-load-image-component';

interface ImageWithFallbackProps extends LazyLoadImageProps {
  fallbackSrc?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  fallbackSrc,
  ...props
}) => {
  return (
    <LazyLoadImage
      {...props}
      src={props.src || fallbackSrc || imagePlaceholder}
      alt={props.alt}
      onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = fallbackSrc || imagePlaceholder;
      }}
    />
  );
};

export { ImageWithFallback };
