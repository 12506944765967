import { Helmet } from 'react-helmet-async';
import React from 'react';

const GlobalStructuredData = ({ name, url }: { name: string; url: string }) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        name,
        url,
      })}
    </script>
  </Helmet>
);

export default GlobalStructuredData;
