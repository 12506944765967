import React from 'react';
import { useLocation } from 'react-router-dom';

import { HelmetServerSafe } from '~/components/meta/HelmetServerSafe';
import { locationIsHomeRoute } from '~/helpers/route.helpers';
import { htmlToText } from '~/utils/helpers';
import useShopInfo from '~/hooks/live/useShopInfo';

/**
 * SEO Component
 */
type SEOPropTypes = {
  description?: string;
  keywords?: string[];
  title: string;
  image?: string;
  pageTitle?: string;
};
const SEO: React.FC<SEOPropTypes> = ({
  description,
  keywords,
  title,
  image,
  pageTitle,
}) => {
  const { shopInfo } = useShopInfo();
  const location = useLocation();

  const isHomeRoute = locationIsHomeRoute(location);
  const shopName = pageTitle || shopInfo?.homepageTitle || shopInfo?.name;
  let titleTemplate = '%s';
  if (!isHomeRoute && shopName) {
    titleTemplate += ` | ${shopName}`;
  }

  const metaDescription = htmlToText(
    description ||
      shopInfo?.homepageDescription ||
      shopInfo?.metaDescription ||
      shopInfo?.description,
  );
  const metaTitle = title || shopInfo?.metaName || shopInfo?.name;
  const metaKeywords = keywords?.length ? keywords : shopInfo?.keywords || [];
  const socialImage = image || shopInfo?.shopLogo;

  const metaAttributes = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: shopInfo?.name,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    socialImage && {
      property: `og:image`,
      content: socialImage,
    },
    socialImage && {
      name: `twitter:image:src`,
      content: socialImage,
    },
    metaKeywords.length && {
      name: `keywords`,
      content: metaKeywords.join(`, `),
    },
  ].filter(i => i);

  return (
    <HelmetServerSafe
      htmlAttributes={{
        language: 'en',
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={metaAttributes}
    />
  );
};

export default SEO;
