import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import { TrackerHelper } from '~/types/tracker-helper.interface';
import { TrackingEvent } from '~/types/tracking-event.enum';
import { AnalyticGroups } from '~/types/analytic-groups.enum';

declare global {
  interface Window {
    ga: any;
    gtag: any;
  }
}

window.ga = window.ga || undefined;
window.gtag = window.gtag || undefined;

const hasBeenInit = true;
let hasGA4BeenInit = false;
let hasGABeenInit = false;

// these events we want to track server side
// for the CS GA pixel
// so only send these on the frontend to shops
const shopOnlyEvents = [
  TrackingEvent.AddToCart,
  TrackingEvent.ViewContent,
  TrackingEvent.ViewProduct,
];

const initializeGoogleAnalytics = (
  analyticsId: string,
  config?: any,
  params?: any,
): void => {
  if (!analyticsId) {
    return;
  }
  if (googleAnalyticsKeyIsV4(analyticsId)) {
    if (hasGA4BeenInit) {
      ReactGA4.reset();
    }
    ReactGA4.initialize(analyticsId, {
      gaOptions: config,
      gtagOptions: params,
    });
    hasGA4BeenInit = true;
    return;
  }
  ReactGA.initialize(analyticsId, { gaOptions: config });
  hasGABeenInit = true;
};

const googleAnalyticsKeyIsV4 = (analyticsId: string): boolean =>
  !!analyticsId?.startsWith('G-');

const googleAnalyticsHasBeenInitialized = (): boolean =>
  (typeof window.ga === 'function' || typeof window.gtag === 'function') &&
  hasBeenInit;

const googleAnalyticsTrackPageview = (path: string) => {
  if (googleAnalyticsHasBeenInitialized()) {
    if (hasGA4BeenInit) {
      ReactGA4.send('pageview');
    }
    if (hasGABeenInit) {
      ReactGA.pageview(path);
    }
  }
};

const googleAnalyticsTrackEvent = (name: TrackingEvent, data: any) => {
  if (googleAnalyticsHasBeenInitialized()) {
    const eventPayload = {
      // To quiet the console logs angry about missing args
      action: 'CommentSold Event',
      category: 'CommentSold Event',
      label: name,
      ...data,
    };
    if (shopOnlyEvents.includes(name)) {
      eventPayload.send_to = AnalyticGroups.Shop;
    }
    if (!eventPayload.send_to) {
      // all events MUST have a send_to group attribute
      eventPayload.send_to = AnalyticGroups.All;
    }
    if (hasGA4BeenInit) {
      // using the ReactGA4 library limits the payload
      // stripping out the send_to, so using the underlying gtag function
      window.gtag('event', 'CommentSold Event', eventPayload);
    }
    if (hasGABeenInit) {
      ReactGA.event(eventPayload);
    }
  }
};

const googleAnalyticsTracker: TrackerHelper = {
  init: initializeGoogleAnalytics,
  hasBeenInitialized: googleAnalyticsHasBeenInitialized,
  trackEvent: googleAnalyticsTrackEvent,
  trackPageView: googleAnalyticsTrackPageview,
};
export default googleAnalyticsTracker;
