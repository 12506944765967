import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { WebstoreSkeletonTheme } from '~/components/ui/organisms/WebstoreSkeletonTheme';

export const PageSkeletonLoader: React.FC<{ ready: boolean }> = ({
  ready,
  children,
}) => {
  if (ready) {
    return <>{children}</>;
  }

  return (
    <WebstoreSkeletonTheme>
      <div className="m-auto w-1/5 mt-8">
        <Skeleton style={{ height: '30px' }} />
      </div>
      <div className="container mt-7">
        <div className="container grid grid-cols-4 md:grid-cols-12 gap-4 md:gap-5">
          <div className="rte mb-9 col-start-1 col-span-4 md:col-start-3 md:col-span-8 content-page-markup__wrapper">
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" />
            <Skeleton style={{ width: '50%' }} className="mb-6" />
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" />
            <Skeleton className="mb-3" style={{ width: '70%' }} />
          </div>
        </div>
      </div>
    </WebstoreSkeletonTheme>
  );
};
