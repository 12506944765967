import { Container } from '~/components/layout/PageStructure';
import ProductSlider from '~/components/product/ProductSlider';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const RelatedProducts = ({ data = {}, className }) => {
  const { title, description, products } = data;

  return (
    <div className={cn('my-8', className)}>
      <Container className="mb-6">
        <h2 className="text-h4 lg:text-h4-lg">{title}</h2>
        <p>{description}</p>
      </Container>
      <ProductSlider products={shuffleArray(products).slice(-6)} />
    </div>
  );
};

RelatedProducts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    products: PropTypes.array.isRequired,
  }),
};

function shuffleArray(array) {
  let index = array.length;
  while (index--) {
    const randomIndex = Math.floor(Math.random() * index);
    [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
  }
  return array;
}

export default RelatedProducts;
