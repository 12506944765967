import { TrackerHelper } from '~/types/tracker-helper.interface';
import { TrackingEvent } from '~/types/tracking-event.enum';

declare global {
  interface Window {
    ttq: any;
    TiktokAnalyticsObject: any;
  }
}

window.ttq = window.ttq || [];
window.TiktokAnalyticsObject = window.TiktokAnalyticsObject || undefined;

let hasBeenInit = false;

const initializeTiktok = (apiKey: string) => {
  if (!apiKey || hasBeenInit) {
    return;
  }

  injectTiktokEmbed(window, document, apiKey);
  hasBeenInit = true;
};

/**
 * TS conversion for standard tiktok pixel embed: https://www.tiktokforbusinesseurope.com/resources/install-tiktok-pixel
 * @param w {Window}
 * @param d {HTMLDocument}
 * @param apiKey {string}
 */
const injectTiktokEmbed = (w: Window, d: Document, apiKey: string) => {
  w.TiktokAnalyticsObject = 'ttq';

  const ttq = (w.ttq = w.ttq || []);

  ttq.methods = [
    'page',
    'track',
    'identify',
    'instances',
    'debug',
    'on',
    'off',
    'once',
    'ready',
    'alias',
    'group',
    'enableCookie',
    'disableCookie',
  ];

  ttq.setAndDefer = function (t: any, e: any) {
    t[e] = function () {
      // eslint-disable-next-line prefer-rest-params
      t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
    };
  };

  for (let i = 0; i < ttq.methods.length; i++)
    ttq.setAndDefer(ttq, ttq.methods[i]);

  ttq.instance = function (t: any) {
    const e = ttq._i[t] || [];
    let n = 0;
    for (; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
    return e;
  };

  ttq.load = function (e: any, n: any) {
    const i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
    ttq._i = ttq._i || {};
    ttq._i[e] = [];
    ttq._i[e]._u = i;
    ttq._t = ttq._t || {};
    ttq._t[e] = +new Date();
    ttq._o = ttq._o || {};
    ttq._o[e] = n || {};

    const o = document.createElement('script');
    o.type = 'text/javascript';
    o.async = !0;
    o.src = `${i}?sdkid=${e}&lib=ttq`;

    const a = document.getElementsByTagName('script')[0];
    if (a && a.parentNode) {
      a.parentNode.insertBefore(o, a);
    }
  };

  ttq.load(apiKey, {});
  ttq.page();
};

const tiktokHasBeenInitialized = (): boolean =>
  typeof window.ttq === 'object' && hasBeenInit;

const tiktokTrackEvent = (name: TrackingEvent, data: any, eventData?: any) => {
  if (tiktokHasBeenInitialized()) {
    let eventPayload = { ...data, eventData };
    if (name === TrackingEvent.AddToCart) {
      eventPayload = {
        content_type: data.content_type,
        content_id: data.content_ids[0],
        currency: data.currency,
        value: data.value,
      };
    }
    window.ttq.track(name, eventPayload);
  }
};

const tiktokTrackPageView = () => {
  if (tiktokHasBeenInitialized()) {
    window.ttq.track('Browse');
  }
};

const tiktokTracker: TrackerHelper = {
  init: initializeTiktok,
  hasBeenInitialized: tiktokHasBeenInitialized,
  trackEvent: tiktokTrackEvent,
  trackPageView: tiktokTrackPageView,
};
export default tiktokTracker;
