import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getMobileMenus from '~/utils/mobileMenus';
import { MobileMenu } from '~/types/MobileMenu';
const data: any = {
  id: '40c404c4-de4f-44bd-b703-3fccef826bc5',
  uri: '/categories/shop_all',
  url: 'shop_all',
  isApp: true,
  isWeb: true,
  links: [
    {
      id: 'category-127',
      uri: '/categories/127/clothing',
      links: [
        {
          id: 'category-174',
          uri: '/categories/174/shirts-tops',
          links: [],
          title: 'Shirts & Tops',
          productCount: 3289,
        },
        {
          id: 'category-160',
          uri: '/categories/160/dresses',
          links: [],
          title: 'Dresses',
          productCount: 943,
        },
        {
          id: 'category-173',
          uri: '/categories/173/pants',
          links: [],
          title: 'Pants',
          productCount: 396,
        },
        {
          id: 'category-175',
          uri: '/categories/175/shorts',
          links: [],
          title: 'Shorts',
          productCount: 281,
        },
        {
          id: 'category-165',
          uri: '/categories/165/outerwear',
          links: [],
          title: 'Outerwear',
          productCount: 217,
        },
        {
          id: 'category-161',
          uri: '/categories/161/one-pieces',
          links: [],
          title: 'One-Pieces',
          productCount: 164,
        },
        {
          id: 'category-190',
          uri: '/categories/190/swimwear',
          links: [],
          title: 'Swimwear',
          productCount: 92,
        },
        {
          id: 'category-181',
          uri: '/categories/181/sleepwear-loungewear',
          links: [],
          title: 'Sleepwear & Loungewear',
          productCount: 42,
        },
        {
          id: 'category-128',
          uri: '/categories/128/activewear',
          links: [],
          title: 'Activewear',
          productCount: 15,
        },
        {
          id: 'category-210',
          uri: '/categories/210/underwear-socks',
          links: [],
          title: 'Underwear & Socks',
          productCount: 9,
        },
      ],
      title: 'Clothing',
      productCount: 5464,
    },
    {
      id: 'category-344',
      uri: '/categories/344/jewelry',
      links: [
        {
          id: 'category-350',
          uri: '/categories/350/earrings',
          links: [],
          title: 'Earrings',
          productCount: 289,
        },
        {
          id: 'category-352',
          uri: '/categories/352/necklaces',
          links: [],
          title: 'Necklaces',
          productCount: 85,
        },
        {
          id: 'category-347',
          uri: '/categories/347/bracelets',
          links: [],
          title: 'Bracelets',
          productCount: 47,
        },
        {
          id: 'category-353',
          uri: '/categories/353/rings',
          links: [],
          title: 'Rings',
          productCount: 1,
        },
      ],
      title: 'Jewelry',
      productCount: 425,
    },
    {
      id: 'category-365',
      uri: '/categories/365/shoes',
      links: [],
      title: 'Shoes',
      productCount: 264,
    },
    {
      id: 'category-339',
      uri: '/categories/339/handbags-wallets-cases',
      links: [
        {
          id: 'category-342',
          uri: '/categories/342/handbags',
          links: [],
          title: 'Handbags',
          productCount: 108,
        },
      ],
      title: 'Handbags, Wallets & Cases',
      productCount: 108,
    },
    {
      id: 'category-2803',
      uri: '/categories/2803/personal-care',
      links: [
        {
          id: 'category-2806',
          uri: '/categories/2806/cosmetics',
          links: [],
          title: 'Cosmetics',
          productCount: 76,
        },
        {
          id: 'category-2984',
          uri: '/categories/2984/oral-care',
          links: [],
          title: 'Oral Care',
          productCount: 14,
        },
        {
          id: 'category-2946',
          uri: '/categories/2946/hair-care',
          links: [],
          title: 'Hair Care',
          productCount: 6,
        },
      ],
      title: 'Personal Care',
      productCount: 99,
    },
    {
      id: 'category-277',
      uri: '/categories/277/hair-accessories',
      links: [],
      title: 'Hair Accessories',
      productCount: 48,
    },
    {
      id: 'category-297',
      uri: '/categories/297/hats',
      links: [],
      title: 'Hats',
      productCount: 34,
    },
    {
      id: 'category-312',
      uri: '/categories/312/sunglasses',
      links: [],
      title: 'Sunglasses',
      productCount: 33,
    },
    {
      id: 'category-3791',
      uri: '/categories/3791/tableware',
      links: [],
      title: 'Tableware',
      productCount: 20,
    },
    {
      id: 'category-3085',
      uri: '/categories/3085/decor',
      links: [
        {
          id: 'category-3147',
          uri: '/categories/3147/home-fragrances',
          links: [],
          title: 'Home Fragrances',
          productCount: 6,
        },
        {
          id: 'category-3142',
          uri: '/categories/3142/home-fragrance-accessories',
          links: [],
          title: 'Home Fragrance Accessories',
          productCount: 4,
        },
      ],
      title: 'Decor',
      productCount: 10,
    },
    {
      id: 'category-268',
      uri: '/categories/268/belts',
      links: [],
      title: 'Belts',
      productCount: 8,
    },
    {
      id: 'category-4096',
      uri: '/categories/4096/luggage-accessories',
      links: [
        {
          id: 'category-4104',
          uri: '/categories/4104/travel-pouches',
          links: [],
          title: 'Travel Pouches',
          productCount: 2,
        },
        {
          id: 'category-4100',
          uri: '/categories/4100/luggage-straps',
          links: [],
          title: 'Luggage Straps',
          productCount: 1,
        },
      ],
      title: 'Luggage Accessories',
      productCount: 3,
    },
    {
      id: 'category-3075',
      uri: '/categories/3075/business-home-security',
      links: [
        {
          id: 'category-3077',
          uri: '/categories/3077/home-alarm-systems',
          links: [],
          title: 'Home Alarm Systems',
          productCount: 1,
        },
      ],
      title: 'Business & Home Security',
      productCount: 1,
    },
  ],
  title: 'Shop All',
  is_shop_all: true,
  menuLinkType: 'Shop All',
};
const ShopAll = () => {
  const [shopAllMenus, setShopAllMenus] = useState<any[]>([]);
  const history = useHistory();
  const handleNavigate = (pathEndPoint: string) => {
    history.push(pathEndPoint);
  };
  useEffect(() => {
    const newShopAllMenu: MobileMenu[] = getMobileMenus(data);
    setShopAllMenus(newShopAllMenu);
  }, []);
  return (
    <div>
      {shopAllMenus?.map((item: any, ind: number) => {
        return (
          <div key={ind + 1}>
            <button
              onClick={() => {
                handleNavigate(item.uri);
              }}
              style={{ padding: '20px', width: '100%', textAlign: 'left' }}
            >
              {item.title}
            </button>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default ShopAll;
