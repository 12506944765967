import { Container } from '~/components/layout/PageStructure';
import Link from '~/components/ui/Link';
import React from 'react';

type AnnouncementBannerProps = {
  data?: {
    text?: string;
    uri: string;
    startDate?: string;
    endDate?: string;
    useSchedule?: boolean;
  };
};

const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({ data }) => {
  if (!data || !data?.text) return null;

  const { text, uri, startDate, endDate, useSchedule } = data;

  if (startDate && endDate && useSchedule) {
    const now = new Date();
    const currentDateAndTime = now.toISOString();

    if (currentDateAndTime < startDate || currentDateAndTime >= endDate) {
      return null;
    }
  }

  return (
    <div className="py-3 bg-main-3 text-ally-3 text-center text-tiny md:text-tiny-lg">
      <Container>
        <p>
          {uri && <Link to={uri}>{text}</Link>}
          {!uri && text}
        </p>
      </Container>
    </div>
  );
};

export default AnnouncementBanner;
