import { Helmet } from 'react-helmet-async';
import React from 'react';
import { htmlToText } from '~/utils/helpers';
import { Product } from '~/types/Product';

const ProductStructuredData = ({
  product,
  meta,
  location,
}: {
  product: Product;
  meta: { shopName: string };
  location: { origin: string; pathname: string; href: string };
}) => {
  const { shopName } = meta;

  const offers = product.variants?.map(variant => {
    const isWaitlist = variant.quantity === 0;
    const { priceCents, salePriceCents } = variant;
    const p = salePriceCents ? salePriceCents : priceCents;
    const price = Intl.NumberFormat(undefined, {
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(p / 100);

    return {
      '@type': 'Offer',
      priceCurrency: 'USD',
      price,
      availability: `http://schema.org/${
        isWaitlist ? 'OutOfStock' : 'InStock'
      }`,
      url: `${location.origin}${location.pathname}?variant=${variant.id}`,
      seller: {
        '@type': 'Organization',
        name: shopName,
      },
      inventoryLevel: variant.quantity,
    };
  });

  const structuredData = Object.assign(
    {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: product.name,
      image: product.images[0]?.url,
      productId: product.id,
      offers,
    },
    product.storeDescription && {
      description: htmlToText(product.storeDescription),
    },
    product.brand && {
      brand: {
        '@type': 'Thing',
        name: product.brand,
      },
    },
    product.sku && {
      sku: product.sku,
    },
  );

  const collections = product.collections;
  const breadcrumbsStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: collections?.length
      ? [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': `${location.origin}`,
              name: 'Home',
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': `${location.origin}/collections/${collections[0]}`,
              name: collections[0].title,
            },
          },
          {
            '@type': 'ListItem',
            position: 3,
            item: {
              '@id': location.href,
              name: product.name,
            },
          },
        ]
      : [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': `${location.origin}`,
              name: 'Home',
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            passing: '4',
            item: {
              '@id': location.href,
              name: product.name,
            },
          },
        ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbsStructuredData)}
      </script>
    </Helmet>
  );
};

export default ProductStructuredData;
