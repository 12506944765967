import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import client from '~/utils/client';
import { find } from 'lodash';
import useParams from '~/hooks/useParams';
import { useTracking } from '~/context/TrackingContext';
import { TrackingEvent } from '~/types/tracking-event.enum';
import { useAppSelector } from '~/store/hooks';
import { selectInfluencer } from '~/features/Influencer/influencerSlice';
import {
  buildExperience,
  buildSession,
} from '~/context/helpers/CartContext.helpers';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';

interface addVariantToCartParams {
  variant: {
    id: string;
    quantity: number;
    product: {
      id: number;
      name: string;
    };
    priceCents: number;
  };
  quantity: number;
  isGiftCard: boolean;
  liveId?: number;
  isLiveReplay?: boolean;
}

interface CartContextType extends Record<string, any> {
  addVariantToCart: (params: addVariantToCartParams) => Promise<any>;
  wasLastItemWaitlisted: boolean;
}

const CartContext = createContext<CartContextType>({} as CartContextType);

const initialCartState = {
  cart: null,
  loaded: false,
  notice: null,
};

const CartProvider: FC = ({ children }) => {
  const [cart, setCart] = useState(initialCartState);
  const [miniCartOpen, setMiniCartOpen] = useState(false);
  const [wasLastItemWaitlisted, setWasLastItemWaitlisted] = useState(false);
  const { id: influencerId } = useAppSelector(selectInfluencer);
  const { getParam } = useParams();
  const { trackEvent } = useTracking();

  const loadCart = useCallback(() => {
    setCart(prevState => {
      return {
        ...prevState,
        loaded: false,
      };
    });

    client.getCart().then(response => {
      setCart(prevState => {
        return {
          ...prevState,
          cart: response,
          loaded: true,
        };
      });
    });
  }, []);

  // Initial load
  useEffect(() => {
    loadCart();
  }, [loadCart]);

  const addVariantToCart = async ({
    variant,
    quantity = 1,
    isGiftCard,
    liveId,
    isLiveReplay,
  }: addVariantToCartParams) => {
    const experienceFromParam = getParam('experience');
    const sessionSourceFromParam = getParam('session_source');
    const isFromTikTok = getParam('source-tiktok') !== null;

    // Bail if no variant ID or quantity
    if (!variant?.id || !quantity) {
      return;
    }

    const { sessionSource, sessionSourceId } = buildSession({
      influencerId,
      isFromTikTok,
      sessionSourceFromParam,
    });
    const { experience, experienceId } = buildExperience({
      isLiveReplay,
      liveId,
      isFromTikTok,
      experienceFromParam,
    });
    const willAddToWaitlist = variant.quantity === 0 && !isGiftCard;

    webstoreTracker.track({
      eventName: 'webstore_add_to_cart_attempted',
      eventDetails: {
        variantId: variant.id,
        productId: variant.product.id,
      },
    });

    return client
      .addToCart({
        id: variant.id,
        // Unnecessarily safe after we fix types everywhere
        quantity: parseInt(String(quantity), 10),
        sessionSource,
        sessionSourceId,
        experience,
        experienceId,
      })
      .then(fullResponse => {
        webstoreTracker.track({
          eventName: 'webstore_add_to_cart_success',
          eventDetails: {
            variantId: variant.id,
            productId: variant.product.id,
          },
        });

        if (isFromTikTok) {
          window.location.href = '/checkout';
          return;
        }

        const headers = fullResponse.headers;
        const response = fullResponse.data.data.addToCart;
        let notice = 'An item was added to your cart.';

        // Get added item from full cart response based on variant options
        if (response?.items) {
          const addedItem = find(response.items, {
            variant: {
              id: variant.id,
            },
          });

          // Set added product's name for feedback to customer
          if (addedItem && !willAddToWaitlist) {
            notice = `${addedItem.variant.product.name} was added to your cart.`;
          }
        }

        // Update state with new cart
        setCart((prevState: any) => {
          return {
            ...prevState,
            cart: response,
            notice,
          };
        });

        // If waitlisting, show a little message, otherwise open the mini cart
        if (willAddToWaitlist) {
          setWasLastItemWaitlisted(true);
        } else {
          setWasLastItemWaitlisted(false);
          toggleMiniCart(true);
        }

        trackEvent(
          TrackingEvent.AddToCart,
          {
            content_ids: [variant.product.id],
            content_type: 'product',
            value: variant.priceCents / 100,
            currency: 'USD',
          },
          {
            eventID: headers['x-request-id'],
          },
        );

        return fullResponse;
      });
  };

  /**
   * Remove item from cart or waitlist
   * @param lineItemID
   * @param quantity
   * @param status
   * @returns {any}
   */
  const removeLineItem = (lineItemID: any, quantity: any, status = '') => {
    const methodName =
      status === 'WAITLIST' ? 'removeFromWaitlist' : 'removeFromCart';

    return client[methodName](lineItemID, quantity).then(response => {
      // Update state with new cart + waitlist info
      setCart(prevState => {
        return {
          ...prevState,
          cart: response,
        };
      });
    });
  };

  /**
   * Update item
   *
   * @param {string} lineItemID ID of the item to update
   * @param {*} quantity Quantity to update to
   */
  const updateLineItem = (lineItemID: any, quantity: any) => {
    return client
      .updateCart(lineItemID, parseInt(quantity, 10))
      .then(response => {
        // Update state with new cart
        // eslint-disable-next-line sonarjs/no-identical-functions
        setCart(prevState => {
          return {
            ...prevState,
            cart: response,
          };
        });

        toggleMiniCart(true);
      });
  };

  /**
   * Toggle MiniCart visibility
   *
   * @param state - forces a visible (true) or hidden (false) state
   */
  const toggleMiniCart = (state: any) => {
    let newMiniCartState;

    if (state !== undefined) {
      newMiniCartState = state;
    } else {
      newMiniCartState = !miniCartOpen;
    }

    if (window.location.pathname.startsWith('/cart')) {
      setMiniCartOpen(false);
    } else {
      setMiniCartOpen(newMiniCartState);
    }

    if (!state) {
      setTimeout(() => {
        setCart(prevState => {
          return {
            ...prevState,
            notice: null,
          };
        });
      }, 300);
    }
  };

  return (
    <CartContext.Provider
      value={{
        ...cart,
        setCart,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        miniCartOpen,
        toggleMiniCart,
        wasLastItemWaitlisted,
        loadCart,
        isLoaded: cart.loaded,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCart() {
  return useContext(CartContext);
}

export { CartProvider, useCart };
