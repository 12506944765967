import React, { lazy, Suspense } from 'react';
import { VideoPlayerPropsInferface } from '~/components/ui/VideoPlayer';

const VideoPlayer = lazy(() =>
  import('~/components/ui/VideoPlayer').then(({ VideoPlayer }) => ({
    default: VideoPlayer,
  })),
);

export const LoadableVideoPlayer = (props: VideoPlayerPropsInferface) => {
  return (
    <Suspense fallback={<span />}>
      <VideoPlayer {...props} />
    </Suspense>
  );
};
