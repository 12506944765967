import 'what-input';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Footer from '~/components/layout/Footer';
import GridGuide from '~/components/ui/GridGuide';
import Header from '~/components/layout/Header/Header';
import LayoutHooks from '~/components/layout/LayoutHooks';
import { useSiteContext } from '~/context/SiteContext';
import routes from '~/utils/routes';
import { useTracking } from '~/context/TrackingContext';

const Layout = () => {
  const { globals } = useSiteContext();
  const { trackPageView, isInitialize } = useTracking();
  const location = useLocation();

  useEffect(() => {
    if (isInitialize) trackPageView(location.pathname, location.search);
  }, [location.pathname, location.search, trackPageView, isInitialize]);

  return (
    <div className="site-wrapper">
      <Header globals={globals} />

      <main className="relative flex-1">
        <LayoutHooks />
        <Switch>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
        </Switch>
      </main>

      <Footer globals={globals} />
      <GridGuide />
    </div>
  );
};
export default Layout;
