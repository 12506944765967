import landscapeHuge from '~/assets/landscapeHuge.jpg';
import landscapeLarge from '~/assets/landscapeLarge.jpg';
import portraitLarge from '~/assets/portraitLarge.jpg';
import portraitMedium from '~/assets/portraitMedium.jpg';

/**
 * Return placeholder image objects for use with Image
 *
 * @param {String} size
 */
export function placeholderImage(size) {
  const image = {
    portraitMedium: {
      url: portraitMedium,
    },
    portraitLarge: {
      url: portraitLarge,
    },
    landscapeLarge: {
      url: landscapeLarge,
    },
    landscapeHuge: {
      url: landscapeHuge,
    },
  };

  return image[size] ?? image[0];
}
