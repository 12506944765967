import ProductCard from '~/components/product/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';

const ProductGrid = ({ products = [], productClassName = '' }) => {
  return (
    <>
      {products ? (
        products.map(product => (
          <ProductCard
            product={product}
            className={productClassName}
            showQuickShop={false}
            key={`${product.id}-${product.name}`}
          />
        ))
      ) : (
        <p>No Products found!</p>
      )}
    </>
  );
};

export default ProductGrid;

ProductGrid.propTypes = {
  aspectRatio: PropTypes.string,
  imageCrop: PropTypes.bool,
  productClassName: PropTypes.string,
  products: PropTypes.array,
};
