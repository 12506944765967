import { getAppNavigation } from '~/helpers/mobileApp/getAppNavigation';

export const rewriteAnchorTagsFromRawHtml = (html: string) => {
  let appSafeHtml = html;

  const regex = /href="(.*?)"/g;
  let match;

  while ((match = regex.exec(html))) {
    const newUrl = getAppNavigation(match[1]);
    if (appSafeHtml.includes(`href="${match[1]}"`)) {
      // it's possible if they have multiple of the same external links
      appSafeHtml = appSafeHtml.replaceAll(
        `href="${match[1]}"`,
        `href="${newUrl}"`,
      );
    }
  }

  return appSafeHtml;
};
