import React from 'react';

export function ErrorPage() {
  return (
    <main className="ws-error__main">
      <article className="ws-error__500">
        <svg className="ws-error__icon mb-3" viewBox="0 0 512 512">
          <path d="M256 360c-13.25 0-23.1 10.74-23.1 24c0 13.25 10.75 24 23.1 24c13.25 0 23.1-10.75 23.1-24C280 370.7 269.3 360 256 360zM256 320c8.843 0 15.1-7.156 15.1-16V160c0-8.844-7.155-16-15.1-16S240 151.2 240 160v144C240 312.8 247.2 320 256 320zM504.3 397.3L304.5 59.38C294.4 42.27 276.2 32.03 256 32C235.8 32 217.7 42.22 207.5 59.36l-199.9 338c-10.05 16.97-10.2 37.34-.4218 54.5C17.29 469.5 35.55 480 56.1 480h399.9c20.51 0 38.75-10.53 48.81-28.17C514.6 434.7 514.4 414.3 504.3 397.3zM476.1 435.1C472.7 443.5 464.8 448 455.1 448H56.1c-8.906 0-16.78-4.484-21.08-12c-4.078-7.156-4.015-15.3 .1562-22.36L235.1 75.66C239.4 68.36 247.2 64 256 64c0 0-.0156 0 0 0c8.765 .0156 16.56 4.359 20.86 11.64l199.9 338C480.1 420.7 481.1 428.8 476.1 435.1z" />
        </svg>
        <h1 className="text-3xl mb-5">Oops...</h1>
        <h2 className="text-xl mb-4">Someone broke the internet</h2>
        <p className="mb-3">
          It&apos;s not you, it&apos;s us. There&apos;s been a technical glitch{' '}
          <strong>but we have our best people on it.</strong>
        </p>
        <p>
          Please check back shortly and we&apos;ll have the issue all sorted out
          and be back in business.
        </p>
      </article>
    </main>
  );
}
