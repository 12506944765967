import { useCallback, useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import useParams from '~/hooks/useParams';

/**
 * Various variant option functionality
 *
 * @param {object} initialVariant
 * @param {array} variants An array of all the possible variants
 * @param setVariantExists
 * @param disableUrlUpdates
 */
const useVariantOptions = ({
  initialVariant,
  variants,
  setVariantExists,
  disableUrlUpdates,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { setParam, deleteParam } = useParams();
  const initialOptions = {};

  if (initialVariant?.size) {
    initialOptions.Size = initialVariant.size;
  }
  if (initialVariant?.color) {
    initialOptions.Color = initialVariant.color;
  }

  const [selectedOptions, setSelectedOptions] = useState(initialOptions);
  const [selectedVariant, setSelectedVariant] = useState({ ...initialVariant });

  /**
   * Returns undefined or a matched variant based on selected options
   */
  const matchVariant = useCallback(() => {
    return (
      variants &&
      variants.find(variant => {
        const { size, color } = variant;
        const variantOptions = {};
        if (size) {
          variantOptions.Size = size;
        }
        if (color) {
          variantOptions.Color = color;
        }

        return isEqual(selectedOptions, variantOptions);
      })
    );
  }, [selectedOptions, variants]);

  const updateUrl = useCallback(variant => {
    if (variant?.id) {
      setParam('variant', variant?.id);
    } else {
      deleteParam('variant');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Changes variant based on selected form options
   */
  useEffect(() => {
    const matchedVariant = matchVariant();
    if (!matchedVariant) {
      if (!disableUrlUpdates) {
        updateUrl();
      }

      // Set to empty for incomplete variant
      // eg. Color taupe does not exist in selected size
      setSelectedVariant({});
      return setVariantExists(false);
    }

    const variant = matchedVariant;

    setSelectedVariant(variant);
    setVariantExists(true);

    if (!disableUrlUpdates) {
      updateUrl(variant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOptions,
    disableUrlUpdates,
    setSelectedVariant,
    setVariantExists,
    updateUrl,
  ]);

  return { selectedOptions, setSelectedOptions, selectedVariant };
};

export default useVariantOptions;
