import PropTypes from 'prop-types';
import React from 'react';
import { FeaturedCollection } from '~/components/modules/featuredCollection';
import { Hero } from '~/components/modules/hero';
import ImageWithText from '~/components/modules/imageWithText';
import ProductGridContainer from '~/components/modules/productGrid/productGridContainer';
import DropshipProductGridContainer from '~/components/modules/DropshipProductGrid/DropshipProductGridContainer';
import PromoGrid from '~/components/modules/PromoGrid';
import Slideshow from '~/components/modules/slideshow';
import { LiveReplaysModule } from '~/components/modules/LiveReplaysModule';
import ContentSection from './ContentSection';
import AdvanceSlideshow from './advanceSlideshow';
import GridSection from './GridSection';
import RawHtml from './RawHtml';

export const Modules = ({ data, type, oldLayout, config, parentWidget }) => {
  switch (type) {
    case 'FeaturedCollection':
      return (
        <FeaturedCollection
          data={data}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'ProductGrid':
      return (
        <ProductGridContainer
          gridData={data}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'DropshipProductGrid':
      return (
        <DropshipProductGridContainer
          gridData={data}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'ImageWithText':
      return (
        <ImageWithText
          data={data}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'Slideshow':
      return (
        <Slideshow data={data} config={config} parentWidget={parentWidget} />
      );
    case 'AdvanceSlideshow':
      return (
        <AdvanceSlideshow
          data={data}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'PromoGrid':
      return (
        <PromoGrid data={data} config={config} parentWidget={parentWidget} />
      );
    case 'LiveReplays':
      return (
        <LiveReplaysModule
          data={data}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'HeadingBlock':
      return (
        <Hero
          data={data}
          oldLayout={oldLayout}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'ContentSection':
      return (
        <ContentSection
          data={data}
          oldLayout={oldLayout}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'GridBlock':
      return (
        <GridSection
          data={data}
          oldLayout={oldLayout}
          config={config}
          parentWidget={parentWidget}
        />
      );
    case 'RawHtml':
      return (
        <RawHtml
          data={data}
          // oldLayout={oldLayout}
          config={config}
          parentWidget={parentWidget}
        />
      );
    default:
      return <span></span>;
  }
};

Modules.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  oldLayout: PropTypes.number,
};
