import React from 'react';
import { WebstoreSkeletonTheme } from '~/components/ui/organisms/WebstoreSkeletonTheme';
import Skeleton from 'react-loading-skeleton';
type ProductLoaderPropsType = {
  totalThumbs: number;
  ready: boolean;
};
export const ProductLoader: React.FC<ProductLoaderPropsType> = ({
  totalThumbs = 10,
  ready,
  children,
}) => {
  if (ready) {
    return <>{children}</>;
  }

  return (
    <WebstoreSkeletonTheme>
      {Array.from(Array(totalThumbs), (e, i) => (
        <div key={`product-thumbs-${i}`}>
          <div className={'mb-3 p-1'}>
            <Skeleton key={`skeleton-img-${i}`} style={{ height: '351px' }} />
            <div className={'w-full pt-3 pr-2'}>
              <Skeleton key={`skeleton-title-${i}`} style={{ width: '100%' }} />
              <Skeleton key={`skeleton-desc-${i}`} style={{ width: '90%' }} />
            </div>
          </div>
        </div>
      ))}
    </WebstoreSkeletonTheme>
  );
};
