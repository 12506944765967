import { useModal } from '~/context/ModalProvider';
import { GuestModalContent } from '~/components/ui/GuestModal';
import React from 'react';

const useWaitlistModal = () => {
  const { openModal } = useModal();

  // eslint-disable-next-line react/display-name
  return () =>
    openModal({
      id: 'waitlist',
      content: (
        <GuestModalContent
          modalText="You must log in to be added to the waitlist"
          closeModal={undefined}
        />
      ),
      attrs: {
        className: 'relative top-1/2 bg-main-0 rounded',
        containerClassName: 'p-7',
        overlayClassName: 'fixed inset-0 px-4 z-50',
        style: {
          content: {
            inset: 'none',
            transform: 'translateY(-50%)',
            maxWidth: '578px',
          },
        },
      },
      aria: {
        label: 'Waitlist',
      },
    });
};

export default useWaitlistModal;
