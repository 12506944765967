import React from 'react';
import enums from '~/utils/enums';

const Context = React.createContext(undefined as any);

const { featured, bestSelling, alphaAsc, alphaDesc, priceAsc, priceDesc } =
  enums.sortMethods;
type SortMethodsType = {
  Featured: string;
  'Best selling': string;
  'Alphabetical, A to Z': string;
  'Alphabetical, Z to A': string;
  'Price, low to high': string;
  'Price, high to low': string;
};
const sortMethods: SortMethodsType = {
  Featured: featured,
  'Best selling': bestSelling,
  'Alphabetical, A to Z': alphaAsc,
  'Alphabetical, Z to A': alphaDesc,
  'Price, low to high': priceAsc,
  'Price, high to low': priceDesc,
};
type FilterContextProps = {
  getParam: (param: string) => string | null;
  setFilters: (filters: any) => void;
  setSortMethod: (sortMethod: string) => void;
  deleteParam: (param: string) => void;
  setParam: (type: string, param: string) => void;
};
class FilterContext extends React.Component<FilterContextProps> {
  state = {
    activeFilters: {},
    activeFilterCount: 0,
    activeSortMethod: '',
  };

  componentDidMount = () => {
    const { getParam, setFilters, setSortMethod } = this.props;

    const activeFilters = JSON.parse(getParam('filters') || '{}') || {};
    const activeFilterCount = this.getActiveFilterCount(activeFilters);
    const activeSortMethod = getParam('sort') || '';

    this.setState({
      activeFilters,
      activeFilterCount,
      activeSortMethod,
    });

    setFilters(activeFilters);
    setSortMethod(activeSortMethod);
  };

  getActiveFilterCount = (activeFilters: any) => {
    const activeFilterKeys = Object.keys(activeFilters);
    let activeFilterCount = 0;

    for (let i = 0; i < activeFilterKeys.length; i++) {
      const key = activeFilterKeys[i];
      const filter = activeFilters[key];

      if (filter === true) {
        activeFilterCount++;
      } else if (filter.length) {
        activeFilterCount += filter.length;
      }
    }

    return activeFilterCount;
  };

  setActiveFilters = (activeFilters = {}) => {
    const { deleteParam, setParam } = this.props;
    const activeFilterCount = this.getActiveFilterCount(activeFilters);

    this.setState({ activeFilters, activeFilterCount });

    if (Object.keys(activeFilters).length) {
      setParam('filters', JSON.stringify(activeFilters));
    } else {
      deleteParam('filters');
    }
  };

  setSortMethod = (sortMethod: any) => {
    const { deleteParam, setParam, setSortMethod } = this.props;

    this.setState({ activeSortMethod: sortMethod });
    setSortMethod(sortMethod);

    const firstSortMethod =
      sortMethods[Object.keys(sortMethods)[0] as keyof SortMethodsType];

    if (sortMethod === firstSortMethod) {
      deleteParam('sort');
    } else {
      setParam('sort', sortMethod);
    }
  };

  render = () => {
    const { activeFilters, activeFilterCount, activeSortMethod } = this.state;

    return (
      <Context.Provider
        value={{
          activeFilters,
          activeFilterCount,
          setActiveFilters: this.setActiveFilters,
          sortMethods,
          activeSortMethod,
          setActiveSortMethod: this.setSortMethod,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  };
}

const withFilterContext =
  // eslint-disable-next-line react/display-name
  (Rendered: any) => (props: any) => {
    return (
      <Context.Consumer>
        {value => <Rendered {...props} {...value} />}
      </Context.Consumer>
    );
  };

export { withFilterContext, FilterContext as default };
