import { useEffect, useLayoutEffect } from 'react';
import useLocalStorage from './useLocalStorage';
// sets scrollY position of window based on a setting condition, i.e. when api calls are done
// also sets the scroll position when unmounting, i.e. a user navigates to a different page
export default function useWindowScrollPosition(
  localStorageKey: string,
  setCondition: boolean,
): void {
  const [scrollYStorage, setScrollYStorage] = useLocalStorage(
    localStorageKey,
    0,
  );
  useEffect(() => {
    // if the setcondition is true (AKA everything in the DOM is loaded: fire off the scrollTo()!)
    if (setCondition) {
      window.scrollTo(0, scrollYStorage);
    }
  }, [setCondition, scrollYStorage]);
  // use layouteffect runs before repaint so scroll y will be accurate
  // runs when unmounted
  useLayoutEffect(() => {
    return () => {
      setScrollYStorage(window.scrollY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
