import ProductCard from '~/components/modules/productGrid/ProductCard';
import React from 'react';
import { ProductListItem } from '~/types/Product';

type ProductGridPropsType = {
  products: ProductListItem[];
  productClassName: string;
  cardSettings: any;
};
const ProductGrid: React.FC<ProductGridPropsType> = ({
  products = [],
  productClassName = '',
  cardSettings,
}) => {
  return (
    <>
      {products ? (
        products.map(product => (
          <ProductCard
            product={product}
            className={productClassName}
            cardSettings={cardSettings}
            key={`${product.id}-${product.name}`}
          />
        ))
      ) : (
        <p>No Products found!</p>
      )}
    </>
  );
};

export default ProductGrid;
