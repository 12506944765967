import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import React from 'react';

const ProductListPageStructuredData = ({
  location,
  title,
  page,
}: {
  location: { origin: string; pathname: string };
  title: string;
  page: number;
}) => {
  const dataList = [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': `${location.origin}`,
        name: 'Home',
      },
    },
    {
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': `${location.origin}${location.pathname}`,
        name: title,
      },
    },
  ];

  if (page !== 1) {
    dataList.push({
      '@type': 'ListItem',
      position: 3,
      item: {
        '@id': `${location.origin}${location.pathname}?page=${page}`,
        name: `Page ${page}`,
      },
    });
  }

  const breadcrumbsStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: dataList,
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbsStructuredData)}
      </script>
    </Helmet>
  );
};

ProductListPageStructuredData.propTypes = {
  location: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
};

export default ProductListPageStructuredData;
