import React from 'react';
import { ProductThumbPlaceholder } from '~/components/ui/molecules/skeleton-loaders/ProductThumbPlaceholder';
import { WebstoreSkeletonTheme } from '~/components/ui/organisms/WebstoreSkeletonTheme';

export const ProductListLoader: React.FC<{
  totalThumbs: number;
  ready?: boolean;
}> = ({ totalThumbs = 10, ready, children }) => {
  if (ready) {
    return <>{children}</>;
  }

  return (
    <WebstoreSkeletonTheme>
      {Array.from(Array(totalThumbs), (e, i) => (
        <ProductThumbPlaceholder key={i} />
      ))}
    </WebstoreSkeletonTheme>
  );
};
