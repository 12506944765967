import React, { HTMLAttributes, useEffect, useState } from 'react';
import { sanitizeUri, uriIsExternal } from '~/utils/helpers';
import { Link as Anchor } from 'react-router-dom';
import { useSiteContext } from '~/context/SiteContext';
import { getAppNavigation } from '~/helpers/mobileApp/getAppNavigation';

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  to: string;
  blank?: boolean;
  ignoreRouter?: boolean;
  popup?: boolean;
  onKeyPress?: () => void;
  className?: string;
}

const Link: React.FC<LinkProps> = ({
  children,
  to,
  blank,
  ignoreRouter = false,
  popup,
  onKeyPress,
  ...props
}) => {
  const [external, setExternal] = useState(blank ?? false);
  const { isMobileAppConsuming } = useSiteContext();
  let sanitizedTo = sanitizeUri(to);

  useEffect(() => {
    if (blank || popup || uriIsExternal(sanitizedTo ?? '')) {
      setExternal(true);
    }
  }, [blank, popup, sanitizedTo]);

  if (external) {
    let target = '_blank';

    if (popup) target = 'popup';

    return (
      <a
        href={sanitizedTo}
        {...props}
        target={target}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  if (isMobileAppConsuming) {
    sanitizedTo = getAppNavigation(sanitizedTo ?? '');
  }

  if (ignoreRouter || sanitizedTo?.startsWith('navigate://')) {
    return (
      <a href={sanitizedTo} {...props}>
        {children}
      </a>
    );
  }

  return (
    <Anchor to={sanitizedTo ?? ''} onKeyPress={onKeyPress} {...props}>
      {children}
    </Anchor>
  );
};

export default Link;
