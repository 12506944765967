// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';
import {
  getImageSet,
  getImagesFromSplit,
  getVariantFromSplit,
} from '~/utils/helpers';
import ProductForm from '~/components/product/ProductForm';
import ProductGallery from '~/components/product/ProductGallery';
import PropTypes from 'prop-types';
import { useTracking } from '~/context/TrackingContext';
import { TrackingEvent } from '~/types/tracking-event.enum';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';

import { AnalyticGroups } from '~/types/analytic-groups.enum';
import { GA4Event } from '~/types/google-analytics-4-event.enum';
import { getVariantPriceDollars } from '~/models/helpers/inventory';

const ProductDetails = ({
  linkedVariantId,
  product,
  splitAttr,
  disableUrlUpdates,
}) => {
  const { trackEvent } = useTracking();
  linkedVariantId = linkedVariantId // convert to integer always
    ? parseInt(linkedVariantId)
    : linkedVariantId;
  const [activeVariantId, setActiveVariantId] = useState(linkedVariantId);
  const { videoSource, videoUrl, name, id: productId } = product;
  const hasOnlyOneVariant = product?.variants?.length === 1;

  // Set a default variant to active variant, or first split variant, or first variant
  // for use if there is no active variant
  const defaultVariantId = activeVariantId
    ? activeVariantId
    : splitAttr
    ? getVariantFromSplit(product, splitAttr).id
    : product.variants[0].id;

  let images = product.images || [];

  if (getVariantFromSplit(product, splitAttr)) {
    images = getImagesFromSplit(product, splitAttr);
  }

  if (activeVariantId) {
    // reduces to show only active variant's image
    images = getImageSet(product, defaultVariantId);
  }

  // filter out duplicate images
  const imageUrls = images.map(img => img.url);
  images = [...new Set(imageUrls)].map(imgUrl => {
    return { url: imgUrl };
  });

  const brand = product?.brand;
  const productName = product.name;
  const variant = product.variants.find(v => v.id === activeVariantId);
  const sku = product?.sku;

  useEffect(() => {
    if (!variant && hasOnlyOneVariant) {
      return; // in case we only have a single variant, but the route hasn't updated, prevents double events
    }

    trackEvent(TrackingEvent.ViewContent, {
      content_ids: [productId],
      content_type: 'product',
      value: variant?.priceCents / 100,
      currency: 'USD',
    });

    webstoreTracker.track({
      eventName: GA4Event.ViewItem,
      eventDetails: {
        currency: 'USD',
        value: variant ? getVariantPriceDollars(variant) : null,
        items: [
          {
            item_id: sku,
            item_name: productName,
            currency: 'USD',
            item_brand: brand,
            item_variant: (
              (variant?.color ?? '') +
              ' ' +
              (variant?.size ?? '')
            ).trim(),
            price: variant ? getVariantPriceDollars(variant) : null,
          },
        ],
        send_to: AnalyticGroups.All,
      },
    });
  }, [
    hasOnlyOneVariant,
    productId,
    variant,
    trackEvent,
    brand,
    productName,
    sku,
  ]);

  // ViewContent above fires on every variant change. This tracks product-level changes only
  useEffect(() => {
    trackEvent(TrackingEvent.ViewProduct, {
      product_id: productId,
    });
  }, [productId, trackEvent]);

  useEffect(() => {
    webstoreTracker.tagGroup(ContentGroups.PDP);
  }, []);

  return (
    <>
      <div className="col-span-full sm:col-span-2 md:col-span-6">
        <ProductGallery
          key={`${product.id}-${activeVariantId}`}
          activeVariantId={`${defaultVariantId}`}
          product={product}
          images={images}
          videoSource={videoSource}
          videoUrl={videoUrl}
          initialSlide={0}
        />
      </div>

      <div className="col-span-full sm:col-span-2 md:col-span-6">
        <div className="product-details">
          <h1 className="mt-2 font-body text-subtitle lg:text-subtitle-lg">
            {name}
          </h1>

          <ProductForm
            key={product.id}
            product={product}
            linkedVariantId={`${activeVariantId}`}
            defaultVariantId={defaultVariantId}
            setActiveVariantId={setActiveVariantId}
            splitAttr={splitAttr}
            disableUrlUpdates={disableUrlUpdates}
          />
        </div>
      </div>
    </>
  );
};

ProductDetails.propTypes = {
  linkedVariantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  splitAttr: PropTypes.string,
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  liveId: PropTypes.number,
  isLive: PropTypes.bool.isRequired,
  disableUrlUpdates: PropTypes.bool,
};

export default ProductDetails;
