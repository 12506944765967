import { useEffect, useRef } from 'react';

/**
 * usePreviousState
 * @param {*} value - the item to track
 * @returns {*} the previous value of the state item
 */
const usePreviousState = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePreviousState;
