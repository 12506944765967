import { useEffect, useRef } from 'react';

const useScroll = ({ hash, isLoading } = {}) => {
  const scrolledRef = useRef(false);
  const hashRef = useRef(hash);

  useEffect(() => {
    if (hash && !isLoading) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
      if (!scrolledRef.current) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          scrolledRef.current = true;
        }
      }
    }
  }, [hash, isLoading]);
};

export default useScroll;
