import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ProductFilterLoader: React.FC<{
  isReady: boolean;
  className: string;
}> = ({ isReady, children, className }) => {
  if (isReady) {
    return <>{children}</>;
  }
  return (
    <div className={className}>
      <div className="flex justify-between mt-1/2 mb-2 pb-2 border-b border-grey">
        <span>Filters</span>
      </div>
      <div className={'w-full pt-3 pr-2 '}>
        <Skeleton className={'mb-3'} style={{ width: '45%' }} />
        <Skeleton className={'mb-3'} style={{ width: '30%' }} />
        <Skeleton className={'mb-3'} style={{ width: '35%' }} />
        <Skeleton className={'mb-3'} style={{ width: '30%' }} />
        <Skeleton className={'mb-3'} style={{ width: '40%' }} />
        <Skeleton className={'mb-3'} style={{ width: '20%' }} />
      </div>
    </div>
  );
};
