import React from 'react';
import { useReplays } from '~/hooks/live/useReplays';
import { LiveReplays } from '~/components/live/live-replays/LiveReplays';

const LiveReplaysContainer: React.FC = () => {
  const { replays, isLoading, isError, checkForMore, hasMore } = useReplays();

  return (
    <LiveReplays
      hasMore={hasMore}
      isError={isError}
      isLoading={isLoading}
      onLoaderIsOnScreen={checkForMore}
      replays={replays}
    />
  );
};

export { LiveReplaysContainer };
