/* eslint-disable sonarjs/no-duplicate-string,react/display-name */
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Container } from '~/components/layout/PageStructure';
import HeaderNav from '~/components/layout/Header/HeaderNav';
import Icons from '~/components/ui/Icons';
import Link from '~/components/ui/Link';
import Search from '~/components/layout/Header/Search';
import UIContext from '~/context/UIContext';
import cn from 'classnames';
import { useCart } from '~/context/CartContext';
import { ShoppingCart } from '~/types/ShoppingCart';
import { HeaderType } from '~/types/Header';
import { ShopInfo } from '~/types/ShopInfo';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Partials = {
  MobileNav: ({
    setMenuOpen,
    menuHasLinks,
  }: {
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
    menuHasLinks: boolean;
  }) => (
    <>
      {menuHasLinks && (
        <button
          className="mr-1 transition md:mr-2 hover:opacity-80"
          type="button"
          onClick={() => setMenuOpen(true)}
        >
          <Icons type="menu" ariaLabel="Open menu" className="w-6" />
        </button>
      )}
    </>
  ),
  MobileSearch: ({
    searchOpen,
    setSearchOpen,
    layout,
    isDesktop,
  }: {
    searchOpen: boolean;
    setSearchOpen: (value: boolean) => void;
    layout: string;
    isDesktop: boolean;
  }) => (
    <>
      {searchOpen && (
        <div className="absolute flex items-center w-full h-full px-4 center-both bg-main-0 z-max md:px-5">
          <>
            <button className="mr-2" onClick={() => setSearchOpen(false)}>
              <Icons type="close" className="w-6" ariaLabel="Close search" />
            </button>
            <Search
              headerLayout={layout}
              setSearchOpen={setSearchOpen}
              mobileView={!isDesktop}
            />
          </>
        </div>
      )}

      {!searchOpen && (
        <button
          className="relative mx-1 transition md:mx-2 hover:opacity-80 text-ally-0"
          onClick={() => setSearchOpen(true)}
        >
          <Icons type="search" className="w-6" ariaLabel="Search" />
        </button>
      )}
    </>
  ),
  DesktopSearch: ({
    setSearchOpen,
    layout,
    isDesktop,
  }: {
    setSearchOpen: (value: boolean) => void;
    layout: string;
    isDesktop: boolean;
  }) => (
    <div className={cn('flex', 'w-full' && !isDesktop)}>
      <Search
        headerLayout={layout}
        setSearchOpen={setSearchOpen}
        mobileView={!isDesktop}
      />
    </div>
  ),
  UserAndCart: ({
    cart,
    openMiniCart,
    openTriggerRef,
  }: {
    cart: ShoppingCart;
    openMiniCart: () => void;
    openTriggerRef: RefObject<HTMLButtonElement>;
  }) => (
    <div className="flex items-center justify-end">
      <Link
        className="inline-flex items-center mx-1 transition md:mx-2 hover:opacity-80"
        to="/account"
        aria-label="Account"
      >
        <Icons type="account" className="w-6" ariaLabel="Account" />
      </Link>

      <button
        className="relative inline-flex items-center ml-1 transition md:ml-2 hover:opacity-80 "
        type="button"
        aria-label="cart"
        onClick={() => openMiniCart()}
        ref={openTriggerRef}
      >
        <Icons type="cart" className="w-6" ariaLabel="Open cart" />
        {cart && cart.itemCount > 0 && (
          <span className="absolute flex justify-center p-1 header-cart-count top-1 left-4 text-tiniest bg-main-1 text-ally-1 rounded-xl">
            <span>{cart.itemCount}</span>
          </span>
        )}
      </button>
    </div>
  ),
  Logo: ({
    logo,
    shopName,
    searchOpen,
    layout,
  }: {
    logo: string;
    shopName: string;
    searchOpen?: boolean;
    layout: string;
  }) => (
    <Link className={cn('header-logo', { invisible: searchOpen })} to="/">
      <h1
        className={cn('text-h4 md:text-h4-lg', {
          'text-center': layout === 'center',
        })}
      >
        {logo && (
          <figure>
            <LazyLoadImage
              className="max-h-7 md:max-h-8"
              src={logo}
              alt={shopName}
            />
          </figure>
        )}
        {!logo && shopName && <span>{shopName}</span>}
      </h1>
    </Link>
  ),
};

const getLayout = ({
  shopName,
  logo,
  layout,
  isDesktop,
  setMenuOpen,
  searchOpen,
  setSearchOpen,
  cart,
  openMiniCart,
  openTriggerRef,
  header,
  menuHasLinks,
}: {
  shopName: string;
  logo: string;
  layout: string;
  isDesktop: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  searchOpen: boolean;
  setSearchOpen: (value: boolean) => void;
  cart: ShoppingCart;
  openMiniCart: () => void;
  openTriggerRef: RefObject<HTMLButtonElement>;
  header: HeaderType;
  menuHasLinks: boolean;
}) => {
  if (layout === 'center' && !isDesktop) {
    // Logo center | Tablet or mobile
    return {
      navIncluded: false,
      leftComponents: [
        <Partials.MobileNav
          key="header-mobile-nav"
          setMenuOpen={setMenuOpen}
          menuHasLinks={menuHasLinks}
        />,
        <Partials.MobileSearch
          key="header-mobile-search"
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          layout={layout}
          isDesktop={isDesktop}
        />,
      ],
      centerComponents: [
        <Partials.Logo
          key="header-logo"
          layout={layout}
          logo={logo}
          shopName={shopName}
          searchOpen={searchOpen}
        />,
      ],
      rightComponents: [
        <Partials.UserAndCart
          key="header-user-cart"
          cart={cart}
          openMiniCart={openMiniCart}
          openTriggerRef={openTriggerRef}
        />,
      ],
    };
  } else if (layout === 'left' && !isDesktop) {
    // Logo left | Tabler or mobile
    return {
      navIncluded: false,
      leftComponents: [
        <Partials.MobileNav
          key="header-mobile-nav"
          setMenuOpen={setMenuOpen}
          menuHasLinks={menuHasLinks}
        />,
        <Partials.Logo
          key="header-logo"
          layout={layout}
          logo={logo}
          shopName={shopName}
        />,
      ],
      rightComponents: [
        <Partials.MobileSearch
          key="header-mobile-search"
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          layout={layout}
          isDesktop={isDesktop}
        />,
        <Partials.UserAndCart
          key="header-user-cart"
          cart={cart}
          openMiniCart={openMiniCart}
          openTriggerRef={openTriggerRef}
        />,
      ],
    };
  } else if (layout === 'center' && isDesktop) {
    // Logo center | Desktop
    return {
      navIncluded: false,
      centerComponents: [
        <Partials.Logo
          key="header-logo"
          layout={layout}
          logo={logo}
          shopName={shopName}
        />,
      ],
      rightComponents: [
        <Partials.DesktopSearch
          key="header-desktop-search"
          setSearchOpen={setSearchOpen}
          layout={layout}
          isDesktop={isDesktop}
        />,
        <Partials.UserAndCart
          key="header-user-cart"
          cart={cart}
          openMiniCart={openMiniCart}
          openTriggerRef={openTriggerRef}
        />,
      ],
    };
  } else if (layout === 'left' && isDesktop) {
    // Logo left | Desktop
    return {
      navIncluded: true,
      leftComponents: [
        <Partials.Logo
          key="header-logo"
          layout={layout}
          logo={logo}
          shopName={shopName}
        />,
        <HeaderNav key="header-nav" data={{ header }} />,
      ],
      rightComponents: [
        <Partials.DesktopSearch
          key="header-desktop-search"
          setSearchOpen={setSearchOpen}
          layout={layout}
          isDesktop={isDesktop}
        />,
        <Partials.UserAndCart
          key="header-user-cart"
          cart={cart}
          openMiniCart={openMiniCart}
          openTriggerRef={openTriggerRef}
        />,
      ],
    };
  }
};

const HeaderMain = ({
  data,
  pathname,
}: {
  data: {
    header: HeaderType;
    shopInfo: ShopInfo;
    setOpenTrigger: (ref: RefObject<HTMLButtonElement>) => void;
  };
  pathname: string;
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const openTriggerRef = useRef(null);
  const {
    breakpoint: { isDesktop },
    setMenuOpen,
  } = useContext(UIContext);

  const { header, shopInfo, setOpenTrigger } = data;
  const { logo, layout } = header;

  const { cart, toggleMiniCart } = useCart();

  const openMiniCart = () => {
    if (pathname === '/cart') {
      return null;
    }

    toggleMiniCart(true);
  };

  useEffect(() => {
    if (!openTriggerRef) {
      return;
    }
    setOpenTrigger(openTriggerRef);
  }, [openTriggerRef, setOpenTrigger]);

  const renderedLayout = getLayout({
    shopName: shopInfo.name,
    logo,
    layout,
    isDesktop,
    setMenuOpen,
    searchOpen,
    setSearchOpen,
    cart,
    openMiniCart,
    openTriggerRef,
    header,
    menuHasLinks: !!(header?.menu?.links || []).length,
  });

  return (
    <>
      <div className="">
        <Container
          className={cn('py-4 md:py-2', {
            relative: layout === 'center' || !isDesktop,
          })}
        >
          <div className="flex items-center justify-between">
            <div
              className={cn('flex items-center', {
                'header-spacer': layout === 'center' && isDesktop,
                'flex-1': layout === 'left' && isDesktop,
              })}
            >
              {renderedLayout?.leftComponents}
            </div>
            <div
              className={cn('flex items-center', {
                'flex-1 justify-center': layout === 'center' && isDesktop,
              })}
            >
              {renderedLayout?.centerComponents}
            </div>
            <div
              className={cn('flex items-center', {
                'justify-end': layout === 'center' && isDesktop,
              })}
            >
              {renderedLayout?.rightComponents}
            </div>
          </div>
        </Container>
      </div>

      {!renderedLayout?.navIncluded && <HeaderNav data={{ header }} />}
    </>
  );
};

export default HeaderMain;
