import Icons from '~/components/ui/Icons';
import Link from '~/components/ui/Link';
import React from 'react';
import cn from 'classnames';

/* Button
 * Possible styles: primary, secondary, tertiary, text
 */

export type ButtonProps = {
  attrs?: any;
  className?: string;
  button?: {
    style?: string;
    text?: string;
    rounded?: boolean;
    size?: string;
    icon?: string;
  };
};
const Button: React.FC<ButtonProps> = ({
  attrs = {},
  className = '',
  button = {},
}) => {
  const {
    style = 'primary',
    text = 'Submit',
    rounded = true,
    size = 'regular',
    icon,
  } = button;

  const elementAttrs = {
    className: cn('button relative', {
      [`button--${style}`]: !!style,
      [className]: !!className,
      rounded: rounded,
      [`button--${size}`]: !!size,
    }),
    ...attrs,
  };

  const buttonIcon = style === 'text' && icon && (
    <Icons
      type={icon}
      className={cn('w-5 h-5 absolute center-vertical right-0', {
        'right-6': style !== 'text',
      })}
    />
  );

  if (attrs.to) {
    return (
      <Link {...elementAttrs} onClick={attrs.onClick}>
        {text} {buttonIcon}
      </Link>
    );
  }

  return (
    <button {...elementAttrs} onClick={attrs.onClick}>
      {text}
      {buttonIcon}
    </button>
  );
};

export default Button;
