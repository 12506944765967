import React from 'react';
import { GiftItemProps } from '~/types/GiftItemTypes';
import Link from '~/components/ui/Link';

const GiftItem: React.FC<GiftItemProps> = ({
  productName,
  productId,
  productImage,
}) => {
  return (
    <div className="my-4 b">
      <div>
        <div
          className="flex  text-base p-2 pl-3 rounded-t-md"
          style={{ backgroundColor: '#ddd' }}
        >
          <p className="gift-item-heading">Free Gift</p>
        </div>
      </div>
      <div className="p-2">
        <div className="flex">
          <div className="md:flex-shrink-0">
            <img
              className="h-36 w-full object-cover md:w-28 rounded-md m-2"
              src={productImage}
              alt={`gift`}
            />
          </div>
          <div className="w-full p-2">
            <div>
              <p className="pb-2 gift-item-product-name-text">{productName}</p>
              <div className="flex justify-between">
                <div className="pt-2">
                  <p>Free</p>
                </div>
                <div className="pb-2">
                  <p className="font-semibold text-red">Free</p>
                </div>
              </div>
              <div>
                <p className="font-semibold">
                  Check out soon to claim this gift! Limited supplies!
                </p>
              </div>
              <div className="pb-2">
                <p>
                  Please checkout to claim and purchase your items ahead of
                  other shoppers.
                </p>
              </div>
            </div>
            <div className="flex align-center gap-4">
              <Link to={`/products/id/${productId}`}>
                <div className="claim-gift-button button--primary">
                  <p>Claim Free Gift</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-black"></div>
    </div>
  );
};

export default GiftItem;
