import React, { FC, useState } from 'react';
import cn from 'classnames';
import { LiveReplay } from '~/types';
import { FaRegPlayCircle, FaUsers } from 'react-icons/fa';
import { ImageWithFallback } from '~/components/ui/ImageWithFallback';
import Spinner from '~/components/ui/Spinner';
import Link from '~/components/ui/Link';

export interface LiveReplaysProps {
  replay: LiveReplay;
  isReady: boolean;
}

export const LiveReplayTile: FC<LiveReplaysProps> = ({ replay, isReady }) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const timeDisplay = buildTimeDisplay(replay.started_at * 1000);

  return (
    <Link
      className={cn(
        'inline-block w-52 h-full cursor-pointer rounded overflow-hidden',
        !isReady && 'pointer-events-none bg-main-1-dark',
      )}
      to={`/live/${replay.id}`}
    >
      <div
        className="relative cursor-pointer h-64"
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onFocus={() => setHovering(true)}
        onBlur={() => setHovering(false)}
      >
        {isReady && (
          <ImageWithFallback
            className={cn('object-cover w-full h-full rounded', {
              'filter-brightness-50': !hovering,
            })}
            alt={replay.name}
            src={getSrc(hovering, replay)}
          />
        )}

        {!hovering && (
          <div className="fas fa-play-circle absolute top-20 px-4 fa-2x text-white w-full">
            {isReady ? (
              <FaRegPlayCircle className="m-auto" size={70} color="white" />
            ) : (
              <div
                className={
                  'inline-block mx-auto flex flex-col items-center relative bottom-4'
                }
              >
                <Spinner
                  style={{ borderLeftColor: 'white', height: 30, width: 30 }}
                />
                <div className={'text-xs font-bold mt-3'}>
                  Preparing replay...
                </div>
                <div className={'text-xs font-bold mt-1'}>
                  Please check back shortly
                </div>
              </div>
            )}
          </div>
        )}

        <div
          title={replay.name}
          className="rounded-b absolute w-full py-3 bg-black bg-opacity-50 bottom-0 left-0 px-2 text-white text-small"
        >
          <div className="truncate pb-2">{replay.name}</div>
          <div className="text-tiny float-left">{timeDisplay}</div>
          <div className="text-tiny float-right">
            <FaUsers
              className="inline-block mb-px mr-px"
              size={16}
              color="#fff"
            />{' '}
            {replay.peak_viewers}
          </div>
          <div />
        </div>
      </div>
    </Link>
  );
};

// PRIVATE

function buildTimeDisplay(unixTimeMs: number) {
  const startedAt = new Date(unixTimeMs);
  const milliseconds = Date.now() - startedAt.getTime();
  const minutes = Math.floor(milliseconds / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days >= 1) {
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else if (hours >= 1) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }
}

function getSrc(hovering: boolean, replay: LiveReplay): string {
  // Yeah, it's silly but a `replay` can be LIVE at the moment, so it's missing data.
  // Note, later commits filtered the "live" replays out, but still leaving this code here.
  return hovering
    ? replay.animated_thumb ?? replay.source_thumb ?? ''
    : replay.source_thumb ?? '';
}
