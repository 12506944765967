import React from 'react';
import PropTypes from 'prop-types';

import PromoGridItem from '~/components/modules/PromoGridItem';
import Slider from '~/components/ui/Slider';
import {
  PromoGridFancyMedia,
  PromoGridMedia,
  PromoGridSliderMedia,
} from '~/constants/responsiveImageConstants';
import useShopInfo from '~/hooks/live/useShopInfo';

const layoutConstants = {
  fancyGrid: 'fancy-grid',
  grid: 'grid',
  slider: 'slider',
};
const layoutOptions = Object.values(layoutConstants);

const PromoGrid = ({ data = {}, config, parentWidget }) => {
  const { settings = {}, blocks = [], anchorTag } = data;
  const {
    title = 'Promo Grid',
    layout = 'slider',
    imageAspectRatio = '1:1',
  } = settings;
  const { useResponsiveImages } = useShopInfo();

  const paddingTop = config?.paddingTop ?? 0;
  const paddingBottom = config?.paddingBottom ?? 0;
  const paddingLeft = config?.paddingLeft ?? 0;
  const paddingRight = config?.paddingRight ?? 0;

  const marginTop = config?.marginTop ?? 0;
  const marginBottom = config?.marginBottom ?? 0;
  const marginLeft = config?.marginLeft ?? 0;
  const marginRight = config?.marginRight ?? 0;

  const gridDisplay = (
    <div
      className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-5"
      data-testid={layoutConstants.grid}
    >
      {blocks.map(item => (
        <PromoGridItem
          key={item.id}
          item={item}
          aspectRatio={imageAspectRatio}
          containerClassName="mb-2"
          imageMedia={
            useResponsiveImages
              ? PromoGridMedia
              : [{ width: 600, useAsFallback: true }]
          }
        />
      ))}
    </div>
  );

  const fancyGridDisplay = (
    <div
      className="grid-flow-row-dense grid-cols-3 gap-4 promo-grid--fancy sm:grid md:gap-5"
      data-testid={layoutConstants.fancyGrid}
    >
      {blocks.map(item => (
        <PromoGridItem
          key={item.id}
          item={item}
          aspectRatio="4:3"
          containerClassName="mb-4 sm:mb-0"
          imageClassName="flex-grow"
          imageMedia={
            useResponsiveImages
              ? PromoGridFancyMedia
              : [{ width: 600, useAsFallback: true }]
          }
          layout="fancy-grid"
        />
      ))}
    </div>
  );

  const sliderDisplay = (
    <div data-testid={layoutConstants.slider}>
      <Slider className="promo-grid__slider">
        {blocks.map(item => (
          <div key={item.id}>
            <PromoGridItem
              item={item}
              aspectRatio={imageAspectRatio}
              imageMedia={
                useResponsiveImages
                  ? PromoGridSliderMedia
                  : [{ width: 600, useAsFallback: true }]
              }
              imageCrop={true}
            />
          </div>
        ))}
      </Slider>
    </div>
  );

  const trimmedTitle = title?.trim() || '';

  return (
    <div
      style={
        parentWidget === 'GridBlock'
          ? {
              paddingTop,
              paddingBottom,
              paddingLeft,
              paddingRight,
              marginTop,
              marginBottom,
              marginLeft,
              marginRight,
            }
          : {}
      }
      className={parentWidget !== 'GridBlock' ? 'py-7' : ''}
      id={anchorTag}
    >
      {trimmedTitle && (
        <div className={parentWidget !== 'GridBlock' ? 'container' : ''}>
          <h2 className="mb-6 text-h4 lg:text-h4-lg">{trimmedTitle}</h2>
        </div>
      )}

      <div className={parentWidget !== 'GridBlock' ? 'container' : ''}>
        {layout === layoutConstants.grid && gridDisplay}
        {layout === layoutConstants.fancyGrid && fancyGridDisplay}
      </div>
      {layout === layoutConstants.slider && sliderDisplay}
    </div>
  );
};

PromoGrid.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.shape({
      title: PropTypes.string,
      layout: (props, _propName, componentName) => {
        if (!layoutOptions.includes(props.layout)) {
          return new Error(
            `Prop 'layout' requires a value included within [${layoutOptions.join(
              ', ',
            )}] in ${componentName}—instead got "${props.layout}"`,
          );
        }
      },
      imageAspectRatio: PropTypes.string,
    }).isRequired,
    blocks: PropTypes.array.isRequired,
  }),
};

export default PromoGrid;
export { layoutConstants };
