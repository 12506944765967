import React, { useEffect } from 'react';
import { useSiteContext } from '~/context/SiteContext';
import { setColorVars, setCssVar } from '~/utils/helpers';
import { TrackingProvider } from '~/context/TrackingContext';
import { BrowserRouter } from 'react-router-dom';
import { CustomerProvider } from '~/context/CustomerContext';
import { StreamProvider } from '~/context/StreamContext';
import { CartProvider } from '~/context/CartContext';
import { UIProvider } from '~/context/UIContext';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ModalProvider } from '~/context/ModalProvider';
import { HelmetProvider } from 'react-helmet-async';
import Layout from '~/components/layout/Layout';
import { HelmetContent } from '~/components/HelmetContent';
import { Provider } from 'react-redux';
import { store } from '~/store/store';
import { scriptGeneration } from '~/utils/scriptGenerator';
import removeScript from '~/utils/scriptRemover';

export const Site = () => {
  const { theme, globals, htmlScripts, isMobileAppConsuming } =
    useSiteContext();

  useEffect(() => {
    if (!document || !theme) {
      return;
    }

    const { fontHeading, fontBody, borderRadius, color1, color2, color3 } =
      theme;

    setColorVars(color1, 1);
    setColorVars(color2, 2);
    setColorVars(color3, 3);
    setCssVar('--font-heading', fontHeading);
    setCssVar('--font-body', fontBody);
    setCssVar('--border-radius', `${borderRadius}px`);
  }, [theme]);

  useEffect(() => {
    const scriptId = `site${Math.floor(Math.random() * 90000) + 10000}`;
    if (isMobileAppConsuming) {
      return;
    }

    scriptGeneration(htmlScripts, scriptId);
    return () => {
      removeScript(scriptId);
    };
  }, [htmlScripts, isMobileAppConsuming]);

  const payPalScriptProviderOptions = {
    'client-id': globals?.shopInfo?.payPalDetails?.clientId,
    'merchant-id': globals?.shopInfo?.payPalDetails?.merchantId,
    components: 'messages',
  };

  if (!theme || !globals) {
    return <></>;
  }

  // Be mindful of the order of providers here. When adding new providers,
  // remember that all children are re-rendered when the values change.
  return (
    <Provider store={store}>
      <HelmetProvider>
        <TrackingProvider>
          <BrowserRouter>
            <CustomerProvider>
              <StreamProvider>
                <CartProvider>
                  <UIProvider>
                    <PayPalScriptProvider
                      deferLoading={true}
                      options={payPalScriptProviderOptions}
                    >
                      <ModalProvider>
                        <HelmetContent />
                        <Layout />
                      </ModalProvider>
                    </PayPalScriptProvider>
                  </UIProvider>
                </CartProvider>
              </StreamProvider>
            </CustomerProvider>
          </BrowserRouter>
        </TrackingProvider>
      </HelmetProvider>
    </Provider>
  );
};
