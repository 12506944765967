import React, { ChangeEvent } from 'react';
import Select from '~/components/ui/Select';
import { withFilterContext } from '~/context/FilterContext';

type SortMethods = {
  [key: string]: string[];
};

const ProductSorting: React.FC<{
  sortMethods: SortMethods;
  activeSortMethod: string;
  setActiveSortMethod: (value: string | null) => void;
  selectClassName: string;
}> = ({
  sortMethods,
  activeSortMethod,
  setActiveSortMethod,
  selectClassName = '',
}) => (
  <Select
    className={selectClassName}
    attrs={{
      value: activeSortMethod,
      onChange: (e: ChangeEvent<HTMLSelectElement>) =>
        setActiveSortMethod(e.currentTarget.value),
      label: 'Sort By',
    }}
  >
    {Object.keys(sortMethods).map(sortOption => (
      <option
        value={sortMethods[sortOption]}
        key={`collection-sort-${sortOption}`}
      >
        {sortOption}
      </option>
    ))}
  </Select>
);

export default withFilterContext(ProductSorting);
