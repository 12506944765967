import { Helmet } from 'react-helmet-async';
import React from 'react';

const SearchStructuredData = ({ url }: { url: string }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': url,
                name: 'Search',
              },
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SearchStructuredData;
