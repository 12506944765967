import { uriIsExternal } from '~/utils/helpers';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getAppNavigation = (url: string) => {
  if (url.startsWith('#')) {
    // internal link: #section1
    return url;
  }
  try {
    const parser = document.createElement('a');
    parser.href = url;
    if (!uriIsExternal(url)) {
      if (parser.pathname.includes('/products/')) {
        return getProductsNavigation(parser.pathname);
      }

      if (parser.pathname.includes('/collections/')) {
        return getCollectionPageNavigation(parser.pathname);
      }

      if (parser.pathname.includes('/categories/')) {
        return getCategoryPageNavigation(parser.pathname);
      }

      if (
        parser.pathname.includes('/live/') ||
        parser.pathname.endsWith('/live')
      ) {
        return getLiveReplayNavigation(parser.pathname);
      }

      if (parser.pathname.includes('/pages/')) {
        return getCMSPageNavigation(parser.href);
      }

      const searchParams = new URLSearchParams(parser.search);
      if (parser.pathname.includes('/search') && searchParams.get('q')) {
        return getSearchPageNavigation(searchParams);
      }

      if (parser.pathname === '/store' || parser.pathname === '/') {
        return `navigate://feed`;
      }

      if (parser.pathname.includes('/cart')) {
        return 'navigate://checkout';
      }

      if (parser.pathname.includes('/mobilemenu')) {
        return 'navigate://mobilemenu';
      }
    }

    return `navigate://external-page?url=${url}`;
  } catch (e) {
    console.error('unable to parse ', url);
  }

  return url;
};

function getProductsNavigation(url: string) {
  const path = url.split('/');

  if (path[1] !== 'products') {
    return url;
  }
  let slugOrId = path[2] ?? '';
  if (slugOrId === 'id') {
    slugOrId = path[3] ?? '';
  }

  if (!slugOrId) {
    return url;
  }

  if (!isNaN(Number(slugOrId))) {
    return `navigate://product?id=${slugOrId}`;
  }

  return `navigate://product?slug=${slugOrId}`;
}

function getCollectionPageNavigation(url: string) {
  const path = url.split('/');

  if (path[1] !== 'collections' || !path[2]) {
    throw Error(`Url (${url}) is not a collection page!`);
  }

  if (path[2] === 'id') {
    return `navigate://collection?id=${path[3]}`;
  }

  return `navigate://collection?slug=${path[2]}`;
}

function getLiveReplayNavigation(url: string) {
  const path = url.split('/');

  if (path[1] !== 'live') {
    return url;
  }

  if (!path[2]) {
    return 'navigate://live';
  }

  return `navigate://live?id=${path[2]}`;
}

function getCMSPageNavigation(url: string) {
  return `navigate://cms-page?url=${url}`;
}

function getSearchPageNavigation(query: URLSearchParams) {
  return `navigate://search?query=${encodeURI(query.get('q') ?? '')}`;
}

function getCategoryPageNavigation(url: string) {
  const path = url.split('/');

  return `navigate://category?id=${path[2] ?? ''}`;
}
