import Link from '~/components/ui/Link';
import ProductCard from '~/components/product/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import { useSiteContext } from '~/context/SiteContext';
import Slider from '~/components/ui/Slider';
import cn from 'classnames';

const ProductSlider = ({ products = [], slug = '' }) => {
  const { productCardAspectRatio = '2:3' } = useSiteContext().theme;
  const slides = products.map(product => (
    <ProductCard product={product} key={`slide-${product.id}`} />
  ));

  if (slug) {
    slides.push(
      <div
        key="view-all"
        className={cn('relative', `pt-${productCardAspectRatio}`)}
      >
        <Link
          className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center text-center bg-main-1 text-ally-1 rounded"
          to={`/collections/${slug}`}
        >
          View all
        </Link>
      </div>,
    );
  }

  return <Slider>{slides}</Slider>;
};

export default ProductSlider;

ProductSlider.propTypes = {
  slug: PropTypes.string,
  // Defaulting to empty array above so this is not required
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};
