import { ContainerGrid } from '~/components/layout/PageStructure';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams as useUrl } from 'react-router-dom';
import { getImageSet, htmlToText } from '~/utils/helpers';
import ProductDetails from '~/components/product/ProductDetails';
import ProductStructuredData from '~/components/meta/structured-data/ProductStructuredData';
import RelatedProducts from '~/components/product/RelatedProducts';
import SEO from '~/components/meta/SEO';
import { useSiteContext } from '~/context/SiteContext';
import useParams from '~/hooks/useParams';
import { ProductDetailLoader } from '~/components/ui/organisms/ProductDetailLoader';
import useRestClient from '~/hooks/useRestClient';
import AppLinks from '~/components/meta/AppLinks';

const Product = () => {
  const {
    theme: { productPageRelatedProducts },
    globals: { shopInfo },
  } = useSiteContext();
  const { slug, id } = useUrl<{ slug: string; id: string }>();
  const [product, setProduct] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const { getParam } = useParams();
  const linkedVariantId = getParam('variant');
  const splitAttr = getParam('split');
  const isFromTikTok = getParam('source-tiktok') !== null;
  const restClient = useRestClient();

  useEffect(() => {
    setIsLoading(true);
    restClient
      .getProductByIdOrSlug({ id, slug, includeUnpublished: isFromTikTok })
      .then(response => {
        if (response?.response?.status === 404) {
          return setIsNotFound(true);
        }

        setProduct(response?.data?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isFromTikTok, id, slug, setIsLoading, setProduct, restClient]);

  if (isNotFound) {
    return <Redirect to={'/404'} />;
  }

  if (!product || isLoading) {
    return <ProductDetailLoader ready={false} />;
  }

  const { name, storeDescription, relatedProducts } = product;
  const images = getImageSet(product);
  const showRelatedProducts = relatedProducts?.length > 0;

  return (
    <>
      <SEO
        title={name}
        description={htmlToText(storeDescription)}
        image={images[0]?.url}
      />
      <AppLinks url={window.location.href} />

      <ContainerGrid className="mb-8 mt-7">
        <ProductDetails
          product={product}
          linkedVariantId={linkedVariantId}
          splitAttr={splitAttr}
          isLive={false}
        />
      </ContainerGrid>

      {productPageRelatedProducts && showRelatedProducts && (
        <RelatedProducts
          className="col-span-full"
          data={{
            title: 'Recommended Products',
            products: relatedProducts,
          }}
        />
      )}

      <ProductStructuredData
        product={product}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        meta={{ shopName: shopInfo.name }}
        location={window.location}
      />
    </>
  );
};

export default Product;
