import { useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth ||
  0;

const getHeight = () => window.innerHeight || 0;

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<Dimensions>({
    width: getWidth(),
    height: getHeight(),
  });

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | null | undefined = null;
    function handleResize() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const newWidth = getWidth();
        const newHeight = getHeight();
        if (newWidth !== windowSize.width || newHeight !== windowSize.height) {
          setWindowSize({
            width: newWidth,
            height: newHeight,
          });
          console.log('window resize event updated');
        }
      }, 150);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.height, windowSize.width, setWindowSize]);

  return windowSize;
}
