import { Container, ContainerGrid } from '~/components/layout/PageStructure';
import React, { useContext, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Button from '~/components/ui/Button';
import Icons from '~/components/ui/Icons';
import Link from '~/components/ui/Link';
import NewsletterSubscribe from '~/components/ui/NewsletterSubscribe';
import {
  GenericLink,
  GenericLinkWithChildren,
  ShopInfo,
  useSiteContext,
} from '~/context/SiteContext';
import UIContext from '~/context/UIContext';
import { createMarkup } from '~/utils/helpers';

const NavItem: React.FC<{ title: string; links: GenericLink[] }> = ({
  title,
  links,
}) => {
  const [open, setOpen] = useState(false);
  const { breakpoint } = useContext(UIContext);

  return !breakpoint.isDesktop ? (
    <div className="border-b mb-4">
      <div className="pb-2">
        <Button
          className="w-full text-nav lg:text-nav-lg text-left pb-0 mb-0 tracking-wide uppercase"
          attrs={{ onClick: () => setOpen(!open) }}
          button={{ text: title, style: 'text', icon: open ? 'minus' : 'plus' }}
        />
      </div>

      <AnimateHeight height={open ? 'auto' : 0}>
        <nav className="flex flex-col">
          {links.map((link, index) => (
            <Link
              key={`footer-link-${index}`}
              to={link.uri}
              className="mb-4 text-small"
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </AnimateHeight>
    </div>
  ) : (
    <div className="ml-6">
      <p className="mb-5 text-nav lg:text-nav-lg tracking-wide uppercase">
        {title}
      </p>

      <nav className="flex flex-col" aria-label={title}>
        {links.map((link, index) => (
          <Link
            key={`footer-link-${index}`}
            to={link.uri}
            className="mb-3 text-small"
          >
            {link.title}
          </Link>
        ))}
      </nav>
    </div>
  );
};
type SocialLinks = {
  [key: string]: string;
  facebook: string;
  instagram: string;
  twitter: string;
  tiktok: string;
};
type FooterProps = {
  globals: {
    shopInfo: ShopInfo;
  };
};
const Footer: React.FC<FooterProps> = ({ globals }) => {
  const { footer, mailingListPopup } = useSiteContext();
  const {
    menu = { id: '', links: [] },
    policyMenu = {},
    contactText,
    socialUrls = {},
  } = footer;
  const { paymentMethods = [] } = globals.shopInfo;
  const { facebookUrl, twitterUrl, instagramUrl, tiktokUrl } = socialUrls;
  const socialLinks: SocialLinks = {
    facebook: facebookUrl,
    instagram: instagramUrl,
    twitter: twitterUrl,
    tiktok: tiktokUrl,
  };

  const defaultNewsletterMessage = 'Join our mailing list';
  const defaultNewsletterCta = 'Join';

  const { isMobileAppConsuming } = useSiteContext();

  if (isMobileAppConsuming) {
    return <></>;
  }

  return (
    <footer className="flex-grow-0 mt-auto md:mt-5 pt-8 pb-5 bg-main-2 text-ally-2 text-small lg:text-small-lg">
      <ContainerGrid collapseRows={true}>
        <div className="row-start-1 col-span-4 md:col-span-12 lg:col-span-5 text-center lg:text-left">
          <p className="mb-5 text-nav lg:text-nav-lg tracking-wide uppercase">
            {mailingListPopup?.title || defaultNewsletterMessage}
          </p>

          <NewsletterSubscribe
            inputID="email-footer"
            className="mb-6"
            ctaText={mailingListPopup?.cta || defaultNewsletterCta}
          />

          {Object.keys(socialLinks).length ? (
            <div className="mb-8 flex flex-wrap justify-center lg:justify-start align-center">
              {Object.keys(socialLinks)
                .filter(network => socialLinks[network])
                .map(network => (
                  <a
                    key={`social-${network}`}
                    href={socialLinks[network]}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="mx-2 lg:ml-0 lg:mr-4"
                    aria-label={network}
                  >
                    <Icons type={network} />
                  </a>
                ))}
            </div>
          ) : null}

          {contactText && (
            <div>
              <p className="mb-5 text-nav lg:text-nav-lg tracking-wide uppercase">
                Get in touch
              </p>
              <div
                className="text-small"
                dangerouslySetInnerHTML={createMarkup(
                  contactText.replace(/\n/g, '<br />'),
                )} // Enables like break to match what is stored in our database table
              />
            </div>
          )}
        </div>

        <div className="row-start-2 lg:row-start-1 col-span-4 md:col-span-12 lg:col-start-6 lg:col-span-6 flex flex-col lg:flex-row justify-between mt-7 lg:mt-0 text-ally-2">
          {menu?.links?.map((m: GenericLinkWithChildren) => {
            if (!m?.links?.length) return null;

            return <NavItem title={m.title} links={m.links} key={m.id} />;
          })}
        </div>
      </ContainerGrid>

      <Container>
        {paymentMethods.length ? (
          <div className="footer__payment-icons flex flex-wrap justify-center align-center my-7 lg:mb-6">
            {paymentMethods.map(gateway => (
              <Icons
                key={`payment-gateway-${gateway}`}
                type={gateway.toLowerCase()}
                className="mx-1"
              />
            ))}
          </div>
        ) : null}

        <div className="mt-7 lg:mt-6 flex flex-col lg:flex-row text-tiny justify-between items-center">
          <p className="text-center">
            Copyright &copy; {new Date().getFullYear()} CommentSold.com. All
            rights reserved.
          </p>

          {policyMenu?.links?.length ? (
            <nav className="flex flex-col lg:flex-row justify-center mt-4 lg:mt-0">
              {policyMenu.links.map(
                ({ id, title, uri }: GenericLink, index: number) => (
                  <Link
                    key={`footer-link-${index}`}
                    className="mx-3 text-center"
                    to={uri}
                    blank
                  >
                    {title}
                  </Link>
                ),
              )}
            </nav>
          ) : null}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
