import {
  PayPalMessages,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react';
import {
  buildOptions,
  createMarkup,
  getAvailability,
  getIsSale,
  getPriceDollars,
  getVariantSet,
} from '~/utils/helpers';
import { find, isEmpty } from 'lodash';
import Button from '~/components/ui/Button';
import FadeIn from 'react-fade-in';
import Icons from '~/components/ui/Icons';
import ProductPrice from '~/components/product/ProductPrice';
import PropTypes from 'prop-types';
import Quantity from '~/components/ui/Quantity';
import Shareable from '~/components/ui/Shareable';
import { useSiteContext } from '~/context/SiteContext';
import { useCart } from '~/context/CartContext';
import usePrice from '~/hooks/usePrice';
import useVariantOptions from '~/hooks/useVariantOptions';
import { useModal } from '~/context/ModalProvider';
import Link from '~/components/ui/Link';
import OptionGroup from '~/components/product/ProductFormOptionGroup';
import useWaitlistModal from '~/hooks/useWaitlistModal';
import createRestClient from '~/utils/createRestClient';
import enums from '~/utils/enums';

const getVariant = ({ variantId, variants }) => {
  return find(variants, {
    id: variantId,
  });
};

/**
 * When the component mounts, dispatch to tell PayPalScriptProvider to load
 */
const PayPalPayments = ({ amount }) => {
  const [{ isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: 'setLoadingStatus',
      value: 'pending',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPending ? (
        <p className="m-0 text-grey text-tiny lg:text-tiny-lg">Loading</p>
      ) : null}
      <PayPalMessages amount={amount} forceReRender={[amount]} />
    </>
  );
};

const KlarnaPayments = ({ priceCents }) => {
  useEffect(() => {
    const stripePublicKey = window.cs.shopInfo.stripePublicKey;

    if (typeof window.Stripe === 'undefined' || !stripePublicKey) {
      return;
    }

    // eslint-disable-next-line no-undef
    const stripe = window.Stripe(stripePublicKey);
    const elements = stripe.elements();
    const options = {
      amount: Math.round(priceCents),
      currency: 'USD',
      paymentMethodTypes: ['klarna'],
      countryCode: 'US',
    };

    const PaymentMessageElement = elements.create(
      'paymentMethodMessaging',
      options,
    );
    PaymentMessageElement.mount('#klarna-messaging-element');
  }, [priceCents]);

  return <div id="klarna-messaging-element" />;
};

const ProductForm = ({
  product,
  linkedVariantId,
  defaultVariantId,
  setActiveVariantId,
  splitAttr,
  disableUrlUpdates = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { storeDescription } = product;
  const variants = getVariantSet(product, splitAttr);
  const [showWaitlistSuccess, setShowWaitlistSuccess] = useState(false);
  const { closeModal } = useModal();
  const openWaitlistModel = useWaitlistModal();

  // Get sezzle context
  const {
    sezzlePublicKey,
    payPalDetails = {},
    hideAvailableStockCount,
    klarnaDisplayOnProductPage,
  } = useSiteContext().globals.shopInfo;

  // Set initial variant if one is linked. Use the linkedVariantId
  // and match it to our variants array
  const initialVariant = getVariant({ variantId: linkedVariantId, variants });
  const defaultVariant = getVariant({ variantId: defaultVariantId, variants });
  const [variantExists, setVariantExists] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Please make a selection');
  const { addVariantToCart, wasLastItemWaitlisted } = useCart();

  const { selectedOptions, setSelectedOptions, selectedVariant } =
    useVariantOptions({
      initialVariant,
      variants,
      setVariantExists,
      disableUrlUpdates,
    });

  const isSale = getIsSale([selectedVariant]);
  const options = buildOptions(variants, selectedOptions);

  /**
   * Initial state
   */
  const [priceCents, setPriceCents] = useState(
    initialVariant
      ? initialVariant.priceCents
      : defaultVariant?.priceCents || 0,
  );
  const [salePriceCents, setSalePriceCents] = useState(
    initialVariant
      ? initialVariant.salePriceCents
      : defaultVariant?.priceCents || 0,
  );
  const [amount, setAmount] = useState(
    isSale ? getPriceDollars(salePriceCents) : getPriceDollars(priceCents),
  );
  const [quantity, setQuantity] = useState(1);
  const [isAvailable, setisAvailable] = useState(
    getAvailability(initialVariant, product),
  );

  /**
   * Runs only when variant is updated
   */
  useEffect(() => {
    // Clear waitlist success message
    setShowWaitlistSuccess(false);

    // Bail and set as unavailable if no variant
    if (!selectedVariant || isEmpty(selectedVariant)) {
      setisAvailable(false);

      // Update button
      setButtonDisabled(!variantExists);
      setButtonText(
        variantExists
          ? isAvailable
            ? 'Add to Cart'
            : 'Add to Waitlist'
          : 'Please make a selection',
      );
      return;
    }

    // Sets id, availability and price
    setPriceCents(selectedVariant.priceCents);
    setSalePriceCents(selectedVariant.salePriceCents);
    setisAvailable(getAvailability(selectedVariant, product));

    setAmount(
      isSale ? getPriceDollars(salePriceCents) : getPriceDollars(priceCents),
    );

    if (setActiveVariantId) setActiveVariantId(selectedVariant.id);

    // Update button
    setButtonDisabled(!variantExists);
    setButtonText(
      variantExists
        ? isAvailable
          ? 'Add to Cart'
          : 'Add to Waitlist'
        : 'Unavailable',
    );
  }, [
    isAvailable,
    isSale,
    priceCents,
    product,
    salePriceCents,
    selectedVariant,
    setActiveVariantId,
    variantExists,
    selectedOptions,
  ]);

  /**
   * Sets selected options
   *
   * @param {string} name Option name
   * @param {string} value New value to be set to  option
   */
  const handleOptionChange = (name, value) => {
    const optionsToUpdate = {
      ...selectedOptions,
      [name]: value,
    };

    setSelectedOptions(optionsToUpdate);
  };

  const handleOptionUnselect = name => {
    const optionsToUpdate = { ...selectedOptions };

    delete optionsToUpdate[name];

    setSelectedOptions(optionsToUpdate);
  };

  const handleQuantityChange = value => {
    setQuantity(value);
  };

  /**
   * Handle waitlist check
   */
  const clearForWaitlist = async () => {
    if (variantExists && isAvailable) return true;

    const restClient = createRestClient();
    const response = await restClient.me();

    return !response.isGuest;
  };

  /**
   * Add to cart handler
   */
  const handleViewProduct = async e => {
    e.preventDefault();

    // Juuust in case ATC button is clickable with no variant
    if (!selectedVariant && isEmpty(selectedVariant)) {
      return;
    }

    // Disable the ATC
    setButtonDisabled(true);

    // Check if the customer is able to add something to the waitlist
    // this is dependent upon whether they are logged in
    if (!(await clearForWaitlist())) {
      openWaitlistModel();
      setButtonDisabled(false);
      return;
    }

    await addVariantToCart({
      variant: selectedVariant,
      quantity,
      isGiftCard: product?.type === enums.productTypes.giftCard,
    });

    // Only shows waitlist success message if the last item added was to waitlist
    setShowWaitlistSuccess(true);

    // Enable the ATC
    setButtonDisabled(false);

    // Close quickshop if open
    closeModal({ id: 'quickshop' });
  };

  const SezzlePayments = () => (
    <p className="m-0 text-grey text-tiny lg:text-tiny-lg">
      Pay in 4 interest-free payments of{' '}
      {usePrice(Math.round((salePriceCents || priceCents) / 4))} with{' '}
      <Icons type="sezzle" className="inline w-auto h-4" />
    </p>
  );

  const priceFormatted = usePrice(priceCents);
  const salePriceFormatted = usePrice(salePriceCents);

  return (
    <FadeIn>
      {isEmpty(selectedVariant) ? (
        <ProductPrice
          product={product}
          className="mt-4 text-subtitle lg:text-subtitle-lg"
        />
      ) : (
        <div className="mt-4 text-subtitle lg:text-subtitle-lg">
          {isSale ? (
            <>
              <div className={'visually-hidden'}>Sale Price:</div>
              <span id="price">{salePriceFormatted}</span>
              <div className={'visually-hidden'}>Original Price:</div>
              <span
                className="ml-5 line-through text-grey"
                aria-label="Product sale price"
              >
                {priceFormatted}
              </span>
            </>
          ) : (
            <span id="price">{priceFormatted}</span>
          )}
        </div>
      )}

      {klarnaDisplayOnProductPage && (
        <div className="mt-2">
          <KlarnaPayments priceCents={salePriceCents || priceCents} />
        </div>
      )}

      {payPalDetails && (
        <div className="mt-2">
          <PayPalPayments amount={amount} />
        </div>
      )}

      {sezzlePublicKey && (
        <div className="mt-2">
          <SezzlePayments />
        </div>
      )}

      {!!options.length && variants?.length && (
        <div className="grid grid-cols-1 mt-2 overflow-hidden">
          {options.map(({ name: optionName, values: optionValues }) => (
            <OptionGroup
              key={`${product.id}-${optionName}`}
              product={product}
              selectedOptions={selectedOptions}
              optionName={optionName}
              optionValues={optionValues}
              handleOptionChange={handleOptionChange}
              handleOptionUnselect={handleOptionUnselect}
              hideAvailableStockCount={hideAvailableStockCount}
            />
          ))}
        </div>
      )}

      <div className="mt-4">
        {selectedVariant?.id && (
          <div className="mb-6 mr-4">
            <Quantity
              id={selectedVariant.id}
              product={product}
              onChange={handleQuantityChange}
            />
          </div>
        )}

        {showWaitlistSuccess && wasLastItemWaitlisted && (
          <div className="mb-2">
            <Link
              to="/checkout"
              className="underline"
              style={{ fontSize: '0.875rem' }}
            >
              Item added to waitlist! Click here to be first on the waitlist.
            </Link>
          </div>
        )}

        <Button
          className="w-full lg:flex-grow h-7 sm:h-auto"
          attrs={{
            disabled: buttonDisabled || !variantExists,
            onClick: e => {
              void handleViewProduct(e);
            },
            type: 'submit',
          }}
          button={{
            text: buttonText,
          }}
        />
      </div>

      <div className="mt-7">
        <Shareable title={product.name} />
      </div>

      {storeDescription && (
        <div className="product-form--description">
          <span className="block mt-6">Product details</span>
          <div
            className="mt-4 rte text-small lg:text-small-lg"
            dangerouslySetInnerHTML={createMarkup(storeDescription)}
          />
        </div>
      )}
    </FadeIn>
  );
};
export default ProductForm;

ProductForm.propTypes = {
  product: PropTypes.shape({
    storeDescription: PropTypes.string,
    variants: PropTypes.array,
  }),
  linkedVariantId: PropTypes.string,
  disableUrlUpdates: PropTypes.bool,
};
