import { StreamInfo } from '~/types';
import { useEffect, useState } from 'react';
import { useStream } from '~/context/StreamContext';

export enum IsLiveHappening {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown',
}

export default function useIsLiveHappeningWithPolling() {
  const [isLiveHappening, setIsLiveHappening] = useState<IsLiveHappening>(
    IsLiveHappening.Unknown,
  );
  const { streamInfo } = useStream();

  useEffect(() => {
    setIsLiveHappening(getIsLiveHappening(streamInfo));
  }, [streamInfo]);

  return { isLiveHappening, streamInfo };
}

function getIsLiveHappening(
  streamInfo: StreamInfo | undefined,
): IsLiveHappening {
  if (!streamInfo) return IsLiveHappening.Unknown;

  return !!streamInfo?.is_streaming_now && !!streamInfo?.isLotwStreamingEnabled
    ? IsLiveHappening.Yes
    : IsLiveHappening.No;
}
