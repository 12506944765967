import '~/styles/main.css';
import React from 'react';
import { SiteProvider } from '~/context/SiteContext';
import { Site } from '~/components/Site';
import { ErrorBoundary } from '@sentry/react';
import { ErrorPage } from '~/ErrorPage';
import useStoreConfig from '~/hooks/useStoreConfig';

const App = () => {
  const storeConfig = useStoreConfig();

  return (
    <ErrorBoundary
      fallback={<ErrorPage />}
      beforeCapture={scope => {
        scope.setTag('is.fatal', true);
      }}
    >
      <React.StrictMode>
        <SiteProvider config={storeConfig}>
          <Site />
        </SiteProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
};

export default App;
