import React, {
  createContext,
  Dispatch,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import useScroll from '~/hooks/useScroll';
type ScrollContextType = {
  count: number;
  isLoaded: boolean;
};
type Action =
  | { type: 'increment'; payload?: undefined }
  | { type: 'decrement'; payload?: undefined }
  | { type: 'isLoaded'; payload: boolean };

const initialState: ScrollContextType = { count: 0, isLoaded: false };
export const ScrollContext = createContext<{
  state: { count: number; isLoaded: boolean };
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

function countReducer(
  state: ScrollContextType,
  action: Action,
): ScrollContextType {
  const { type, payload } = action;
  switch (type) {
    case 'increment':
      return { ...state, count: state.count + 1 };
    case 'decrement':
      return { ...state, count: state.count - 1 };
    case 'isLoaded':
      return { ...state, isLoaded: payload as boolean };
    default:
      throw new Error();
  }
}

export const ScrollProvider: React.FC = props => {
  const [state, dispatch] = useReducer(countReducer, initialState);
  const value = { state, dispatch };
  const [isLoading, setIsLoading] = useState(true);
  const { hash } = useLocation();

  useEffect(() => {
    if (state.count === 0 && state.isLoaded) {
      setIsLoading(false);
    }
  }, [state]);

  useScroll({ hash, isLoading });

  return <ScrollContext.Provider value={value} {...props} />;
};
