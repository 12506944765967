import { Container } from '~/components/layout/PageStructure';
import React from 'react';
import Link from '~/components/ui/Link';
import useIsLiveHappeningWithPolling, {
  IsLiveHappening,
} from '~/hooks/useIsLiveHappeningWithPolling';

const LiveBanner = () => {
  const { isLiveHappening } = useIsLiveHappeningWithPolling();
  const shouldShowLive = isLiveHappening === IsLiveHappening.Yes;

  if (!shouldShowLive) {
    return <></>;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Link to="/live">
      <div
        className="py-3 bg-main-3 text-ally-3 text-center"
        style={{
          backgroundColor: '#FF6464',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        <Container>
          <p>We are LIVE!</p>
        </Container>
      </div>
    </Link>
  );
};

export default LiveBanner;
