import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

export const ProductThumbPlaceholder: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div
      className={cn(
        className ? className : 'col-span-2 md:col-span-4 lg:col-span-3 mb-3',
      )}
    >
      <Skeleton style={{ height: '351px' }} />
      <div className={'w-full pt-3 pr-2'}>
        <Skeleton style={{ width: '100%' }} />
        <Skeleton style={{ width: '90%' }} />
      </div>
    </div>
  );
};
