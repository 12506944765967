import React, { useContext, useState } from 'react';

import ExpiryTimer from '~/components/cart/ExpiryTimer';
import { Grid } from '~/components/layout/PageStructure';
import Icons from '~/components/ui/Icons';
import Link from '~/components/ui/Link';
import Quantity from '~/components/ui/Quantity';
import { useSiteContext } from '~/context/SiteContext';
import UIContext from '~/context/UIContext';
import cn from 'classnames';
import { getProductLink } from '~/utils/helpers';
import { useCart } from '~/context/CartContext';
import usePrice from '~/hooks/usePrice';
import { ImageWithFallback } from '~/components/ui/ImageWithFallback';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import { LineItemMedia } from '~/constants/responsiveImageConstants';
import freeGiftPlaceholder from '../../assets/free-gift-placeholder.jpeg';
import { ShoppingCartItem } from '~/types/ShoppingCartItem';

/**
 * Line Item Component
 *
 * @param {object} lineItem Line item data
 * @param className
 * @param context
 * @param isHidden
 */
const LineItem: React.FC<{
  lineItem: ShoppingCartItem;
  className: string;
  context?: string;
  isHidden?: boolean;
}> = ({ lineItem, className = '', context, isHidden = false }) => {
  const { variant, isHeld } = lineItem;
  const { product } = variant;
  const { removeLineItem, updateLineItem } = useCart();
  const [disabled, setDisabled] = useState(false);
  const {
    theme,
    globals: { shopInfo },
  } = useSiteContext();
  const { allowCartRemoval, allowCartUpdate, useResponsiveImages } = shopInfo;
  const { productCardAspectRatio: aspectRatio, productImageCrop: imageCrop } =
    theme;
  const { breakpoint } = useContext(UIContext);
  const splitAttr = product.splitColor || product.splitSize;
  const productLink = getProductLink(
    product.slug || '',
    variant.id,
    splitAttr || '',
  );
  const priceEl = usePrice(variant.priceCents);
  const salePriceEl = usePrice(variant.salePriceCents);
  const showCheckoutTimer = isHeld && lineItem.expiresAt;
  const freeGiftItemText = 'Free Gift';
  const freeGiftDescription =
    'Check out soon to claim this gift! Limited supplies!';
  const itemNotHeldText = !isHeld ? 'Item not reserved' : '';
  const handleQuantityChange = async (value: number) => {
    if (!disabled && value !== lineItem.quantity) {
      setDisabled(true);
      await updateLineItem(variant.id, value);
      setDisabled(false);
    }
  };

  // Removes line item
  const handleRemove = async (event: { stopPropagation: () => void }) => {
    event.stopPropagation();

    setDisabled(true);
    await removeLineItem(variant.id, lineItem.quantity);
    setDisabled(false);
  };

  const ProductImage = () => {
    // Image of selected variant
    let image: { url: string } =
      variant.images?.[0] || product.images?.[0] || null;

    if (!image && variant.product?.isFreeGift) {
      image = { url: freeGiftPlaceholder };
    }

    return (
      <figure className="self-center w-full overflow-hidden border border-opacity-50 rounded max-w-9 border-grey">
        <Link
          to={productLink}
          className="product-interactive"
          tabIndex={isHidden ? -1 : 0}
        >
          {image ? (
            <ResponsiveImage
              aspectRatio={aspectRatio}
              imageCrop={imageCrop}
              url={image?.url}
              altText={product.name}
              media={
                useResponsiveImages ? LineItemMedia : [{ useAsFallback: true }]
              }
            />
          ) : (
            <ImageWithFallback alt={product.name} />
          )}
        </Link>
      </figure>
    );
  };

  const getVariantTitle = () => {
    const titleArray = [];
    if (variant.size) titleArray.push(variant.size);
    if (variant.color) titleArray.push(variant.color);
    return titleArray.join(' - ');
  };

  const QuantityInput = () => (
    <Quantity
      id={`${context}-${lineItem.variant.id}`}
      product={lineItem.variant.product}
      initialQuantity={lineItem.quantity}
      context="lineitem"
      onChange={handleQuantityChange}
      disabled={disabled || isHidden}
    />
  );

  const ProductInfo = () => (
    <>
      <p className="my-1">
        <Link to={productLink} tabIndex={isHidden ? -1 : 0}>
          {product.name}
        </Link>
        {product.isFreeGift && <p>{freeGiftDescription}</p>}
      </p>

      <p className="my-1 text-tiny">{getVariantTitle()}</p>

      <p className="my-1">
        {salePriceEl ? (
          <>
            <div className={'visually-hidden'}>Sale Price:</div>
            <span className="mr-4">{salePriceEl}</span>
            <div className={'visually-hidden'}>Original Price:</div>
            <span className="line-through text-grey">
              {Number(variant.priceCents) === 0 ? (
                <span className="text-red">Free</span>
              ) : (
                priceEl
              )}
            </span>
          </>
        ) : (
          <span className="mr-4">
            {Number(variant.priceCents) === 0 ? (
              <span className="text-red">Free</span>
            ) : (
              priceEl
            )}
          </span>
        )}
      </p>
      <p className="my-1">{itemNotHeldText}</p>
    </>
  );

  const Remove = () => (
    <button
      type="button"
      onClick={handleRemove}
      aria-label="Remove from cart"
      className="text-grey"
      disabled={isHidden}
    >
      <Icons type="remove" className="w-6" />
    </button>
  );
  const FreeGiftIcon = () => (
    <div className="btn btn-sm free-gift-label">{freeGiftItemText}</div>
  );
  const FullWidthDisplay = () => {
    const price = variant.salePriceCents ?? variant.priceCents;
    const lineItemTotal = price * lineItem.quantity;

    return (
      <Grid
        className={cn('col-span-full grid-cols-6 mt-5', className, {
          'justify-between': context !== 'minicart',
        })}
      >
        {context !== 'minicart' && (
          <div className="flex col-span-3 align-center md:col-span-5">
            <ProductImage />
            <div className="self-center pl-5 pr-7">
              <ProductInfo />

              {showCheckoutTimer && !product.isFreeGift && (
                <ExpiryTimer
                  expiresAt={lineItem.expiresAt || ''}
                  className="mt-1"
                  expiredText={'This product is expired'}
                />
              )}
            </div>
          </div>
        )}
        <div className="flex items-center justify-center col-span-1 my-1 md:col-span-2">
          {allowCartUpdate &&
            !product.isFreeGift &&
            (variant.salePriceCents ?? variant.priceCents > 0) && (
              <QuantityInput />
            )}
          {!allowCartUpdate && !product.isFreeGift && (
            <div
              className={cn('flex w-10', {
                'justify-center': context !== 'minicart',
              })}
            >
              {lineItem.quantity}
            </div>
          )}
        </div>
        <div className="flex items-center justify-center col-span-1 text-center md:col-span-2">
          <div className="relative">
            {allowCartRemoval && !product.isFreeGift && <Remove />}
            {product.isFreeGift && (
              <div className="absolute right-0 w-24">
                <FreeGiftIcon />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end col-span-1 text-right md:col-span-3">
          {usePrice(lineItemTotal)}
        </div>
      </Grid>
    );
  };

  const MobileMiniCartDisplay = () => (
    <div className={cn('flex items-center gap-3', className)}>
      <ProductImage />
      <div className="flex flex-col">
        <div className="self-center flex-grow px-5">
          <ProductInfo />

          <div className="my-1">
            {allowCartUpdate &&
              !product.isFreeGift &&
              (variant.salePriceCents ?? variant.priceCents > 0) && (
                <QuantityInput />
              )}
            {!allowCartUpdate && !product.isFreeGift && (
              <div
                className={cn('flex w-10', {
                  'justify-center': context !== 'minicart',
                })}
              >
                {lineItem.quantity}
              </div>
            )}
          </div>

          {showCheckoutTimer && !product.isFreeGift && (
            <ExpiryTimer
              expiresAt={lineItem.expiresAt || ''}
              className="mt-1"
              expiredText={'This product is expired'}
            />
          )}
        </div>

        {product.isFreeGift && (
          <div className="text-right">
            <FreeGiftIcon />
          </div>
        )}
      </div>
      {allowCartRemoval && !product.isFreeGift && (
        <div className="relative">
          <Remove />
        </div>
      )}
    </div>
  );

  return (
    <>
      {breakpoint.isMobile || context === 'minicart' ? (
        <MobileMiniCartDisplay />
      ) : (
        <FullWidthDisplay />
      )}
    </>
  );
};

export default LineItem;
