import { ContainerGrid } from '~/components/layout/PageStructure';
import React from 'react';

const cols = 12;

const GridGuide: React.FC = () => {
  // Do not run in production and make sure we are in the browser
  if (process.env.NODE_ENV === 'development' && typeof window !== `undefined`) {
    const parsedUrl = new URL(window.location.href);
    const parsedQueryArray = parsedUrl.search.split('=');

    parsedQueryArray[0] = parsedQueryArray[0].replace('?', '');

    if (parsedQueryArray.includes('show-grid') === false) {
      return <></>;
    }
  } else {
    return <></>;
  }

  return (
    <div className="fixed inset-0 pointer-events-none">
      <ContainerGrid className="h-full">
        {[...Array(cols)].map((e, i) => (
          <div
            className="col-span-1 opacity-25 bg-lightgrey-20"
            key={`col-${i}`}
          />
        ))}
      </ContainerGrid>
    </div>
  );
};

export default GridGuide;
