import React from 'react';
import Link from '~/components/ui/Link';
import { Swiper, SwiperSlide } from 'swiper/react';

export const SubNavigation: React.FC<{
  links: { url: string; title: string }[];
  currentPageTitle: string;
}> = ({ links, currentPageTitle }) => {
  return (
    <div className="mt-1/2 border-b border-grey mb-2">
      <Swiper
        navigation={true}
        className="subcategory-nav"
        slidesPerView={'auto'}
        spaceBetween={50}
        allowTouchMove={true}
      >
        <SwiperSlide
          style={{ width: 'auto' }}
          className={'pb-2 border-b-2 border-grey'}
        >
          All {currentPageTitle}
        </SwiperSlide>
        {links.map(link => (
          <SwiperSlide
            key={link.url}
            className={'mb-2'}
            style={{ width: 'auto' }}
          >
            <Link to={link.url}>{link.title}</Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
