// See ActionContext.php in Laravel app
const EXPERIENCE_LIVE = 'live';
const EXPERIENCE_LIVE_REPLAY = 'live_replay';
const EXPERIENCE_TIKTOK = 'tiktok';
const SESSION_SOURCE_INFLUENCER = 'influencer';
const SESSION_SOURCE_TIKTOK = 'tiktok';

interface BuildExperienceParams {
  isLiveReplay?: boolean;
  liveId?: number;
  isFromTikTok: boolean;
  experienceFromParam: string | null;
}

export function buildExperience({
  isLiveReplay,
  liveId,
  isFromTikTok,
  experienceFromParam,
}: BuildExperienceParams) {
  if (isFromTikTok) {
    return {
      experience: EXPERIENCE_TIKTOK,
      experienceId: undefined,
    };
  }

  return {
    experience: isLiveReplay
      ? EXPERIENCE_LIVE_REPLAY
      : liveId !== undefined
      ? EXPERIENCE_LIVE
      : experienceFromParam,
    experienceId: liveId !== undefined ? liveId : undefined,
  };
}

interface BuildSessionParams {
  influencerId: number | null;
  isFromTikTok: boolean;
  sessionSourceFromParam: string | null;
}

export function buildSession({
  influencerId,
  isFromTikTok,
  sessionSourceFromParam,
}: BuildSessionParams) {
  if (isFromTikTok) {
    return {
      sessionSource: SESSION_SOURCE_TIKTOK,
      sessionSourceId: undefined,
    };
  }

  return {
    sessionSource: influencerId // Will be null if no influencer
      ? SESSION_SOURCE_INFLUENCER
      : sessionSourceFromParam,
    sessionSourceId: influencerId?.toString(),
  };
}
