import ProductCard from '~/components/modules/DropshipProductGrid/ProductCard';
import React from 'react';
import { DropshipProduct } from '~/types/ApiResponseTypes';

type ProductGridPropsType = {
  products: DropshipProduct[];
  productClassName: string;
  cardSettings: any;
};
const ProductGrid: React.FC<ProductGridPropsType> = ({
  products = [],
  productClassName = '',
  cardSettings,
}) => {
  return (
    <>
      {products.length > 0 ? (
        products?.map(product => (
          <ProductCard
            product={product}
            className={productClassName}
            cardSettings={cardSettings}
            key={`${product.id}-${product.name}`}
          />
        ))
      ) : (
        <p>No Products found!</p>
      )}
    </>
  );
};

export default ProductGrid;
