import React, { useEffect, useState } from 'react';

import Icons from '~/components/ui/Icons';
import Spinner from '~/components/ui/Spinner';
import cn from 'classnames';
import { ProductListItem } from '~/types/Product';

type QuantityProps = {
  id: string;
  product?: ProductListItem;
  context?: string;
  onChange: (quantity: number) => void;
  initialQuantity?: number;
  disabled: boolean;
};
const Quantity: React.FC<QuantityProps> = ({
  id,
  product = {},
  context = 'lineItem',
  onChange,
  initialQuantity = 1,
  disabled,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const increaseLabel = 'Increase quantity';
  const decreaseLabel = 'Decrease quantity';
  const contextClassNames =
    context === 'lineitem' ? 'w-6 h-6' : 'w-6 h-6 lg:w-7 lg:h-7';

  useEffect(() => {
    onChange?.(quantity);
  }, [quantity, onChange]);

  const Input = () => (
    <input
      className={cn(
        'flex-grow text-center text-button lg:text-button-lg',
        contextClassNames,
      )}
      id={`quantity-${id}`}
      type="text"
      name="quantity"
      defaultValue={quantity}
      onBlur={({ target: { value } }) => {
        setQuantity(parseInt(value, 10) >= 0 ? parseInt(value, 10) : 1);
      }}
      aria-label={`${product?.name} Quantity`}
    />
  );

  return (
    <div>
      {context !== 'lineitem' && (
        <label
          className="block mb-1 text-tiny lg:text-tiny-lg text-grey"
          htmlFor={`quantity-${id}`}
        >
          Quantity
        </label>
      )}
      <div className="flex w-max border border-grey rounded">
        <button
          className={cn(
            'flex justify-center border-r border-grey',
            contextClassNames,
          )}
          type="button"
          aria-label={decreaseLabel}
          onClick={() => setQuantity(quantity >= 1 ? quantity - 1 : 1)}
          disabled={disabled}
        >
          <Icons type="minus" className="w-5 self-center" />
        </button>
        {!disabled && <Input key={`${id}-${product.id}-${initialQuantity}`} />}
        {disabled && (
          <div className="flex-grow flex justify-center items-center w-6 h-6">
            <Spinner />
          </div>
        )}
        <button
          className={cn(
            'flex justify-center border-l border-grey',
            contextClassNames,
          )}
          type="button"
          aria-label={increaseLabel}
          onClick={() => setQuantity(quantity + 1)}
          disabled={disabled}
        >
          <Icons type="plus" className="w-5 self-center" />
        </button>
      </div>
    </div>
  );
};

export default Quantity;
