export const scriptGeneration = (htmlScripts, scriptId) => {
  const headerScript = htmlScripts?.header && atob(htmlScripts?.header);
  const footerScript = htmlScripts?.footer && atob(htmlScripts?.footer);
  const styleScript = htmlScripts?.style && atob(htmlScripts?.style);

  const dom = new DOMParser().parseFromString(headerScript, 'text/html');

  const scripts = dom.head.querySelectorAll('script');
  scripts.forEach(item => {
    const script = document.createElement('script');
    script.id = scriptId;
    script.async = true;
    if (item.hasAttribute('src')) {
      script.src = item.getAttribute('src') || '';
    }
    script.innerHTML = item.textContent || '';
    document.head.appendChild(script);
  });

  // footer script
  const footerDom = new DOMParser().parseFromString(footerScript, 'text/html');

  const footerScripts = footerDom.head.querySelectorAll('script');
  footerScripts.forEach(item => {
    const footerScript = document.createElement('script');
    footerScript.id = scriptId;
    footerScript.async = true;

    if (item.hasAttribute('src')) {
      footerScript.src = item.getAttribute('src') || '';
    }

    footerScript.innerHTML = item.textContent || '';
    document.body.appendChild(footerScript);
  });

  // style script
  let styleElement = document.createElement('style');
  styleElement.id = 'custom-style';
  styleElement.textContent = styleScript;
  document.head.appendChild(styleElement);
};
