import React, { useContext, useEffect, useRef } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { Container } from '~/components/layout/PageStructure';
import Icons from '~/components/ui/Icons';
import NavItem from '~/components/ui/NavItem';
import PropTypes from 'prop-types';
import UIContext from '~/context/UIContext';
import cn from 'classnames';
import { HeaderType } from '~/types/Header';

const HeaderNav = ({ data }: { data: { header: HeaderType } }) => {
  const { menuOpen, setMenuOpen, breakpoint } = useContext(UIContext);
  const {
    header: { menu, layout },
  } = data;
  const ref = useRef<HTMLDivElement>(null);

  // Click outside nav to close
  useEffect(() => {
    function closeMenu(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.addEventListener('mousedown', closeMenu);
    };
  }, [setMenuOpen, ref]);

  // Lock page scroll on menu open
  useEffect(() => {
    const nav = ref.current;

    if (menuOpen && nav) {
      disablePageScroll(nav);

      return () => {
        enablePageScroll(nav);
      };
    }
  }, [menuOpen, ref]);

  const NavContent = () => (
    <nav
      className={cn({
        'mobile-nav-content overflow-x-hidden overflow-y-auto':
          !breakpoint.isDesktop,
      })}
      aria-label="Main Menu"
      data-scroll-lock-scrollable={true}
    >
      <ul
        className={cn('lg:flex flex-wrap pb-16', {
          'lg:justify-center': layout === 'center',
        })}
      >
        {menu?.links.map(
          link =>
            (!('isWeb' in link) || link.isWeb) && (
              <NavItem key={link.id} link={link} setMenuOpen={setMenuOpen} />
            ),
        )}
      </ul>
    </nav>
  );

  return (
    <>
      {!breakpoint.isDesktop && (
        <div
          className={cn('fixed w-screen h-screen transform z-50', {
            'translate-x-0': menuOpen,
            '-translate-x-full delay-300': !menuOpen,
          })}
        >
          <span
            className={cn(
              'fixed w-screen h-screen bg-ally-0 transition-opacity duration-300 ease-in-out',
              {
                'opacity-60': menuOpen,
                'opacity-0': !menuOpen,
              },
            )}
          />
          <div
            ref={ref}
            className={cn(
              'header-nav fixed-width fixed top-0 left-0 z-50 p-5 bg-main-2 text-ally-2 h-screen shadow-panel transform transition-transform duration-300 ease-in-out',
              {
                'translate-x-0': menuOpen,
                '-translate-x-full': !menuOpen,
              },
            )}
          >
            <button onClick={() => setMenuOpen(false)}>
              <Icons type="close" className="w-6" ariaLabel="Close menu" />
            </button>
            <NavContent />
          </div>
        </div>
      )}

      {breakpoint.isDesktop &&
        (layout !== 'center' ? (
          <div className="flex justify-center flex-1 px-4 header-nav">
            <NavContent />
          </div>
        ) : (
          <Container className="header-nav">
            <NavContent />
          </Container>
        ))}
    </>
  );
};

HeaderNav.propTypes = {
  isStuck: PropTypes.bool,
};

export default HeaderNav;
