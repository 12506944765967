import { Container } from '~/components/layout/PageStructure';
import React from 'react';

const FreeGiftBannerBanner: React.FC<{ freeGiftTopBannerMessage: string }> = ({
  freeGiftTopBannerMessage,
}) => {
  return (
    <div className="py-3 bg-main-3 text-ally-3 text-center text-tiny md:text-tiny-lg">
      <Container>
        <p>{freeGiftTopBannerMessage}</p>
      </Container>
    </div>
  );
};

export default FreeGiftBannerBanner;
