import React from 'react';
import { ProductThumbPlaceholder } from '~/components/ui/molecules/skeleton-loaders/ProductThumbPlaceholder';
import { WebstoreSkeletonTheme } from '~/components/ui/organisms/WebstoreSkeletonTheme';

export const SliderFeaturedCollectionLoader: React.FC<{ ready?: boolean }> = ({
  ready,
  children,
}) => {
  if (ready) {
    return <>{children}</>;
  }

  return (
    <WebstoreSkeletonTheme>
      <div
        className="container overflow-hidden relative"
        style={{ maxHeight: '415px' }}
      >
        <div className="flex space-x-6 overflow-hidden">
          {Array.from(Array(8), (e, i) => (
            <ProductThumbPlaceholder className="w-60 flex-none" key={i} />
          ))}
        </div>
      </div>
    </WebstoreSkeletonTheme>
  );
};
