import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { WebstoreSkeletonTheme } from '~/components/ui/organisms/WebstoreSkeletonTheme';

export const ProductDetailLoader: React.FC<{ ready: boolean }> = ({
  ready,
  children,
}) => {
  if (ready) {
    return <>{children}</>;
  }

  return (
    <WebstoreSkeletonTheme>
      <div className="flex flex-col sm:flex-row p-4 sm:space-x-4 mb-10 mx-6 mt-7">
        <div className="flex-1">
          <Skeleton
            className="w-full h-120 skeleton-loader__product-detail-image"
            style={{ aspectRatio: '5/6' }}
          />
        </div>
        <div className="flex-1">
          <Skeleton className="w-1/2 h-4 mt-3" />
          <Skeleton className="w-1/6 h-3 mt-3" />
          <Skeleton className="w-full h-3" />
          <Skeleton className="w-1/2 h-3" />

          <Skeleton className="w-1/6 h-3 lg:mt-8 mt-4" />
          <div className="flex space-x-4 my-2">
            <Skeleton
              containerClassName="flex-grow-0 flex-shrink-0"
              className="h-7 w-7"
            />
            <Skeleton
              containerClassName="flex-grow-0 flex-shrink-0"
              className="h-7 w-7"
            />
            <Skeleton
              containerClassName="flex-grow-0 flex-shrink-0"
              className="h-7 w-7"
            />
          </div>

          <Skeleton className="w-1/4 h-4 lg:mt-8 mt-4" />
          <Skeleton className="w-10/12 h-3" />
          <Skeleton className="w-full h-3" />
          <Skeleton className="w-11/12 h-3" />
          <Skeleton className="w-full h-3 hidden md:block" />
          <Skeleton className="w-11/12 h-3 hidden md:block" />
          <Skeleton className="w-10/12 h-3 hidden md:block" />
          <Skeleton className="w-11/12 h-3 hidden md:block" />
          <Skeleton className="w-1/5 h-3 hidden md:block" />
        </div>
      </div>
    </WebstoreSkeletonTheme>
  );
};
