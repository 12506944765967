import React, { useEffect, useRef } from 'react';
import { uniqBy } from 'lodash';
import useOnScreen from '~/hooks/useOnScreen';
import { LiveReplay as LiveReplayModel } from '~/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LiveReplayTile } from '~/components/live/live-replays/LiveReplayTile';
import Spinner from '~/components/ui/Spinner';
import isReplayReady from '~/helpers/isReplayReady';
import { LiveReplayLoader } from '~/components/ui/molecules/skeleton-loaders/LiveReplayLoader';

interface LiveReplaysProps {
  hasMore: boolean;
  hideHeading?: boolean;
  isError: boolean;
  isLoading: boolean;
  onLoaderIsOnScreen: () => void;
  replays: LiveReplayModel[];
  useTouchNavigation?: boolean;
}

export const LiveReplays: React.FC<LiveReplaysProps> = ({
  hasMore,
  hideHeading = false,
  isError,
  isLoading,
  onLoaderIsOnScreen,
  replays,
  useTouchNavigation = true,
}) => {
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const { isOnScreen: isLoaderOnScreen, checkAgain } = useOnScreen(loaderRef);

  useEffect(() => {
    if (isLoaderOnScreen) {
      void onLoaderIsOnScreen();
    }
  }, [isLoaderOnScreen, onLoaderIsOnScreen]);

  if (isLoading) {
    return <LiveReplayLoader />;
  }

  if (isError) {
    return (
      <div className="text-red my-4 text-center">
        Sorry, but we&apos;re experiencing some problems loading live replays at
        this time. Please try again in a little while.
      </div>
    );
  }

  if (!replays.length) {
    return null; // On homepage, we just want this hidden if there are no replays
  }

  // Just in case there are duplicates
  const uniqueReplays = uniqBy(replays, 'id');

  const handleSlideChange = () => {
    // We have to check if the loader slide is on screen each time a slide changes.
    // useOnScreen is watching a ref so it isn't reactive.
    checkAgain();
  };

  return (
    <div className={'my-2'}>
      {!hideHeading && (
        <h2 className="text-h4 lg:text-h4-lg mt-0 mb-4">Live Replays</h2>
      )}

      <Swiper
        spaceBetween={30}
        // Sliding multiple slides at a time will require some hacking
        // in order to be responsive. Leaving it at one for now.
        slidesPerView={'auto'}
        navigation={true}
        allowTouchMove={useTouchNavigation}
        onSlideChange={handleSlideChange}
      >
        {uniqueReplays.map((replay: LiveReplayModel) => {
          return (
            <SwiperSlide key={replay.id} style={{ width: 'auto' }}>
              <LiveReplayTile replay={replay} isReady={isReplayReady(replay)} />
            </SwiperSlide>
          );
        })}

        {hasMore && (
          <SwiperSlide>
            <div
              ref={loaderRef}
              className="h-full w-52 flex justify-center items-center rounded bg-gray-200"
            >
              <Spinner className="m-auto" />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};
