import React, { FC, HTMLAttributes } from 'react';

interface ExpiryTimerProps extends HTMLAttributes<HTMLParagraphElement> {
  expiresAt: string;
  countdownPrefix?: string;
  expiredText?: string;
}

const ExpiryTimer: FC<ExpiryTimerProps> = ({
  expiresAt,
  countdownPrefix = 'Expires in:',
  expiredText,
  ...paragraphAttrs
}) => {
  if (!expiresAt) return null;

  return (
    <p {...paragraphAttrs}>
      <span>{countdownPrefix} </span>

      {/* This web component is available on the DOM from Laravel and the
      countdown-timer.blade.php view */}
      {/*
      Making web components work in React without making TS angry is a project
      for another time.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore*/}
      <countdown-timer expiration-time={expiresAt} expired-text={expiredText} />
    </p>
  );
};

export default ExpiryTimer;
