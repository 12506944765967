import React, { useCallback, useEffect, useState } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import Icons from '~/components/ui/Icons';
import ReactModal, { Aria, Styles } from 'react-modal';
import cn from 'classnames';
interface ModalProps {
  open: boolean;
  style?: Styles;
  transition?: number;
  onRequestClose: () => void;
  disableCloseButton: boolean;
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  overlayClassName?: string;
  aria?: Aria;
}
const Modal: React.FC<ModalProps> = ({
  open,
  children,
  style,
  transition = 300,
  onRequestClose,
  disableCloseButton,
  className,
  containerClassName,
  contentClassName,
  overlayClassName,
  aria,
}) => {
  const [modalContent, setModalContent] = useState();

  const modalContentRef = useCallback(node => {
    if (node !== null) {
      setModalContent(node);
    }
  }, []);

  useEffect(() => {
    ReactModal.setAppElement('#modal');
    // If modalContent is undefined for some reason
    // the modal will still open, but just won't have
    // scroll lock enabled.
    if (open && modalContent) {
      disablePageScroll(modalContent);
    } else {
      enablePageScroll(modalContent);
    }
  }, [open, modalContent]);

  return (
    <ReactModal
      overlayClassName={overlayClassName}
      className={className}
      isOpen={open}
      closeTimeoutMS={transition}
      style={style}
      onRequestClose={onRequestClose}
      appElement={document.getElementById('root') as HTMLElement}
      aria={aria}
    >
      <div
        className={cn(
          'relative flex flex-col bg-center bg-cover',
          containerClassName,
        )}
      >
        {!disableCloseButton && (
          <button
            className="absolute top-2 right-2"
            onClick={onRequestClose}
            aria-label="close"
          >
            <Icons type="close" className="w-6 h-6" />
          </button>
        )}

        <div
          className={cn('flex-1 overflow-y-auto', contentClassName)}
          ref={modalContentRef}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
