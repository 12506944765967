import commentSoldTracker from '~/helpers/trackers/commentsold.tracker';
import facebookTracker from '~/helpers/trackers/facebook.tracker';
import googleAnalyticsTracker from '~/helpers/trackers/google-analytics.tracker';
import pinterestTracker from '~/helpers/trackers/pinterest.tracker';
import tiktokTracker from '~/helpers/trackers/tiktok.tracker';
import { TrackerHelper } from '~/types/tracker-helper.interface';

export class TrackerHelpers {
  static readonly commentSold: TrackerHelper = commentSoldTracker;
  static readonly facebook: TrackerHelper = facebookTracker;
  static readonly googleAnalytics: TrackerHelper = googleAnalyticsTracker;
  static readonly pinterest: TrackerHelper = pinterestTracker;
  static readonly tiktok: TrackerHelper = tiktokTracker;
}
